import React, { useState } from 'react';
import { useSelector } from "react-redux";
// import CountUp from 'react-countup'

import BatchAddUsers from './BatchAddUsers';
import LeaderList from './LeaderList';
import Users from './Users';
import UserFinances from './UserFinances';

const Wallet = (props) => {
	const [tab, setTab] = useState(0);
	const { authUser } = useSelector(({ user }) => user);

	const handleSetTab = (e, index) => {
		e.preventDefault();
		setTab(index)
	}

	return (
		<>
			<div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Users</a></li>
								{authUser?.role > 2 && <li className="nav-item"><a className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 1)}>Add New Batch Users</a></li>}
								{authUser?.role > 2 && <li className="nav-item"><a className={`nav-link ${tab === 2 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 2)}>Leader List</a></li>}
								{authUser?.role >= 2 && <li className="nav-item"><a className={`nav-link ${tab === 3 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 3)}>User Finances</a></li>}
							</ul>
						</div>
					</div>
				</div>
				{tab === 0 && <Users />}
				{tab === 1 && <BatchAddUsers />}
				{tab === 2 && <LeaderList />}
				{tab === 3 && <UserFinances />}
			</div>
		</>
	)

}

export default Wallet;