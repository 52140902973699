import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import isBefore from 'date-fns/isBefore'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Chat from './Chat';

import { getTickets, ticketAction, setChatSupportID, setTicketDetails } from '../../../actions/supportAction';
import { formatDateTime } from '../../../util/date';

const Status = ({ status }) => {
    let type;
    switch (status) {
        case "PENDING_CUSTOMER":
            type = <span className="tag tag-default">Pending Customer</span>
            break;
        case "PENDING_ADMIN":
            type = <span className="tag tag-info">Pending Admin</span>
            break;
        case "CLOSED":
            type = <span className="tag tag-danger">Closed</span>
            break;
        default:
            type = <span className="tag tag-default">Pending</span>
            break;
    }
    return type;
}

const TicketRow = ({
    id,
    username,
    subject,
    description,
    status,
    createdAt,
    updatedAt,
    setIsChatDialogOpen,
}) => {
    const dispatch = useDispatch();

    const chatAction = () => {
        dispatch(setChatSupportID(id));
        dispatch(setTicketDetails({
            id,
            username,
            subject,
            description,
            status,
            createdAt,
            updatedAt,
        }));
        setIsChatDialogOpen(true);
    }
    const closeAction = () => {
        dispatch(ticketAction({
            supportID: id,
            action: "CLOSED"
        }));
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{username}</td>
            <td>{subject}</td>
            <td style={{ maxWidth: 150 }}>{description}</td>
            <td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
            <td>{updatedAt ? formatDateTime(updatedAt) : '-'}</td>
            <td>{status ? <Status status={status} /> : '-'}</td>
            <td>
                <button type="button" className="btn btn-icon btn-sm btn-sm" title="Chat" onClick={chatAction}>
                    <i className="text-info fa fa-comments-o" aria-hidden="true"></i>
                </button>

                {status && (status === 'PENDING_ADMIN' || status === 'PENDING_CUSTOMER') && <>
                    <button type="button" className="btn btn-icon btn-sm btn-sm" title="Close" onClick={closeAction}>
                        <i className="text-success fa fa-check" aria-hidden="true" />
                    </button>
                </>}
            </td>
        </tr>
    )
}

const TicketList = (props) => {
    const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);
    const [chatSupportID, setChatSupportID] = useState(null);

    const [searchStatus, setSearchStatus] = useState("ALL")
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const tickets = useSelector(({ support }) => support.tickets)

    useEffect(() => {
        dispatch(getTickets());
    }, [])

    const handleCloseChatDialog = () => {
        setIsChatDialogOpen(false)
    }

    const validateSearchWithdrawal = () => {
        if (!searchStatus) {
            return ('No status selected');
        } else if (isBefore(new Date(endDate), new Date(startDate))) {
            return ('Invalid End Date');
        } else {
            return ('');
        }
    }

    const submitSearchWithdrawal = (e) => {
        e.preventDefault();
        const hasError = validateSearchWithdrawal();

        if (!hasError) {
            dispatch(getTickets({
                startDate,
                endDate,
                status: searchStatus === 'ALL' ? undefined : [searchStatus],
            }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const renderError = () => {
        return (error ? <p className="text-danger mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="user-tab"
                                    data-toggle="tab"
                                    href="#ticket-list"
                                >
                                    Tickets
                                </a>
                            </li>
                        </ul>
                        {/* <div className="header-action">
								<button type="button" className="btn btn-primary">
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</div> */}
                    </div>

                    <div className="tab-content mt-3">
                        <div className="tab-pane fade show active" id="ticket-list" role="tabpanel">
                            <div className="row clearfix mt-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <form className="row" onSubmit={submitSearchWithdrawal}>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <label>Start Date</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <label>End Date</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <label>Status</label>
                                                    <div className="multiselect_div">
                                                        <select className="custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                            <option value="ALL">All</option>
                                                            <option value="PENDING_CUSTOMER">Pending Customer</option>
                                                            <option value="PENDING_ADMIN">Pending Admin</option>
                                                            <option value="CLOSED">Closed</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6">
                                                    <label>&nbsp;</label>
                                                    <button type="submit" className="btn btn-primary btn-block">
                                                        Search
                                                    </button>
                                                </div>
                                            </form>
                                            <div className="row">
                                                <div className="col-12">{renderError()}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Tickets</h3>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-striped table-vcenter">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>User</th>
                                                        <th>Subject</th>
                                                        <th>Description</th>
                                                        <th>Created</th>
                                                        <th>Last Updated</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tickets && tickets.length > 0 ? tickets.map((t, i) => <TicketRow key={i} {...t} setIsChatDialogOpen={setIsChatDialogOpen} />)
                                                        :
                                                        <tr><td colSpan={7}><p className="text-center">No Tickets found</p></td></tr>
                                                    }
                                                    {/* <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" defaultChecked />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-5258</a></td>
                                                        <td><span>It is a long established fact that a reader</span></td>
                                                        <td><span className="tag tag-default">lower</span></td>
                                                        <td><span>Pre-Sales</span></td>
                                                        <td><span>Archie Cantones</span></td>
                                                        <td><span>6 hours ago</span></td>
                                                        <td>No reply yet</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-8793</a></td>
                                                        <td><span>Measures your Current Assets / Current</span></td>
                                                        <td><span className="tag tag-danger">High</span></td>
                                                        <td><span>Pre-Sales</span></td>
                                                        <td><span>Rose Orcullo</span></td>
                                                        <td><span>9 hours ago</span></td>
                                                        <td>2 reply</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-2500</a></td>
                                                        <td><span>There are many variations of passages</span></td>
                                                        <td><span className="tag tag-info">Medium</span></td>
                                                        <td><span>Pre-Sales</span></td>
                                                        <td><span>Charize Cericoz</span></td>
                                                        <td><span>10 hours ago</span></td>
                                                        <td>1 reply</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-9465</a></td>
                                                        <td><span>Measures your Current Assets / Current</span></td>
                                                        <td><span className="tag tag-default">lower</span></td>
                                                        <td><span>Payment</span></td>
                                                        <td><span>Billie Ko</span></td>
                                                        <td><span>23-01-2019</span></td>
                                                        <td>No reply yet</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-1515</a></td>
                                                        <td><span>Contrary to popular belief, Lorem Ipsum</span></td>
                                                        <td><span className="tag tag-info">Medium</span></td>
                                                        <td><span>Sales</span></td>
                                                        <td><span>Hamza Macasindil</span></td>
                                                        <td><span>22-01-2019</span></td>
                                                        <td>No reply yet</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-2589</a></td>
                                                        <td><span>It uses a dictionary of over 200 Latin</span></td>
                                                        <td><span className="tag tag-info">Medium</span></td>
                                                        <td><span>Technical</span></td>
                                                        <td><span>Dyanne Aceron</span></td>
                                                        <td><span>28-01-2019</span></td>
                                                        <td>5 reply</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-8793</a></td>
                                                        <td><span>Measures your Current Assets / Current</span></td>
                                                        <td><span className="tag tag-danger">High</span></td>
                                                        <td><span>Pre-Sales</span></td>
                                                        <td><span>Rose Orcullo</span></td>
                                                        <td><span>9 hours ago</span></td>
                                                        <td>2 reply</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-2500</a></td>
                                                        <td><span>There are many variations of passages</span></td>
                                                        <td><span className="tag tag-info">Medium</span></td>
                                                        <td><span>Pre-Sales</span></td>
                                                        <td><span>Charize Cericoz</span></td>
                                                        <td><span>10 hours ago</span></td>
                                                        <td>1 reply</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-9465</a></td>
                                                        <td><span>Measures your Current Assets / Current</span></td>
                                                        <td><span className="tag tag-default">lower</span></td>
                                                        <td><span>Payment</span></td>
                                                        <td><span>Billie Ko</span></td>
                                                        <td><span>23-01-2019</span></td>
                                                        <td>No reply yet</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" defaultValue="option1" />
                                                                <span className="custom-control-label">&nbsp;</span>
                                                            </label>
                                                        </td>
                                                        <td><a href="/#">ASD-4569</a></td>
                                                        <td><span>The standard chunk of Lorem Ipsum used</span></td>
                                                        <td><span className="tag tag-danger">High</span></td>
                                                        <td><span>Technical</span></td>
                                                        <td><span>Dyanne Aceron</span></td>
                                                        <td><span>02-02-2019</span></td>
                                                        <td>3 reply</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={isChatDialogOpen}
                            onClose={handleCloseChatDialog}
                        >
                            <Chat setIsChatDialogOpen={setIsChatDialogOpen} />
                            <DialogActions>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    onClick={handleCloseChatDialog}
                                >
                                    Close
                                </button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketList;