import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createCampaign, deleteCampaign, editCampaign, searchCampaigns } from '../../../actions/campaignAction';
import { formatCustomDate, formatDate, formatDateTime } from '../../../util/date';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';

const ItemRow = ({
	id,
	title,
	duration,
	maxVolume,
	percentage,
	launchDate,
	startDate,
	endDate,
	status,
	actualVolume,
	currentVolume,
	cutOffDate,
	displayEndDate,
	displayPercentageMax,
	displayPercentageMin,
	interimPaid,
	payoutPaid,
	leaderUsername,
	leaderID,
	refund,
	minValue,
	minTotalValue,
	isRepeat,
	repeatFrequency,
	createdAt,
	updatedAt,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const [editTitle, setEditTitle] = useState(title);
	const [editDuration, setEditDuration] = useState(duration);
	const [editCurrentVolume, setEditCurrentVolume] = useState(currentVolume);
	const [editMaxVolume, setEditMaxVolume] = useState(maxVolume);
	const [editPercentage, setEditPercentage] = useState(percentage);
	const [editPercentageMin, setEditPercentageMin] = useState(displayPercentageMin);
	const [editPercentageMax, setEditPercentageMax] = useState(displayPercentageMax);
	const [editLaunchDate, setEditLaunchDate] = useState(formatCustomDate(launchDate));
	const [editStartDate, setEditStartDate] = useState(startDate ? formatCustomDate(startDate) : '');
	const [editStatus, setEditStatus] = useState(status);
	const [editLeader, setEditLeader] = useState(leaderUsername);
	const [editMinValue, setEditMinValue] = useState(minValue);
	const [editMinTotalValue, setEditMinTotalValue] = useState(minTotalValue);
	const [editRepeat, setEditRepeat] = useState(isRepeat);
	const [editRepeatFrequency, setEditRepeatFrequency] = useState(repeatFrequency);

	const { authUser } = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};
	const handleViewClickOpen = () => {
		setIsViewDialogOpen(true);
	};
	const handleDeleteClickOpen = () => {
		setIsDeleteDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const handleViewClose = () => {
		setIsViewDialogOpen(false);
	};
	const handleDeleteClose = () => {
		setIsDeleteDialogOpen(false);
	};

	const submitEdit = (e) => {
		e.preventDefault();
		dispatch(editCampaign({
			id,
			title: editTitle || title,
			duration: editDuration || duration,
			currentVolume: editCurrentVolume || currentVolume,
			maxVolume: editMaxVolume || maxVolume,
			percentage: editPercentage || percentage,
			percentageMin: editPercentageMin || displayPercentageMin,
			percentageMax: editPercentageMax || displayPercentageMax,
			launchDate: editLaunchDate || launchDate,
			startDate: editStartDate,
			status: editStatus || status,
			leader: editLeader || leaderUsername,
			minValue: editMinValue || minValue,
			minTotalValue: editMinTotalValue || minTotalValue,
			isRepeat: editRepeat || isRepeat,
			repeatFrequency: editRepeatFrequency || repeatFrequency,
		}));
		setIsDialogOpen(false);
	}

	const submitDelete = (e) => {
		e.preventDefault();
		dispatch(deleteCampaign({ id, }));
		setIsDeleteDialogOpen(false);
	}

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{title}</h6>
				<small>{isRepeat ? <u><b>Repeat</b></u> : "No Repeat"}{" "}
					({repeatFrequency ? repeatFrequency + " days" : "-"})</small>
			</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : "-"}</td>
			<td>
				{leaderUsername ?
					<>{leaderUsername}<br />
						<small>ID: {leaderID}</small></>
					: '-'
				}
			</td>
			<td>
				{duration}
			</td>
			<td>
				{minValue ? minValue : '-'}
			</td>
			<td>
				{minTotalValue ? minTotalValue : '-'}
			</td>
			<td>
				{actualVolume}
			</td>
			<td>
				{currentVolume}/{maxVolume}
			</td>
			<td>
				{percentage}% ({displayPercentageMin}%-{displayPercentageMax}%)
			</td>
			<td>{launchDate ? formatDateTime(launchDate) : '-'}</td>
			<td>{startDate ? formatDateTime(startDate) : '-'}</td>
			<td>{endDate ? formatDateTime(endDate) : '-'}</td>
			<td>
				{payoutPaid ? "Paid" : "Not Paid"}
			</td>
			<td>
				{authUser?.role > 2 &&
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Edit"
							onClick={handleClickOpen}
						>
							<i className="fa fa-edit" />
						</button>
						<Dialog open={isDialogOpen} onClose={handleClose}>
							<div className="modal-content">
								<form onSubmit={submitEdit}>
									<div className="modal-header">
										<h5 className="modal-title">Edit {title}</h5>
										<button type="button" className="close" onClick={handleClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Title</label>
													<input type="text" className="form-control" placeholder="title" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Duration</label>
													<input type="number" className="form-control" value={editDuration} onChange={(e) => setEditDuration(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Ticket Value<br /><small>Min value of each ticket</small></label>
													<input type="number" className="form-control" value={editMinValue} onChange={(e) => setEditMinValue(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Total Ticket Value<br /><small>Min total sum of the tickets to join campaign</small></label>
													<input type="number" className="form-control" value={editMinTotalValue} onChange={(e) => setEditMinTotalValue(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Leader<br /><small className='text-muted'>Enter Leader username - only leader's referral network can see this campaign<br />Leave blank for all users to see campaign </small></label>
													<div className="input-group">
														<select
															className="form-control custom-select"
															value={editLeader}
															onChange={(e) => setEditLeader(e.target.value)}>
															<option value="">-</option>
															{allLeaders && allLeaders.length > 0 ?
																allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
																: <></>
															}
														</select>
													</div>
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Current Volume</label>
													<input type="number" className="form-control" value={editCurrentVolume} onChange={(e) => setEditCurrentVolume(e.target.value)} />

												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Max Volume</label>
													<input type="number" className="form-control" value={editMaxVolume} onChange={(e) => setEditMaxVolume(e.target.value)} />

												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Actual Percentage</label>
													<input type="number" className="form-control" value={editPercentage} onChange={(e) => setEditPercentage(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Display Percentage</label>
													<input type="number" className="form-control" value={editPercentageMin} onChange={(e) => setEditPercentageMin(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Max Display Percentage</label>
													<input type="number" className="form-control" value={editPercentageMax} onChange={(e) => setEditPercentageMax(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Status</label>
													<select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
														<option value="PENDING">Pending</option>
														<option value="ACTIVE">Active</option>
														<option value="PENDING_RUNNING">Pending Running</option>
														<option value="COMPLETED">Completed</option>
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Launch Date<br /><small>Launch date - campaign will start to allow users to join on the launch date</small></label>
													<input type="date" className="form-control" value={editLaunchDate} onChange={(e) => setEditLaunchDate(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Start Date<br /><small>With start date, campaign will run on the start date</small></label>
													<input type="date" className="form-control" value={editStartDate} onChange={(e) => setEditStartDate(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Repeat<br /><small>If set as repeat, this campaign generate additional duplicate campaigns with the repeat frequency </small></label>
													<select className="form-control custom-select" value={editRepeat} onChange={(e) => setEditRepeat(e.target.value)}>
														<option value={true}>Repeat</option>
														<option value={false}>No Repeat</option>
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Repeat Frequency<br /><small>Set the number of days for the interval between Launch Date for the next campaign to be generated</small></label>
													<input type="number" className="form-control" value={editRepeatFrequency} onChange={(e) => setEditRepeatFrequency(e.target.value)} min={0} />
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
										<button type="submit" className="btn btn-primary">Update Campaign</button>
									</div>
								</form>
							</div>
						</Dialog>
					</>
				}
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleViewClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isViewDialogOpen} onClose={handleViewClose}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View {title}</h5>
							<button type="button" className="close" onClick={handleViewClose}>
							</button>
						</div>
						<div className="modal-body" style={{ minWidth: '350px' }}>
							<p>
								<span className="text-bold">Title: </span>{title}<br />
								<span className="text-bold">Repeat: </span>
								{isRepeat ? "Repeat" : "No Repeat"}{" "}
								({repeatFrequency ? repeatFrequency + " days" : "-"})<br />
								<span className="text-bold">Duration: </span>{duration}<br />
								<span className="text-bold">Min Ticket Value: </span>{minValue}<br />
								<span className="text-bold">Min Total Value: </span>{minTotalValue}<br />
								<span className="text-bold">Current Volume: </span>{currentVolume}<br />
								<span className="text-bold">Max Volume: </span>{maxVolume}<br />
								<span className="text-bold">Actual Volume: </span>{actualVolume}<br />
								<span className="text-bold">Actual Percentage: </span>{percentage}<br />
								<span className="text-bold">Display Min Percentage: </span>{displayPercentageMin}<br />
								<span className="text-bold">Display Max Percentage: </span>{displayPercentageMax}<br />
								<span className="text-bold">Launch Date: </span>{launchDate ? formatDate(launchDate) : '-'}<br />
								<span className="text-bold">Start Date: </span>{startDate ? formatDate(startDate) : '-'}<br />
								<span className="text-bold">End Date: </span>{endDate ? formatDate(endDate) : '-'}<br />
								<span className="text-bold">Status: </span>{status ? <StatusBadge status={status} /> : '-'}<br />
							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" onClick={handleViewClose}>Close</button>
						</div>
					</div>
				</Dialog>
				{authUser?.role > 2 &&
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Delete"
							onClick={handleDeleteClickOpen}
						>
							<i className="text-danger fa fa-trash" />
						</button>
						<Dialog open={isDeleteDialogOpen} onClose={handleDeleteClose}>
							<div className="modal-content">
								<form onSubmit={submitDelete}>
									<div className="modal-header">
										<h5 className="modal-title">Delete {title}</h5>
										<button type="button" className="close" onClick={handleDeleteClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<p>Are You Sure?</p>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleDeleteClose}>Close</button>
										<button type="submit" className="btn btn-primary bg-danger">Delete</button>
									</div>
								</form>

							</div>
						</Dialog>
					</>
				}
			</td>
		</tr >
	)
}

const Campaigns = (props) => {
	const [search, setSearch] = useState('');
	const [searchID, setSearchID] = useState('');
	const [searchLeader, setSearchLeader] = useState('');
	const [searchStatus, setSearchStatus] = useState('');
	const [searchRepeat, setSearchRepeat] = useState('');

	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ campaign }) => campaign.campaigns.report)
	const meta = useSelector(({ campaign }) => campaign.campaigns.meta)
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		dispatch(searchCampaigns({ search, leader: searchLeader, status: searchStatus, repeat: searchRepeat, id: searchID, ...params }))
	}, [params])

	const searchUsers = (e) => {
		e.preventDefault();
		dispatch(searchCampaigns({ search, leader: searchLeader, status: searchStatus, repeat: searchRepeat, id: searchID, ...params }))
	}

	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	return (
		<div>
			<div className="card">
				<div className="card-body">
					<form className="row" onSubmit={searchUsers}>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<label>Search ID</label>
							<div className="input-group">
								<input type="text" value={searchID} onChange={(e) => setSearchID(e.target.value)} className="form-control" />
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<label>Search Campaign Name</label>
							<div className="input-group">
								<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." />
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Status</label>
								<select
									className="form-control custom-select"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								>
									<option value="">All</option>
									<option value="PENDING">Pending</option>
									<option value="ACTIVE">Active</option>
									<option value="PENDING_RUNNING">Pending Running</option>
									<option value="RUNNING">Running</option>
									<option value="COMPLETED">Completed</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Leader</label>
								<div className="input-group">
									<select
										disabled={authUser?.role == 2 && authUser.limitLeader}
										className="form-control custom-select"
										value={searchLeader}
										onChange={(e) => setSearchLeader(e.target.value)}>
										<option value="">All Leaders</option>
										{allLeaders && allLeaders.length > 0 ?
											allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
											: <></>
										}
									</select>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Search Repeat</label>
								<div className="input-group">
									<select
										className="form-control custom-select"
										value={searchRepeat}
										onChange={(e) => setSearchRepeat(e.target.value)}>
										<option value="">All</option>
										<option value="repeat">Repeat</option>
										<option value="norepeat">No Repeat</option>
									</select>
								</div>
							</div>
						</div>
						<div className='col-12'>
							<button type="submit" className="btn btn-primary">
								<i className="fe fe-search mr-2" />
								Search
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className="mt-3">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">Campaign</h3>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
								<thead>
									<tr>
										<th>ID</th>
										<th>Title</th>
										<th>Created</th>
										<th>Status</th>
										<th>Leader</th>
										<th>Duration (Business Days)</th>
										<th>Min Ticket Value</th>
										<th>Min Total Value</th>
										<th>Actual Volume</th>
										<th>Current Volume / Max</th>
										<th>ROI% (Display%)</th>
										<th>Launch Date</th>
										<th>Start Date</th>
										<th>End Date</th>
										<th>Paid?</th>
										<th className="w100">Action</th>
									</tr>
								</thead>
								<tbody>
									{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={9}><p className="text-center">No data found</p></td></tr>}
								</tbody>
							</table>
							<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
						</div>
					</div>
				</div>
			</div>
		</div>

	);

}

export default Campaigns;