import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import isBefore from 'date-fns/isBefore'

import { getDeposits, getDepositSummary, getDepositSearch, approveDeposit, exportDeposits } from '../../../actions/depositAction';
import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { Dialog, DialogContent } from '@material-ui/core';

const Status = ({ status }) => {
    let type;
    switch (status) {
        case "PENDING":
            type = <span className="tag tag-default">Pending</span>
            break;
        case "APPROVED":
            type = <span className="tag tag-info">Approved</span>
            break;
        case "EXPIRED":
            type = <span className="tag tag-warn">Expired</span>
            break;
        case "REJECT":
            type = <span className="tag tag-danger">Rejected</span>
            break;
        case "FAILED":
            type = <span className="tag tag-danger">Failed</span>
            break;
        case "SUCCESS":
            type = <span className="tag tag-success text-muted">Success</span>
            break;
        default:
            type = <span className="tag tag-default">{status}</span>
            break;
    }
    return type;
}

const DepositRow = ({
    id,
    username,
    email,
    type, //currency
    amount,
    cryptoAmount,
    address,
    hash,
    status,
    remarks,
    previousHash,
    adminApproved,
    approvedTimestamp,
    adjustAmount,
    createdAt,
    completedAt,
    // authRole,
}) => {

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
    const [error, setError] = useState('');
    const [editHash, setEditHash] = useState(hash || '');
    const [editAmount, setEditAmount] = useState('');
    const [editRemarks, setEditRemarks] = useState('');
    const [canApprove, setCanApprove] = useState(!adminApproved && (status === "PENDING" || status === "EXPIRED" || status === "FAILED"));


    const dispatch = useDispatch();
    const { authUser } = useSelector(({ user }) => user);

    const handleClose = () => {
        setIsDialogOpen(false)
    }
    const handleClickOpen = () => {
        setIsDialogOpen(true)
    }
    const handleViewClose = () => {
        setIsViewDialogOpen(false)
    }
    const handleViewClickOpen = () => {
        setIsViewDialogOpen(true)
    }
    const clearFields = (e) => {
        e.preventDefault();
        setError('')
    }

    const validateSubmit = () => {
        if (editAmount && editAmount < 0) {
            setError('Amount Cannot be negative')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const hasError = validateSubmit();
        if (!hasError) {
            dispatch(approveDeposit({
                id,
                hash: editHash,
                amount: editAmount,
                remarks: editRemarks,
            }));
            clearFields(e)
            setError('')
        } else {
            setError(hasError)
        }
        setIsDialogOpen(false);
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{username}<br /><small>{email}</small></td>
            <td>{type}</td>
            <td style={{ maxWidth: 150 }}>{address}</td>
            <td style={{ maxWidth: 200 }}>{hash ? hash : '-'}</td>
            <td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
            <td>{approvedTimestamp ? formatDateTime(approvedTimestamp) : '-'}</td>
            <td>{completedAt ? formatDateTime(completedAt) : '-'}</td>
            <td>{status ? <Status status={status} /> : '-'}</td>
            <td>${formatCurrency(amount || 0)}</td>
            <td>${formatCurrency(cryptoAmount || 0)}</td>
            <td>
                {authUser?.role && Number(authUser?.role) >= 8 && canApprove &&
                    <>
                        <button
                            type="button"
                            className="btn btn-icon"
                            title="Edit"
                            onClick={handleClickOpen}
                        >
                            <i className="text-success fa fa-edit" />
                        </button>
                        <Dialog open={isDialogOpen} onClose={handleClose}>
                            <DialogContent>
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-header" style={{ minWidth: 400 }}>
                                        <h5 className="modal-title">Deposit {username}</h5>
                                        <button type="button" className="close" onClick={handleClose}>
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <label className="form-label">Username</label>
                                                <p>{username}</p>
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <label className="form-label">Currency</label>
                                                <p>{type}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <label className="form-label">Amount Requested</label>
                                                <p>${formatCurrency(amount || 0)}</p>
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <label className="form-label">Amount Received</label>
                                                <p>${formatCurrency(cryptoAmount || 0)}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label">Address</label>
                                                <p>{address}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-label">Hash</label>
                                                <p style={{ wordBreak: 'break-all' }}>{hash}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <label className="form-label">Status</label>
                                                <p>{status ? <Status status={status} /> : '-'}</p>
                                            </div>
                                        </div>
                                        {adminApproved && <>
                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">Approved Timestamp</label>
                                                    <p>{approvedTimestamp ? formatDateTime(approvedTimestamp) : '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">Previous Hash</label>
                                                    <p>{previousHash}</p>
                                                </div>
                                            </div>

                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">Adjustment Amount</label>
                                                    <p>${formatCurrency(adjustAmount || 0)}</p>
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">Remarks</label>
                                                    <p>{remarks}</p>
                                                </div>
                                            </div>
                                        </>}
                                        {canApprove && <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Amount</label>
                                                        <input
                                                            type="number"
                                                            required
                                                            className="form-control" placeholder="Amount" value={editAmount} onChange={(e) => setEditAmount(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Hash</label>
                                                        <input type="text" className="form-control" placeholder="Remarks" value={editHash} onChange={(e) => setEditHash(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Remarks</label>
                                                        <input type="text" className="form-control" placeholder="Remarks" value={editRemarks} onChange={(e) => setEditRemarks(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                        {canApprove && <button type="submit" className="btn btn-primary">Approve</button>}
                                    </div>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </>
                }
                {authUser?.role && Number(authUser?.role) >= 2 &&
                    <>
                        <button
                            type="button"
                            className="btn btn-icon"
                            title="View"
                            onClick={handleViewClickOpen}
                        >
                            <i className="text-muted fa fa-eye" />
                        </button>
                        <Dialog open={isViewDialogOpen} onClose={handleViewClose}>
                            <DialogContent>
                                <div className="modal-header" style={{ minWidth: 400 }}>
                                    <h5 className="modal-title">Deposit {username}</h5>
                                    <button type="button" className="close" onClick={handleViewClose}>
                                        {/* <span aria-hidden="true">&times;</span> */}
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <label className="form-label">Username</label>
                                            <p>{username}</p>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <label className="form-label">Currency</label>
                                            <p>{type}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <label className="form-label">Amount Requested</label>
                                            <p>${formatCurrency(amount || 0)}</p>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <label className="form-label">Amount Received</label>
                                            <p>${formatCurrency(cryptoAmount || 0)}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label">Address</label>
                                            <p>{address}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="form-label">Hash</label>
                                            <p style={{ wordBreak: 'break-all' }}>{hash}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <label className="form-label">Status</label>
                                            <p>{status ? <Status status={status} /> : '-'}</p>
                                        </div>
                                    </div>
                                    {adminApproved && <>
                                        <div className="mb-2 row">
                                            <div className="col-12">
                                                <label className="form-label">Approved Timestamp</label>
                                                <p>{approvedTimestamp ? formatDateTime(approvedTimestamp) : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-2 row">
                                            <div className="col-12">
                                                <label className="form-label">Previous Hash</label>
                                                <p>{previousHash}</p>
                                            </div>
                                        </div>

                                        <div className="mb-2 row">
                                            <div className="col-12">
                                                <label className="form-label">Adjustment Amount</label>
                                                <p>${formatCurrency(adjustAmount || 0)}</p>
                                            </div>
                                        </div>
                                        <div className="mb-2 row">
                                            <div className="col-12">
                                                <label className="form-label">Remarks</label>
                                                <p>{remarks}</p>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleViewClose}>Close</button>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </>
                }
            </td>
        </tr>
    )
}

const Deposits = (props) => {
    const [searchStatus, setSearchStatus] = useState("ALL")
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [hash, setHash] = useState("")
    const [address, setAddress] = useState("")
    const [approvalStart, setapprovalStart] = useState("")
    const [approvalEnd, setapprovalEnd] = useState("")
    const [completedStart, setcompletedStart] = useState("")
    const [completedEnd, setcompletedEnd] = useState("")
    const [fromNetwork, setfromNetwork] = useState("")
    const [excludeNetwork, setexcludeNetwork] = useState([])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState('');
    const [tab, setTab] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCryptoAmount, setTotalCryptoAmount] = useState(0);
    const [params, setParams] = useState({ page: 1, limit: 10 })

    const dispatch = useDispatch();
    const deposits = useSelector(({ deposit }) => deposit.deposits.report)
    const meta = useSelector(({ deposit }) => deposit.deposits.meta)

    const summary = useSelector(({ deposit }) => deposit.summary)
    const { authUser } = useSelector(({ user }) => user);

    useEffect(() => {
        dispatch(getDepositSummary())
    }, [])

    useEffect(() => {
        dispatch(getDeposits({
            username,
            email,
            hash,
            address,
            startDate,
            endDate,
            approvalStart,
            approvalEnd,
            completedStart,
            completedEnd,
            fromNetwork,
            excludeNetwork,
            status: searchStatus === 'ALL' ? undefined : [searchStatus],
            ...params
        }))
    }, [params])

    useEffect(() => {
        calculateTotalAmount()
    }, [deposits])

    const calculateTotalAmount = () => {
        if (deposits) {
            const sum = deposits.reduce((a, b) => a + Number(b?.amount || 0), 0)
            setTotalAmount(sum)
            const sumCrypto = deposits.reduce((a, b) => a + Number(b?.cryptoAmount || 0), 0)
            setTotalCryptoAmount(sumCrypto)
        }
    }

    const validateFilterDeposit = () => {
        if (!searchStatus) {
            return ('No status selected');
        } else if (isBefore(new Date(endDate), new Date(startDate))) {
            return ('Invalid End Date');
        } else {
            return ('');
        }
    }

    const handleResetFilters = () => {
        setSearchStatus("ALL")
        setStartDate("")
        setEndDate("")
        setUsername("")
        setEmail("")
        setHash("")
        setAddress("")
        setapprovalStart("")
        setapprovalEnd("")
        setcompletedStart("")
        setcompletedEnd("")
        setfromNetwork("")
        setexcludeNetwork([])
        setParams({ page: 1, limit: 10 })
        setError('')
    }

    const handleAddExcludeNetwork = () => {
        setexcludeNetwork([...excludeNetwork, ""])
    }
    const handleSetExcludeNetwork = (data, id) => {
        setexcludeNetwork([
            ...excludeNetwork.slice(0, id),
            data,
            ...excludeNetwork.slice(id + 1)
        ])
    }
    const handleRemoveExcludeNetwork = (id) => {
        setexcludeNetwork([
            ...excludeNetwork.slice(0, id),
            ...excludeNetwork.slice(id + 1)
        ])
    }

    const handleSetTab = (e, index) => {
        e.preventDefault();
        setParams({ ...params, page: 1, })
        setTab(index)
    }

    const submitFilterDeposit = (e) => {
        e.preventDefault();
        const hasError = validateFilterDeposit();

        if (!hasError) {
            dispatch(getDeposits({
                ...params,
                username,
                email,
                hash,
                address,
                startDate,
                endDate,
                approvalStartDate: approvalStart,
                approvalEndDate: approvalEnd,
                completedStartDate: completedStart,
                completedEndDate: completedEnd,
                fromNetwork,
                excludeNetwork,
                status: searchStatus === 'ALL' ? undefined : [searchStatus],
            }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    const handleExportData = () => {
        dispatch(exportDeposits())
    }

    return (

        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Deposits</a></li>
                            {/* <li className="nav-item"><a className="nav-link" id="Accounts-tab" data-toggle="tab" href="#Accounts-Expenses">Expenses</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div>
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Total Deposits</div>
                                            <div className="py-4 m-0 text-left h1 text-info counter">${formatCurrency(summary?.total || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Daily Deposits</div>
                                            <div className="py-4 m-0 text-left h1 text-info counter">${formatCurrency(summary?.daily || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Monthly Deposits</div>
                                            <div className="py-4 m-0 text-left h1 text-info counter">${formatCurrency(summary?.monthly || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row" onSubmit={submitFilterDeposit}>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Created Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Created End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={endDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Approval Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={approvalStart}
                                                        onChange={(e) => setapprovalStart(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Approval End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={approvalEnd}
                                                        onChange={(e) => setapprovalEnd(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Completed Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={completedStart}
                                                        onChange={(e) => setcompletedStart(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Completed End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={completedEnd}
                                                        onChange={(e) => setcompletedEnd(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12'></div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Username</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={username}
                                                        onChange={(e) => setUsername(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Email</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Status</label>
                                                <div className="multiselect_div">
                                                    <select className="custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                        <option value="ALL">All</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="FAILED">Failed</option>
                                                        <option value="SUCCESS">Success</option>
                                                        <option value="EXPIRED">Expired</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="mt-1">Hash</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={hash}
                                                        onChange={(e) => setHash(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="mt-1">Address</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">From Network</label>
                                                <div className="input-group">
                                                    <input
                                                        disabled={authUser?.role == 2 && authUser.limitLeader}
                                                        type="text"
                                                        className="form-control"
                                                        value={fromNetwork}
                                                        onChange={(e) => setfromNetwork(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label className="mt-1">Exclude Network</label>
                                                {excludeNetwork.map((data, i) =>
                                                    <div className="input-group mb-1" key={i}>
                                                        <input
                                                            required
                                                            type="text"
                                                            className="form-control"
                                                            value={data}
                                                            onChange={(e) => handleSetExcludeNetwork(e.target.value, i)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-icon"
                                                            title="Delete"
                                                            onClick={() => handleRemoveExcludeNetwork(i)}
                                                        >
                                                            <i className="text-danger fa fa-trash" />
                                                        </button>
                                                    </div>
                                                )}
                                                <br />
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    title="Add"
                                                    onClick={() => handleAddExcludeNetwork()}
                                                >
                                                    <i className="text-success fa fa-plus" /> Add
                                                </button>
                                            </div>
                                            <div className='col-12'></div>
                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                <label className="mt-1">&nbsp;</label>
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Filter
                                                </button>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6">
                                                <label>&nbsp;</label>
                                                <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                    Reset
                                                </button>
                                            </div>
                                            {authUser?.role && Number(authUser?.role) >= 4 &&
                                                < div className="col-lg-2 col-md-4 col-sm-6">
                                                    <label>&nbsp;</label>
                                                    <button type="button" className="btn btn-outline-secondary btn-block" onClick={handleExportData}>
                                                        Export Excel
                                                    </button>
                                                </div>
                                            }
                                        </form>
                                        <div className="row">
                                            <div className="col-12">{renderError()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Deposits</h3>
                                    {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-username" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username/Email</th>
                                                    <th>Currency</th>
                                                    <th className="w150">Address</th>
                                                    <th className="w150">Hash</th>
                                                    <th>Request Date</th>
                                                    <th>Approval Date</th>
                                                    <th>Completed Date</th>
                                                    <th>Status</th>
                                                    <th className="w150">Amount</th>
                                                    <th className="w150">Crypto Amount</th>
                                                    <th className="w150">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deposits && deposits.length > 0 ? deposits.map((w, i) => <DepositRow key={i} {...w} authRole={authUser.role} />)
                                                    :
                                                    <tr><td colSpan={11}><p className="text-center">No deposits found</p></td></tr>
                                                }
                                                <tr>
                                                    <td colSpan={9}></td>
                                                    <td><small>Total Amount</small><br />{totalAmount ? formatCurrency(totalAmount) : '-'}</td>
                                                    <td><small>Total Crypto Amount</small><br />{totalCryptoAmount ? formatCurrency(totalCryptoAmount) : '-'}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                    </div>
                                    {/* <nav aria-label="Page navigation">
                                            <ul className="pagination mb-0 justify-content-end">
                                                <li className="page-item"><a className="page-link" href="/#">Previous</a></li>
                                                <li className="page-item active"><a className="page-link" href="/#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">Next</a></li>
                                            </ul>
                                        </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </div >

    )

}

export default Deposits;