import React, { useState } from 'react';
import { createAnnouncement } from '../../../actions/announcementAction';
import { useDispatch } from 'react-redux';

const CreateAnnouncement = (props) => {
    const [upload, setUpload] = useState(null);
    const [error, setError] = useState('');
    const [addTitle, setAddTitle] = useState('');
    const [addDescription, setAddDescription] = useState('');
    const [addStatus, setAddStatus] = useState('LIVE');
    const [addType, setAddType] = useState('MEMO');
    const [addStartDate, setAddStartDate] = useState('');
    const [addEndDate, setAddEndDate] = useState('');
    const [addLeader, setAddLeader] = useState('');

    const dispatch = useDispatch();

    const clearFields = (e) => {
        e.preventDefault();
        setAddTitle('');
        setAddDescription('');
        setAddStatus('');
        setAddStartDate('');
        setAddEndDate('');
        setAddLeader('');
        setUpload(null)
        setError('')
    }

    const handleFileChange = (e) => {
        const files = e.target.files
        setUpload(files)
    }
    const validateFiles = () => {
        if (!addTitle) {
            return ('Title required');
        } else if (!upload) {
            return ('Upload required');
        } else {
            return ('');
        }
    }
    const handleSubmit = (e, index) => {
        e.preventDefault();
        const hasError = validateFiles();
        if (!hasError) {
            dispatch(createAnnouncement({
                title: addTitle,
                description: addDescription,
                leader: addLeader,
                type: addType,
                status: addStatus,
                startDate: addStartDate,
                endDate: addEndDate,
                image: upload[0]
            }))
            clearFields(e)
            setError('')
        } else {
            setError(hasError)
        }
    }

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <div className="row clearfix">
                        <div className="col-12">
                            <h6>Create New Announcement</h6>
                            <hr className="mt-4" />
                            <form onSubmit={handleSubmit}>
                                <div className="row clearfix">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Title</label>
                                            <input
                                                required
                                                type="text"
                                                className="form-control"
                                                placeholder="Title"
                                                value={addTitle}
                                                onChange={(e) => setAddTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={addDescription}
                                                onChange={(e) => setAddDescription(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Leader</label>
                                            <input type="text" className="form-control" value={addLeader} onChange={(e) => setAddLeader(e.target.value)} />
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Type</label>
                                            <select
                                                required
                                                className="form-control custom-select"
                                                value={addType}
                                                onChange={(e) => setAddType(e.target.value)}
                                            >
                                                <option value="MEMO">Memo</option>
                                                {/* <option value="ANNOUNCEMENT">ANNOUNCEMENT</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Status</label>
                                            <select
                                                required
                                                className="form-control custom-select"
                                                value={addStatus}
                                                onChange={(e) => setAddStatus(e.target.value)}
                                            >
                                                <option value="LIVE">LIVE</option>
                                                <option value="DISABLED">DISABLED</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Start Date</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={addStartDate}
                                                onChange={(e) => setAddStartDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">End Date</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={addEndDate}
                                                onChange={(e) => setAddEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Image</label>
                                            <input
                                                onChange={handleFileChange}
                                                required
                                                name="file"
                                                type="file"
                                                className="dropify"
                                                accept="image/png, image/jpeg, image/jpg"
                                                data-allowed-file-extensions="png jpg jpeg"
                                                data-max-file-size="5M"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        {renderError()}
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary mr-2">
                                            Add
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={clearFields}
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateAnnouncement;