import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createCampaign } from '../../../actions/campaignAction';
import { getAllLeaders } from '../../../actions/siteAction';

const NewCampaign = (props) => {
	const [addTitle, setAddTitle] = useState('');
	const [addDuration, setAddDuration] = useState('');
	const [addMaxVolume, setAddMaxVolume] = useState('');
	const [addPercentage, setAddPercentage] = useState('');
	const [addPercentageMin, setAddPercentageMin] = useState('');
	const [addPercentageMax, setAddPercentageMax] = useState('');
	const [addLaunchDate, setAddLaunchDate] = useState('');
	const [addStarthDate, setAddStartDate] = useState('');
	const [addMinValue, setAddMinValue] = useState('');
	const [addMinTotalValue, setAddMinTotalValue] = useState('');
	const [addLeader, setAddLeader] = useState('');
	const [addRepeat, setAddRepeat] = useState(false);
	const [addRepeatFrequency, setAddRepeatFrequency] = useState('');

	const [error, setError] = useState('');

	const dispatch = useDispatch();
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	const clearFields = (e) => {
		e.preventDefault();
		setAddTitle('');
		setAddDuration('');
		setAddMaxVolume('');
		setAddPercentage('');
		setAddPercentageMin('');
		setAddPercentageMax('');
		setAddLaunchDate('');
		setAddMinValue('');
		setAddMinTotalValue('');
		setAddLeader('');
		setAddStartDate('');
		setAddRepeat('');
		setAddRepeatFrequency('');
		setError('')
	}

	const validateAdd = () => {
		if (!addTitle) {
			return ('Title is required');
		} else if (!addDuration) {
			return ('Duration is required');
		} else if (addDuration && (isNaN(Number(addDuration)))) {
			return ('Duration must be a number');
		} else if (!addMaxVolume) {
			return ('Max Volume is required');
		} else if (addMaxVolume && (isNaN(Number(addMaxVolume)))) {
			return ('Max Volume must be a number');
		} else if (!addPercentage) {
			return ('Actual Percentage is required');
		} else if (addPercentage && (isNaN(Number(addPercentage)))) {
			return ('Actual Percentage must be a number');
		} else if (addPercentageMin && (isNaN(Number(addPercentageMin)))) {
			return ('Display Min percentage must be a number');
		} else if (addPercentageMax && (isNaN(Number(addPercentageMax)))) {
			return ('Display Min percentage must be a number');
		} else if (addMinValue && (isNaN(Number(addMinValue)))) {
			return ('Min Ticket Value must be a number');
		} else if (!addLaunchDate) {
			return ('Launch Date is required');
		} else {
			return ('');
		}
	}

	const submitAdd = (e) => {
		e.preventDefault();
		const hasError = validateAdd();

		if (!hasError) {
			dispatch(createCampaign({
				title: addTitle,
				duration: addDuration,
				launchDate: addLaunchDate,
				startDate: addStarthDate,
				maxVolume: addMaxVolume,
				percentage: addPercentage,
				percentageMax: addPercentageMax,
				percentageMin: addPercentageMin,
				leader: addLeader, //leader username
				minValue: addMinValue, //min Ticket Value
				minTotalValue: addMinTotalValue, //min Ticket Value
				isRepeat: addRepeat,
				repeatFrequency: addRepeatFrequency,
			}))
			clearFields(e)
		} else {
			setError(hasError)
		}
	}

	const renderError = () => {
		return (error ? <p className="text-danger">{error}</p> : <p>&nbsp;</p>)
	}

	return (
		<div className="card">
			<div className="card-body">
				<form onSubmit={submitAdd}>
					<div className="row clearfix">
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Title</label>
								<input type="text" className="form-control" placeholder="title" value={addTitle} onChange={(e) => setAddTitle(e.target.value)} />
							</div>
						</div>

						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Duration</label>
								<input type="number" className="form-control" value={addDuration} onChange={(e) => setAddDuration(e.target.value)} />
							</div>
						</div>

						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Max Volume</label>
								<input type="number" className="form-control" value={addMaxVolume} onChange={(e) => setAddMaxVolume(e.target.value)} />

							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Actual Percentage</label>
								<input type="number" className="form-control" value={addPercentage} onChange={(e) => setAddPercentage(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Min Display Percentage</label>
								<input type="number" className="form-control" value={addPercentageMin} onChange={(e) => setAddPercentageMin(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Max Display Percentage</label>
								<input type="number" className="form-control" value={addPercentageMax} onChange={(e) => setAddPercentageMax(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Launch Date<br /><small>Launch date - campaign will start to allow users to join on the launch date</small></label>
								<input type="date" className="form-control" value={addLaunchDate} onChange={(e) => setAddLaunchDate(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Start Date<br /><small>With start date, campaign will run on the start date</small></label>
								<input type="date" className="form-control" value={addStarthDate} onChange={(e) => setAddStartDate(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Min Ticket Value<br /><small>Min value of each ticket</small></label>
								<input type="number" className="form-control" value={addMinValue} onChange={(e) => setAddMinValue(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Min Total Tickets Value<br /><small>Min total sum of the tickets to join campaign</small></label>
								<input type="number" className="form-control" value={addMinTotalValue} onChange={(e) => setAddMinTotalValue(e.target.value)} />
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Leader<br /><small className='text-muted'>Enter Leader username - only leader's referral network can see this campaign<br />Leave blank for all users to see campaign </small></label>
								<div className="input-group">
									<select
										className="form-control custom-select"
										value={addLeader}
										onChange={(e) => setAddLeader(e.target.value)}>
										<option value="">-</option>
										{allLeaders && allLeaders.length > 0 ?
											allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
											: <></>
										}
									</select>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Repeat<br /><small>If set as repeat, this campaign generate additional duplicate campaigns with the repeat frequency</small></label>
								<select className="form-control custom-select" value={addRepeat} onChange={(e) => setAddRepeat(e.target.value)}>
									<option value={true}>Repeat</option>
									<option value={false}>No Repeat</option>
								</select>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label className="form-label">Repeat Frequency<br /><small>Set the number of days for the interval between Launch Date for the next campaign to be generated</small></label>
								<input type="number" className="form-control" value={addRepeatFrequency} onChange={(e) => setAddRepeatFrequency(e.target.value)} min={0} />
							</div>
						</div>
						<div className="col-12">
							{renderError()}
						</div>
						<div className="col-12">
							<button type="submit" className="btn btn-primary mr-2">
								Add
							</button>
							<button
								type="button"
								className="btn btn-secondary"
								onClick={clearFields}
							>
								Clear
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);

}

export default NewCampaign;