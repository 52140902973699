import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'

export const formatDate = (date) => {
  const inputDate = new Date(date)
  return format(utcToZonedTime(inputDate, 'UTC'), 'd MMM yyyy')
}

export const formatCustomDate = (date, formatString = 'yyyy-MM-dd') => {
  const inputDate = new Date(date)
  return format(utcToZonedTime(inputDate, 'UTC'), formatString)
}

export const formatDateTime = (date) => {
  const inputDate = new Date(date)
  return format(utcToZonedTime(inputDate, 'UTC'), 'd MMM yyyy HH:mm:ss')
}
