import {
	SIGNOUT_USER_SUCCESS,
	WALLET_SET_DATA,
	WALLET_SET_SEARCH_TERM,
	WALLET_SET_ADJUST_DATA,
	WALLET_SET_ADJUST_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	wallets: reportInitialState,
	walletSearch: {},
	adjustWallets: reportInitialState,
	adjustWalletsSearch: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case WALLET_SET_DATA: {
			return {
				...state,
				wallets: {
					report: action.payload.report,
					meta: action.payload.meta,
				},
			};
		}
		case WALLET_SET_SEARCH_TERM: {
			return {
				...state,
				walletSearch: action.payload,
			};
		}
		case WALLET_SET_ADJUST_DATA: {
			return {
				...state,
				adjustWallets: {
					report: action.payload.report,
					meta: action.payload.meta,
				},
			};
		}
		case WALLET_SET_ADJUST_SEARCH_TERM: {
			return {
				...state,
				adjustWalletsSearch: action.payload,
			};
		}

		default:
			return state;
	}
}
