import axios from '../util/api';
import {
  PACKAGE_SET_SEARCH,
  PACKAGE_SET_SEARCH_TERM,
  PACKAGE_SET_SEARCH_USER_PIN_PACKAGE,
  PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setPackageSearch = (payload) => {
  return {
    type: PACKAGE_SET_SEARCH,
    payload,
  };
};

export const setPackageSearchTerm = (payload) => {
  return {
    type: PACKAGE_SET_SEARCH_TERM,
    payload,
  };
};

export const setUserPinPackageSearch = (payload) => {
  return {
    type: PACKAGE_SET_SEARCH_USER_PIN_PACKAGE,
    payload,
  };
};

export const setUserPinPackageSearchTerm = (payload) => {
  return {
    type: PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM,
    payload,
  };
};

export const searchPackages = ({ search = "", page = 1, limit = 10 }) => {
  const payload = { search }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setPackageSearchTerm(search));
    axios.post(`/crackers/adm/package/get?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setPackageSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const searchUserPinPackages = ({
  search = "",
  searchPackName = "",
  status = "",
  email = "",
  leader = "",
  startDate = "",
  endDate = "",
  page = 1,
  limit = 10,
}) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      searchPackName,
      status,
      email,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
      startDate,
      endDate,
    }
    dispatch(fetchStart());
    dispatch(setUserPinPackageSearchTerm(payload));
    axios.post(`/crackers/adm/package/getpinpackages?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setUserPinPackageSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createPackage = ({
  name,
  type,
  subtype,
  rank,
  value,
  purchasable,
  direct,
  pair,
  pairMin,
  ternary,
  infinity,
  daily,
  variable,
  earningsCompound,
  maxCapMultiplier,
  expiryDays,
  image,
  special,
  noCampaign,
  noGiveDirect,
  noGivePair,
}) => {
  const payload = {
    name,
    type,
    rank,
    value,
    purchasable,
    direct,
    pair,
    pairMin,
    ternary,
    infinity,
    daily,
    variable,
    earningsCompound,
    maxCapMultiplier,
    expiryDays,
    image,
    special,
    noCampaign,
    noGiveDirect,
    noGivePair,
    subtype: subtype ? subtype : null,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().packages.searchTerm;
    axios.post('/crackers/adm/package/create', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Created Package'));
        dispatch(searchPackages({ search: searchTerm, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const editPackage = ({
  id,
  name,
  type,
  subtype,
  rank,
  value,
  purchasable,
  direct,
  pair,
  pairMin,
  ternary,
  infinity,
  daily,
  variable,
  earningsCompound,
  maxCapMultiplier,
  expiryDays,
  image,
  special,
  noCampaign,
  noGiveDirect,
  noGivePair,
}) => {
  const payload = {
    id,
    name,
    type,
    rank,
    value,
    purchasable,
    direct,
    pair,
    pairMin,
    ternary,
    infinity,
    daily,
    variable,
    earningsCompound,
    maxCapMultiplier,
    expiryDays,
    image,
    special,
    noCampaign,
    noGiveDirect,
    noGivePair,
    subtype: subtype ? subtype : null,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().packages.searchTerm;
    axios.post('/crackers/adm/package/update', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Package updated'));
        dispatch(searchPackages({ search: searchTerm, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const deletePackage = ({
  id,
}) => {
  const payload = {
    id,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().packages.searchTerm;
    axios.post('/crackers/adm/package/delete', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Package deleted'));
        dispatch(searchPackages({ search: searchTerm, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
