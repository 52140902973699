import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';


import rootReducer from './reducers/rootReducer';
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];

function configureStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
        rootReducer(history),
        composeEnhancers(applyMiddleware(...middlewares))
    );
}

const store = configureStore()

export default store;
export { history };
