import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  SUPPORT_SET_TICKETS,
  SUPPORT_SET_TICKETS_TERMS,
  SUPPORT_SET_CHAT_CONVERSATIONS,
  SUPPORT_SET_CHAT_SUPPORTID,
  SUPPORT_SET_CHAT_TICKET_DETAILS,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setTickets = (payload) => {
  return {
    type: SUPPORT_SET_TICKETS,
    payload,
  };
};

export const setTicketSearchTerms = (payload) => {
  return {
    type: SUPPORT_SET_TICKETS_TERMS,
    payload,
  };
};

export const setChatConversations = (payload) => {
  return {
    type: SUPPORT_SET_CHAT_CONVERSATIONS,
    payload,
  };
};

export const setChatSupportID = (payload) => {
  return {
    type: SUPPORT_SET_CHAT_SUPPORTID,
    payload,
  };
};

export const setTicketDetails = (payload) => {
  return {
    type: SUPPORT_SET_CHAT_TICKET_DETAILS,
    payload,
  };
};

export const getTickets = ({
  startDate,
  endDate,
  status,
} = { startDate: null, endDate: null, status: null }) => {
  const payload = { startDate, endDate, status }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setTicketSearchTerms(payload));
    axios.post('/crackers/adm/support/tickets', payload).then(({ data }) => {
      if (data && data.result) {
        dispatch(fetchSuccess());
        dispatch(setTickets(data.result));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const ticketAction = ({
  supportID,
  action,
}) => {
  const payload = {
    supportID,
    action,
  }
  return (dispatch, getStore) => {
    const searchTerms = getStore().support.searchTerms;
    dispatch(fetchStart());
    axios.post('/crackers/adm/support/ticketaction', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Ticket ${action}`))
        dispatch(getTickets(searchTerms))
        dispatch(setChatSupportID(null));
        dispatch(setTicketDetails({}));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
      dispatch(setChatSupportID(null));
      dispatch(setTicketDetails({}));
    });
  }
};

export const getTicketConversations = () => {
  return (dispatch, getStore) => {
    const chatSupportID = getStore().support.chatSupportID;
    const payload = {
      supportID: chatSupportID,
    }
    dispatch(fetchStart());
    axios.post('/crackers/adm/support/getconversations', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setChatConversations(data.conversation));
      }
    }).catch(function (error) {
      dispatch(setChatConversations());
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const replyTicketConversation = (message) => {
  return (dispatch, getStore) => {
    const chatSupportID = getStore().support.chatSupportID;
    const payload = {
      supportID: chatSupportID,
      message,
    }
    dispatch(fetchStart());
    axios.post('/crackers/adm/support/submitreply', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Message Sent'));
        dispatch(getTicketConversations());
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
