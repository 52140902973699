import {
	SIGNOUT_USER_SUCCESS,
	PAYOUT_SET_SEARCH,
	PAYOUT_SET_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	payouts: reportInitialState,
	searchTerm: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case PAYOUT_SET_SEARCH: {
			return {
				...state,
				payouts: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case PAYOUT_SET_SEARCH_TERM: {
			return {
				...state,
				searchTerm: action.payload,
			};
		}
		default:
			return state;
	}
}
