// import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  KYC_SET_DATA,
  KYC_SET_SEARCH_TERM,
  KYC_SET_ADJUST_DATA,
  KYC_SET_ADJUST_SEARCH_TERM,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';
import { exportToExcel } from '../util/exportExcel';

export const setKycs = (payload) => {
  return {
    type: KYC_SET_DATA,
    payload,
  };
};

export const setKycsSearchTerm = (payload) => {
  return {
    type: KYC_SET_SEARCH_TERM,
    payload,
  };
};

export const setKycsAdjustData = (payload) => {
  return {
    type: KYC_SET_ADJUST_DATA,
    payload,
  };
};

export const setKycsAdjustSearchTerm = (payload) => {
  return {
    type: KYC_SET_ADJUST_SEARCH_TERM,
    payload,
  };
};

export const getKYCSearch = ({
  search = '',
  status = '',
  email = '',
  leader = '',
  approvalStartDate = null,
  approvalEndDate = null,
  startDate = null,
  endDate = null,
  page = 1,
  limit = 10
}) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      status,
      email,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
      startDate,
      endDate,
      approvalStartDate,
      approvalEndDate
    }
    dispatch(fetchStart());
    dispatch(setKycsSearchTerm({ ...payload, page, limit }));
    axios.post(`/crackers/adm/kyc/get?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setKycs({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const approveKYC = ({ id, remarks, action } = { id: '', action: '', remarks: '' }) => {
  const payload = { id, action, remarks }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().kyc.search;
    axios.post(`/crackers/adm/kyc/approve`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(getKYCSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

// export const exportKycs = () => {
//   return (dispatch, getStore) => {
//     dispatch(fetchStart());
//     const payload = getStore().kyc.search
//     axios.post(`/crackers/adm/kyc/get`, { ...payload, all: true }).then(({ data }) => {
//       if (data && data.success) {
//         dispatch(fetchSuccess());
//         exportToExcel({
//           data: data.report,
//           headers: [
//             {
//               key: 'id',
//               label: "ID",
//             },
//             {
//               key: 'firstname',
//               label: "First Name",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'username',
//               label: "Username",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'email',
//               label: "Email",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'mobile',
//               label: "Mobile",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'uid',
//               label: "UID",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'referrerUsername',
//               label: "Sponsor",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'packageID',
//               label: "Largest Package ID",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'packageValue',
//               label: "Largest Package Value",
//               formatter: (value) => value ? formatCurrency(value) : '-',
//               s: {
//                 alignment: {
//                   horizontal: 'right',
//                 },
//               },
//             },
//             {
//               key: 'leader',
//               label: "Leader",
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'ranking',
//               label: 'Rank',
//               formatter: (value) => value ? userRankingString(value) : '-',
//             },
//             {
//               key: 'earnings',
//               label: 'Earnings',
//               formatter: (value) => value ? formatCurrency(value) : '-',
//               s: {
//                 alignment: {
//                   horizontal: 'right',
//                 },
//               },
//             },
//             {
//               key: 'sales',
//               label: 'Sales',
//               formatter: (value) => value ? formatCurrency(value) : '-',
//               s: {
//                 alignment: {
//                   horizontal: 'right',
//                 },
//               },
//             },
//             {
//               key: 'emailVerified',
//               label: 'Email Verified',
//               formatter: (value) => value ? "Yes" : 'No',
//             },
//             {
//               key: 'canViewSpecial',
//               label: 'View Special Package',
//               formatter: (value) => value ? "Yes" : 'No',
//             },
//             {
//               key: 'withdrawalBlocked',
//               label: 'Is Withdrawal Blocked',
//               formatter: (value) => value ? "Yes" : 'No',
//             },
//             {
//               key: 'notPlaced',
//               label: 'Referrals Not Placed',
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'status',
//               label: 'Status',
//               formatter: (value) => value ? value : '-',
//             },
//             {
//               key: 'createdAt',
//               label: 'Created Date',
//               formatter: (value) => value ? formatDateTime(value) : '-',
//             },
//           ],
//           title: "Users"
//         })
//       }
//     }).catch(function (error) {
//       if (error && error.response && error.response.data) {
//         dispatch(fetchError(error.response.data));
//       } else {
//         dispatch(fetchError('Unable to get a response'));
//       }
//     });
//   }
// };
