import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';

import { searchAdminUsers, editAdminUsers, addAdminUsers } from '../../../actions/adminAction';
import { formatDateTime } from '../../../util/date';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';

const UserRoles = ({ role }) => {
	let type;
	switch (role) {
		case "1":
			type = <span className="tag tag-default">Guest</span>
			break;
		case "2":
			type = <span className="tag tag-default">Marketing</span>
			break;
		case "4":
			type = <span className="tag tag-success">Support</span>
			break;
		case "8":
			type = <span className="tag tag-info">Finance</span>
			break;
		case "16":
			type = <span className="tag tag-danger">Admin</span>
			break;
		case "32":
			type = <span className="tag tag-danger">Super Admin</span>
			break;
		default:
			type = <span className="tag tag-default">Guest</span>
			break;
	}
	return type;
}

const userRolesString = (role) => {
	let type;
	switch (role) {
		case "1":
			type = "Guest"
			break;
		case "2":
			type = "Marketing"
			break;
		case "4":
			type = "Support"
			break;
		case "8":
			type = "Finance"
			break;
		case "16":
			type = "Admin"
			break;
		case "32":
			type = "Super Admin"
			break;
		default:
			type = "Guest"
			break;
	}
	return type;
}


const UserRow = ({
	id,
	username,
	email,
	role,
	leader,
	status,
	createdAt,
	authRole
}) => {

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [editEmail, setEditEmail] = useState(email);
	const [editRole, setEditRole] = useState(role);
	const [editStatus, setEditStatus] = useState(status);
	const [editLeader, setEditLeader] = useState(leader || '');

	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const submitEditUsers = (e) => {
		e.preventDefault();
		dispatch(editAdminUsers({
			id,
			email: editEmail,
			status: editStatus,
			role: editRole,
			leader: editLeader,
		}));
		setIsDialogOpen(false);
	}

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{username}</h6>
				<span>{email}</span>
			</td>
			<td>
				<UserRoles role={role} />
			</td>
			<td>{leader ? leader : '-'}</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>{status}</td>
			<td>
				{authRole && Number(authRole) >= 16 ?
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Edit"
							onClick={handleClickOpen}
						>
							<i className="fa fa-edit" />
						</button>
						<Dialog open={isDialogOpen} onClose={handleClose}>
							<div className="modal-content">
								<form onSubmit={submitEditUsers}>
									<div className="modal-header">
										<h5 className="modal-title">Edit {username}</h5>
										<button type="button" className="close" onClick={handleClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Username</label>
													<input type="text" className="form-control" disabled placeholder="username" defaultValue={username} />
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Email address</label>
													<input type="email" className="form-control" placeholder="Email" value={editEmail} onChange={(e) => setEditEmail(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Role</label>
													<select className="form-control custom-select" value={editRole} onChange={(e) => setEditRole(e.target.value)}>
														{authRole && Number(authRole) >= 1 && <option value="1">{userRolesString("1")}</option>}
														{authRole && Number(authRole) >= 2 && <option value="2">{userRolesString("2")}</option>}
														{authRole && Number(authRole) >= 4 && <option value="4">{userRolesString("4")}</option>}
														{authRole && Number(authRole) >= 8 && <option value="8">{userRolesString("8")}</option>}
														{authRole && Number(authRole) >= 16 && <option value="16">{userRolesString("16")}</option>}
														{authRole && Number(authRole) >= 32 && <option value="32">{userRolesString("32")}</option>}
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label>Limited by Leader</label>
													<div className="input-group">
														<select
															className="form-control custom-select"
															value={editLeader}
															onChange={(e) => setEditLeader(e.target.value)}>
															<option value="">Not Limited by Leaders</option>
															{allLeaders && allLeaders.length > 0 ?
																allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
																: <></>
															}
														</select>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Status</label>
													<select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
														<option value="ACTIVE">ACTIVE</option>
														<option value="SUSPENDED">SUSPENDED</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
										<button type="submit" className="btn btn-primary">Update Admin</button>
									</div>
								</form>

							</div>
						</Dialog>
					</> : ''}
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					data-toggle="modal"
					data-target={`#usermodal-view-${id}`}
				>
					<i className="fa fa-eye" />
				</button>
				<div className="modal fade" id={`usermodal-view-${id}`} tabIndex="-1" role="dialog" aria-hidden="true">
					<div className="modal-dialog" >
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">View {username}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>
									<span className="text-bold">Username: </span> {username} <br />
									<span className="text-bold">Email: </span> {email} <br />
									<span className="text-bold">Role: </span> <UserRoles role={role} /> <br />
									<span className="text-bold">Limited by Leader: </span> {leader ? leader : '-'} <br />
									<span className="text-bold">Status: </span> {status} <br />
								</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
			</td>
		</tr >
	)
}

const Users = (props) => {
	const [search, setSearch] = useState('');

	const [addUsername, setAddUsername] = useState('');
	const [addEmail, setAddEmail] = useState('');
	const [addRole, setAddRole] = useState('1');
	const [addLeader, setAddLeader] = useState('');
	const [addPassword, setAddPassword] = useState('');
	const [addConfirmPassword, setAddConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const users = useSelector(({ admin }) => admin.users.report)
	const meta = useSelector(({ admin }) => admin.users.meta)
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		dispatch(searchAdminUsers({ search, ...params }))
	}, [params])

	const searchUsers = (e) => {
		e.preventDefault();
		dispatch(searchAdminUsers({ search, ...params }))
	}
	const clearFields = (e) => {
		e.preventDefault();
		setAddUsername('')
		setAddEmail('')
		setAddRole('1')
		setAddPassword('')
		setAddConfirmPassword('')
		setError('')
	}

	const validateAddUser = () => {
		if (!addUsername) {
			return ('No Username');
		} else if (!addEmail) {
			return ('No Email');
		} else if (!addRole) {
			return ('No Role');
		} else if (!addPassword) {
			return ('No password');
		} else if (!addConfirmPassword) {
			return ('No confirm password');
		} else if (addPassword !== addConfirmPassword) {
			return ('Password and confirm password do not match');
		} else if ((addPassword + '').length < 8) {
			return ('Password must be at least 8 characters');
		} else {
			return ('');
		}
	}

	const submitAddUser = (e) => {
		e.preventDefault();
		const hasError = validateAddUser();

		if (!hasError) {
			dispatch(addAdminUsers({
				username: addUsername,
				email: addEmail,
				password: addPassword,
				role: addRole,
				leader: addLeader,
			}))
			setError('')
			setAddUsername('')
			setAddEmail('')
			setAddRole('1')
			setAddLeader('')
			setAddPassword('')
			setAddConfirmPassword('')
		} else {
			setError(hasError)
		}
	}

	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })


	const renderError = () => {
		return (error ? <p className="text-danger">{error}</p> : <p>&nbsp;</p>)
	}


	return (
		<>
			<div>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
					<div className="container-fluid">
						<div className="d-flex justify-content-between align-items-center">
							<ul className="nav nav-tabs page-header-tab">
								<li className="nav-item">
									<a
										className="nav-link active"
										id="user-tab"
										data-toggle="tab"
										href="#user-list"
									>
										Admin Users
									</a>
								</li>
								{authUser && Number(authUser.role) >= 16 && <li className="nav-item">
									<a className="nav-link" id="user-tab" data-toggle="tab" href="#user-add">
										Add New
									</a>
								</li>}
							</ul>
							{/* <div className="header-action">
								<button type="button" className="btn btn-primary">
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</div> */}
						</div>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={searchUsers}>
											<p className="mb-2">Search Username</p>
											<div className="input-group mb-3">
												<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." required />
											</div>
											<button type="submit" className="btn btn-primary">
												<i className="fe fe-search mr-2" />
												Search
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body mt-3">
					<div className="container-fluid">
						<div className="tab-content mt-3">
							<div className="tab-pane fade show active" id="user-list" role="tabpanel">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">User List</h3>
										{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
												<thead>
													<tr>
														<th>ID</th>
														<th>Name</th>
														<th>Role</th>
														<th>Leader</th>
														<th>Created Date</th>
														<th>Status</th>
														<th className="w100">Action</th>
													</tr>
												</thead>
												<tbody>
													{users && Array.isArray(users) && users.length > 0 ? users.map(user => <UserRow key={user.id} {...user} authRole={authUser.role || 0} />) : <tr><td colSpan={6}><p className="text-center">No users found</p></td></tr>}
													{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

												</tbody>
											</table>
											<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />

										</div>

									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="user-add" role="tabpanel">
								<div className="card">
									<div className="card-body">
										<form onSubmit={submitAddUser}>
											<div className="row clearfix">
												<div className="col-12">
													<div className="form-group">
														<label className="form-label">Username</label>
														<input
															required
															type="text"
															className="form-control"
															placeholder="Username"
															value={addUsername}
															onChange={(e) => setAddUsername(e.target.value)}
														/>
													</div>
												</div>
												<div className="col-md-8 col-sm-12">
													<div className="form-group">
														<label className="form-label">Email</label>
														<input
															required
															type="email"
															className="form-control"
															placeholder="Email"
															value={addEmail}
															onChange={(e) => setAddEmail(e.target.value)}
														/>
													</div>
												</div>

												<div className="col-md-4 col-sm-12">
													<div className="form-group">
														<label className="form-label">Role</label>
														<select
															required
															className="form-control custom-select"
															value={addRole}
															onChange={(e) => setAddRole(e.target.value)}
														>
															{authUser && (authUser.role) >= 1 && <option value="1">{userRolesString("1")}</option>}
															{authUser && (authUser.role) >= 2 && <option value="2">{userRolesString("2")}</option>}
															{authUser && (authUser.role) >= 4 && <option value="4">{userRolesString("4")}</option>}
															{authUser && (authUser.role) >= 8 && <option value="8">{userRolesString("8")}</option>}
															{authUser && (authUser.role) >= 16 && <option value="16">{userRolesString("16")}</option>}
															{authUser && (authUser.role) >= 32 && <option value="32">{userRolesString("32")}</option>}
														</select>
													</div>
												</div>
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label className="form-label">Password</label>
														<input
															required
															type="password"
															className="form-control"
															placeholder="Password"
															value={addPassword}
															onChange={(e) => setAddPassword(e.target.value)}

														/>
													</div>
												</div>
												<div className="col-md-6 col-sm-12">
													<div className="form-group">
														<label className="form-label">Confirm Password</label>
														<input
															required
															type="password"
															className="form-control"
															placeholder="Confirm Password"
															value={addConfirmPassword}
															onChange={(e) => setAddConfirmPassword(e.target.value)}
														/>
													</div>
												</div>
												<div className="col-lg-3 col-md-4 col-sm-6">
													<div className="form-group">
														<label>Limited by Leader</label>
														<div className="input-group">
															<select
																className="form-control custom-select"
																value={addLeader}
																onChange={(e) => setAddLeader(e.target.value)}>
																<option value="">Not Limited by Leaders</option>
																{allLeaders && allLeaders.length > 0 ?
																	allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
																	: <></>
																}
															</select>
														</div>
													</div>
												</div>
												<div className="col-12">
													{renderError()}
												</div>
												<div className="col-12">
													<button type="submit" className="btn btn-primary mr-2">
														Add
													</button>
													<button
														type="button"
														className="btn btn-secondary"
														onClick={clearFields}
													>
														Clear
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</>
	);

}

export default Users;