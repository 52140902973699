import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  WITHDRAWAL_SET_DATA,
  WITHDRAWAL_SET_FILTER_TERMS,
  WITHDRAWAL_SET_SUMMARY,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';
import { formatDateTime } from '../util/date';
import { formatCurrency } from '../util/money';
import { exportToExcel } from '../util/exportExcel';

export const setWithdrawals = (payload) => {
  return {
    type: WITHDRAWAL_SET_DATA,
    payload,
  };
};

export const setWithdrawalsFilterTerms = (payload) => {
  return {
    type: WITHDRAWAL_SET_FILTER_TERMS,
    payload,
  };
};

export const setSummary = (payload) => {
  return {
    type: WITHDRAWAL_SET_SUMMARY,
    payload,
  };
};

export const getWithdrawals = ({
  username = "",
  email = "",
  fromNetwork = "",
  excludeNetwork = [],
  hash = "",
  address = "",
  approvalStartDate = null,
  approvalEndDate = null,
  completedStartDate = null,
  completedEndDate = null,
  startDate = null,
  endDate = null,
  status = '',
  page = 1,
  limit = 10,
  all = false,
} = {
    startDate: null,
    endDate: null,
    status: '',
    page: 1,
    limit: 10
  }) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      startDate,
      endDate,
      status,
      approvalStartDate,
      approvalEndDate,
      completedStartDate,
      completedEndDate,
      username,
      email,
      fromNetwork: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : fromNetwork,
      excludeNetwork,
      hash,
      address,
      all,
    }
    dispatch(fetchStart());
    dispatch(setWithdrawalsFilterTerms({ ...payload, page, limit }));
    axios.post(`/crackers/adm/users/getwithdrawal?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setWithdrawals({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const exportWithdrawals = () => {
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const payload = getStore().withdrawal.filterTerms
    axios.post(`/crackers/adm/users/getwithdrawal`, { ...payload, all: true }).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        exportToExcel({
          data: data.report,
          headers: [
            {
              key: 'id',
              label: "ID",
            },
            {
              key: 'username',
              label: "Username",
            },
            {
              key: 'email',
              label: "Email",
            },
            {
              key: 'currency',
              label: 'Currency',
            },
            {
              key: 'amount',
              label: 'Amount',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'fee',
              label: 'fee',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'amountReceivable',
              label: 'Amount to Send',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'walletAddress',
              label: 'Address',
            },
            {
              key: 'hash',
              label: 'Hash',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'status',
              label: 'Status',
            },
            {
              key: 'createdAt',
              label: 'Created Date',
              formatter: (value) => value ? formatDateTime(value) : '-',

            },
            {
              key: 'approvedTimestamp',
              label: 'Approved Date',
              formatter: (value) => value ? formatDateTime(value) : '-',

            },
            {
              key: 'sentTimestamp',
              label: 'Sent Date',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },
            {
              key: 'remarks',
              label: 'Remarks',
              formatter: (value) => value ? value : '-',
            },
          ],
          title: "Withdrawals"
        })
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};


export const withdrawalAction = ({
  withdrawalID,
  action,
}) => {
  const payload = {
    withdrawalID,
    action,
  }
  return (dispatch, getStore) => {
    const filterTerms = getStore().withdrawal.filterTerms;
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/withdrawal', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Withdrawal ${action === 'APPROVE' ? 'approved' : 'rejected'}`))
        dispatch(getWithdrawals(filterTerms))
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getWithdrawalSummary = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/withdrawalsummary').then(({ data }) => {
      if (data && data.result) {
        dispatch(fetchSuccess());
        dispatch(setSummary(data.result));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

