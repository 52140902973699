const content = [
  {
    "id": 1,
    "icon": "icon-rocket",
    "label": "Admin",
    "to": "#!",
    content: [
      {
        "id": "Dashboard",
        "label": "Dashboard",
        "to": "/app/home"
      },
      {
        "id": "Announcements",
        "label": "Announcements",
        "to": "/app/announcements"
      },
      {
        "id": "Downloads",
        "label": "Downloads",
        "to": "/app/downloads"
      },
      {
        "id": "Kyc",
        "label": "Kyc",
        "to": "/app/kycs"
      },
      {
        "id": "Users",
        "label": "Users",
        "to": "/app/users"
      },
      {
        "id": "Packages",
        "label": "Packages",
        "to": "/app/admin-package",
      },
      {
        "id": "User Tickets",
        "label": "User Tickets",
        "to": "/app/user-pin-package",
      },
      {
        "id": "System Settings",
        "label": "Ticket Settings",
        "to": "/app/user-system-setting",
      },
      {
        "id": "Campaign",
        "label": "Campaign",
        "to": "/app/campaign",
      },
      {
        "id": "Deposits",
        "label": "Deposits",
        "to": "/app/deposits"
      },
      {
        "id": "Wallets",
        "label": "Wallets",
        "to": "/app/wallets"
      },
      {
        "id": "Withdrawals",
        "label": "Withdrawals",
        "to": "/app/withdrawals"
      },
      {
        "id": "Payouts",
        "label": "Payouts",
        "to": "/app/payouts"
      },
      // {
      //   "id": 9,
      //   "label": "Stocks",
      //   "to": "/app/stocks"
      // },
      {
        "id": "Support",
        "label": "Support",
        "to": "/app/support"
      },
      {
        "id": "Network",
        "label": "Network",
        "to": "/app/network"
      },
      {
        "id": "Placement",
        "label": "Placement",
        "to": "/app/user-binary-placement",
      },
    ]
  },
  // {
  //   "id": 13,
  //   "icon": "icon-cup",
  //   "label": "Project",
  //   content: [
  //     {
  //       "id": 14,
  //       "label": "Dashboard",
  //       "to": "/app/project-dashboard"
  //     },
  //     {
  //       "id": 15,
  //       "label": "Project List",
  //       "to": "/app/project-list"
  //     },
  //     {
  //       "id": 16,
  //       "label": "Taskboard",
  //       "to": "/app/project-taskboard"
  //     },
  //     {
  //       "id": 17,
  //       "label": "Ticket List",
  //       "to": "/app/project-ticket"
  //     },
  //     {
  //       "id": 18,
  //       "label": "Ticket Details",
  //       "to": "/app/project-ticket-details"
  //     },
  //     {
  //       "id": 19,
  //       "label": "Clients",
  //       "to": "/app/project-clients"
  //     },
  //     {
  //       "id": 20,
  //       "label": "Todo List",
  //       "to": "/app/project-todo"
  //     }
  //   ]
  // },
  // {
  //   "id": 21,
  //   "icon": "icon-briefcase",
  //   "label": "Job Portal",
  //   content: [
  //     {
  //       "id": 22,
  //       "label": "Job Dashboard",
  //       "to": "/app/jobportal-dashboard"
  //     },
  //     {
  //       "id": 23,
  //       "label": "Positions",
  //       "to": "/app/jobportal-positions"
  //     },
  //     {
  //       "id": 24,
  //       "label": "Applicant",
  //       "to": "/app/jobportal-applicants"
  //     },
  //     {
  //       "id": 25,
  //       "label": "Resumes",
  //       "to": "/app/jobportal-resumes"
  //     },
  //     {
  //       "id": 26,
  //       "label": "Settings",
  //       "to": "/app/jobportal-settings"
  //     }
  //   ]
  // },
  // {
  //   "id": 27,
  //   "icon": "icon-lock",
  //   "label": "Authentication",
  //   content: [
  //     {
  //       "id": 28,
  //       "label": "Login",
  //       "to": "/login"
  //     },
  //     {
  //       "id": 29,
  //       "label": "Register",
  //       "to": "/signup"
  //     },
  //     {
  //       "id": 30,
  //       "label": "Forgot Password",
  //       "to": "/forgotpassword"
  //     },
  //     {
  //       "id": 31,
  //       "label": "404 error",
  //       "to": "/notfound"
  //     },
  //     {
  //       "id": 32,
  //       "label": "500 Error",
  //       "to": "/internalserver"
  //     }
  //   ]
  // },
  // {
  //   "id": 'UiElements',
  //   "label": "Ui Elements"
  // },
  // {
  //   "id": 33,
  //   "icon": "icon-tag",
  //   "label": "Icons",
  //   "to": "/app/icons",
  // },
  // {
  //   "id": 34,
  //   "icon": "icon-bar-chart",
  //   "label": "Charts",
  //   "to": "/app/charts",
  // },
  // {
  //   "id": 35,
  //   "icon": "icon-layers",
  //   "label": "Forms",
  //   "to": "/app/forms",
  // },
  // {
  //   "id": 36,
  //   "icon": "icon-tag",
  //   "label": "Tables",
  //   "to": "/app/tables",
  // },
  // {
  //   "id": 37,
  //   "icon": "icon-puzzle",
  //   "label": "Widgets",
  //   "to": "/app/widgets",
  // },
  // {
  //   "id": 38,
  //   "icon": "icon-map",
  //   "label": "Maps",
  //   "to": "/app/maps",
  // },
  // {
  //   "id": 39,
  //   "icon": "icon-picture",
  //   "label": "Gallery",
  //   "to": "/app/gallery",
  // },
];

export default content;