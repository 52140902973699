import { utils, writeFile } from 'xlsx-js-style'
import get from 'lodash/get'
import { formatDateTime } from './date';


export const exportToExcel = ({ data, headers, title = "download" }) => {
  // const headers = [
  //   {
  //     key: 'Requestor',
  //     label: t('Requestor'),
  //     formatter: (value) => {
  //       const name = value.fullNameKhmer || value.fullName
  //       return `${value.username} ${name ? `(${name})` : ''}`
  //     },
  //   },
  //   {
  //     key: 'count',
  //     label: t('No of Claims'),
  //     s: {
  //       alignment: {
  //         horizontal: 'right',
  //       },
  //     },
  //   },
  //   {
  //     key: 'totalAmount',
  //     label: t('Total Amount'),
  //     formatter: (value) => formatPrice(value),
  //     s: {
  //       alignment: {
  //         horizontal: 'right',
  //       },
  //     },
  //   },
  // ]
  if (!headers || headers?.length <= 0) {
    return
  }
  const headerLabels = headers.map((header) => header.label)

  const columnsWidthMap = headerLabels.reduce(
    (map, label) => {
      map[label] = Math.max(label.length, 10)
      return map
    },
    {}
  )

  const rows = []
  rows.push(
    headers.map((header) => ({
      v: header.label,
      t: 's',
      s: { ...header.s, font: { bold: true } },
    }))
  )
  rows.push(
    ...data.map((item) => {
      return headers.map((header) => {
        const headerKey = header.key
        const headerLabel = header.label
        let value = get(item, headerKey)
        if (header.formatter) {
          value = header.formatter(value)
        }
        value = value.toString()
        columnsWidthMap[headerLabel] = Math.max(
          columnsWidthMap[headerLabel],
          value.length
        )
        return {
          v: value,
          t: 's',
          s: header.s,
        }
      })
    })
  )

  const columnsWidthArr = headerLabels.map((key) => {
    return {
      wch: columnsWidthMap[key],
    }
  })

  const workbook = utils.book_new()
  const ws = utils.aoa_to_sheet(rows)
  ws['!cols'] = columnsWidthArr
  utils.book_append_sheet(workbook, ws)
  writeFile(
    workbook,
    `${title}-${formatDateTime(new Date())}.xlsx`,
    { compression: true }
  )
  // writeFileXLSX(workbook, `Claim Report ${new Date().toDateString()} - ${new Date().toDateString()}.xlsx`, { compression: true})
}
