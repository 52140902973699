import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import isBefore from 'date-fns/isBefore';

import { exportWithdrawals, getWithdrawals, getWithdrawalSummary, withdrawalAction } from '../../../actions/withdrawalAction';
import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';

const Status = ({ status }) => {
    let type;
    switch (status) {
        case "PENDING":
            type = <span className="tag tag-default">Pending</span>
            break;
        case "APPROVED":
            type = <span className="tag tag-info">Approved</span>
            break;
        case "REJECT":
            type = <span className="tag tag-danger">Rejected</span>
            break;
        case "FAILED":
            type = <span className="tag tag-danger">Failed</span>
            break;
        case "SUCCESS":
            type = <span className="tag tag-success text-muted">Success</span>
            break;
        default:
            type = <span className="tag tag-default">Pending</span>
            break;
    }
    return type;
}

const WithdrawalRow = ({
    id,
    username,
    email,
    currency,
    amount,
    fee,
    amountReceivable,
    walletAddress,
    hash,
    status,
    remarks,
    sentTimestamp,
    approvedTimestamp,
    createdAt,
    authRole,
}) => {
    const dispatch = useDispatch();
    const approveAction = () => {
        dispatch(withdrawalAction({
            withdrawalID: id,
            action: "APPROVE",
        }))
    }
    const rejectAction = () => {
        dispatch(withdrawalAction({
            withdrawalID: id,
            action: "REJECT",
        }))
    }
    // <th>Requested Date</th>
    // <th>Approval Date</th>
    // <th>Sent Date</th>
    return (
        <tr>
            <td>{id}</td>
            <td>{username}<br /><small>{email}</small></td>
            <td>{currency}</td>
            <td style={{ maxWidth: 150 }}>{walletAddress}</td>
            <td style={{ maxWidth: 150 }}>{hash ? hash : '-'}</td>
            <td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
            <td>{approvedTimestamp ? formatDateTime(approvedTimestamp) : '-'}</td>
            <td>{sentTimestamp ? formatDateTime(sentTimestamp) : '-'}</td>
            <td>{status ? <Status status={status} /> : '-'}</td>
            <td>${formatCurrency(amount || 0)}</td>
            <td>${formatCurrency(fee || 0)}</td>
            <td>${formatCurrency(amountReceivable || 0)}</td>
            <td>
                {authRole >= 8 ? status && status === 'PENDING' ? <>
                    <button type="button" className="btn btn-icon btn-sm btn-sm" title="Approve" onClick={approveAction}>
                        <i className="text-success fa fa-check" />
                    </button>
                    <button type="button" className="btn btn-icon btn-sm" title="Reject" data-toggle="tooltip" data-placement="top" onClick={rejectAction}>
                        <i className="text-danger fa fa-times" />
                    </button>
                </> : <div style={{ height: '31px' }}>{' '}</div> : ''}
            </td>
        </tr>
    )
}

const Withdrawals = (props) => {
    const [searchStatus, setSearchStatus] = useState("ALL")
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [hash, setHash] = useState("")
    const [address, setAddress] = useState("")
    const [approvalStart, setapprovalStart] = useState("")
    const [approvalEnd, setapprovalEnd] = useState("")
    const [completedStart, setcompletedStart] = useState("")
    const [completedEnd, setcompletedEnd] = useState("")
    const [fromNetwork, setfromNetwork] = useState("")
    const [excludeNetwork, setexcludeNetwork] = useState([])
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCryptoAmount, setTotalCryptoAmount] = useState(0);

    const dispatch = useDispatch();
    const withdrawals = useSelector(({ withdrawal }) => withdrawal.withdrawals.report)
    const meta = useSelector(({ withdrawal }) => withdrawal.withdrawals.meta)

    const summary = useSelector(({ withdrawal }) => withdrawal.summary)
    const { authUser } = useSelector(({ user }) => user);

    useEffect(() => {
        dispatch(getWithdrawalSummary())
        dispatch(getWithdrawals())
    }, [])

    useEffect(() => {
        handleDispatch()
    }, [params])

    useEffect(() => {
        calculateTotalAmount()
    }, [withdrawals])

    const calculateTotalAmount = () => {
        if (withdrawals) {
            const sum = withdrawals.reduce((a, b) => a + Number(b?.amount || 0), 0)
            setTotalAmount(sum)
            const sumCrypto = withdrawals.reduce((a, b) => a + Number(b?.amountReceivable || 0), 0)
            setTotalCryptoAmount(sumCrypto)
        }
    }

    const validateSearchWithdrawal = () => {
        if (!searchStatus) {
            return ('No status selected');
        } else if (isBefore(new Date(endDate), new Date(startDate))) {
            return ('Invalid End Date');
        } else {
            return ('');
        }
    }

    const handleResetFilters = () => {
        setSearchStatus("ALL")
        setStartDate("")
        setEndDate("")
        setUsername("")
        setEmail("")
        setHash("")
        setAddress("")
        setapprovalStart("")
        setapprovalEnd("")
        setcompletedStart("")
        setcompletedEnd("")
        setfromNetwork("")
        setexcludeNetwork([])
        setParams({ page: 1, limit: 10 })
        setError('')
    }

    const handleDispatch = () => {
        const hasError = validateSearchWithdrawal();

        if (!hasError) {
            dispatch(getWithdrawals({
                username,
                email,
                hash,
                address,
                startDate,
                endDate,
                approvalStartDate: approvalStart,
                approvalEndDate: approvalEnd,
                completedStartDate: completedStart,
                completedEndDate: completedEnd,
                fromNetwork,
                excludeNetwork,
                status: searchStatus === 'ALL' ? undefined : [searchStatus],
                ...params
            }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const submitSearchWithdrawal = (e) => {
        e.preventDefault();
        handleDispatch()
    }

    const handleAddExcludeNetwork = () => {
        setexcludeNetwork([...excludeNetwork, ""])
    }
    const handleSetExcludeNetwork = (data, id) => {
        setexcludeNetwork([
            ...excludeNetwork.slice(0, id),
            data,
            ...excludeNetwork.slice(id + 1)
        ])
    }
    const handleRemoveExcludeNetwork = (id) => {
        setexcludeNetwork([
            ...excludeNetwork.slice(0, id),
            ...excludeNetwork.slice(id + 1)
        ])
    }

    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    const handleExportData = () => {
        dispatch(exportWithdrawals())
    }

    return (

        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item"><a className="nav-link active" id="Accounts-tab" data-toggle="tab" href="#Accounts-Invoices">Withdrawals</a></li>
                            {/* <li className="nav-item"><a className="nav-link" id="Accounts-tab" data-toggle="tab" href="#Accounts-Payments">Payments</a></li>
                                <li className="nav-item"><a className="nav-link" id="Accounts-tab" data-toggle="tab" href="#Accounts-Expenses">Expenses</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div className="tab-pane fade show active" id="Accounts-Invoices" role="tabpanel">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Total Withdrawals</div>
                                            <div className="py-4 m-0 text-left h1 text-info counter">${formatCurrency(summary.total || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Pending Withdrawals</div>
                                            <div className="py-4 m-0 text-left h1 text-warning counter">${formatCurrency(summary.pending || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div>Successful Withdrawals</div>
                                            <div className="py-4 m-0 text-left h1 text-success counter">${formatCurrency(summary.success || 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-md-6">
                                        <div className="card">
                                            <div className="card-body">
                                                <div>Total Seving A/C</div>
                                                <div className="py-4 m-0 text-center h1 text-danger counter"><CountUp end={70} /></div>
                                                <div className="d-flex">
                                                    <small className="text-muted">This years</small>
                                                    <div className="ml-auto"><i className="fa fa-caret-down text-danger" />10%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <form className="row" onSubmit={submitSearchWithdrawal}>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Start Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>End Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Approval Start Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={approvalStart}
                                                    onChange={(e) => setapprovalStart(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Approval End Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={approvalEnd}
                                                    onChange={(e) => setapprovalEnd(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Completed Start Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={completedStart}
                                                    onChange={(e) => setcompletedStart(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Completed End Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={completedEnd}
                                                    onChange={(e) => setcompletedEnd(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12'></div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Username</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Email</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Status</label>
                                            <div className="multiselect_div">
                                                <select className="custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                    <option value="ALL">All</option>
                                                    <option value="PENDING">Pending</option>
                                                    <option value="APPROVED">Approved</option>
                                                    <option value="REJECT">Rejected</option>
                                                    <option value="FAILED">Failed</option>
                                                    <option value="SUCCESS">Success</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="mt-1">Hash</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={hash}
                                                    onChange={(e) => setHash(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="mt-1">Address</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">From Network</label>
                                            <div className="input-group">
                                                <input
                                                    disabled={authUser?.role == 2 && authUser.limitLeader}
                                                    type="text"
                                                    className="form-control"
                                                    value={fromNetwork}
                                                    onChange={(e) => setfromNetwork(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label className="mt-1">Exclude Network</label>
                                            {excludeNetwork.map((data, i) =>
                                                <div className="input-group mb-1" key={i}>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        value={data}
                                                        onChange={(e) => handleSetExcludeNetwork(e.target.value, i)}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon"
                                                        title="Delete"
                                                        onClick={() => handleRemoveExcludeNetwork(i)}
                                                    >
                                                        <i className="text-danger fa fa-trash" />
                                                    </button>
                                                </div>
                                            )}
                                            <br />
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                title="Add"
                                                onClick={() => handleAddExcludeNetwork()}
                                            >
                                                <i className="text-success fa fa-plus" /> Add
                                            </button>
                                        </div>
                                        <div className='col-12'></div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <label>&nbsp;</label>
                                            <button type="submit" className="btn btn-primary btn-block">
                                                Filter
                                            </button>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <label>&nbsp;</label>
                                            <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                Reset
                                            </button>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6">
                                            <label>&nbsp;</label>
                                            <button type="button" className="btn btn-outline-secondary btn-block" onClick={handleExportData}>
                                                Export Excel
                                            </button>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">{renderError()}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Withdrawals</h3>
                                    {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username</th>
                                                    <th>Currency</th>
                                                    <th>Type</th>
                                                    <th className="w150">Hash</th>
                                                    <th>Requested Date</th>
                                                    <th>Approval Date</th>
                                                    <th>Sent Date</th>
                                                    <th>Status</th>
                                                    <th className="w100">Amount</th>
                                                    <th className="w100">Fees</th>
                                                    <th className="w100">Sent Amount</th>
                                                    <th className="w150">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {withdrawals && withdrawals.length > 0 ? withdrawals.map((w, i) => <WithdrawalRow key={i} {...w} authRole={authUser.role} />)
                                                    :
                                                    <tr><td colSpan={7}><p className="text-center">No withdrawals found</p></td></tr>
                                                }
                                                <tr>
                                                    <td colSpan={9}></td>
                                                    <td><small>Total Amount</small><br />{totalAmount ? formatCurrency(totalAmount) : '-'}</td>
                                                    <td></td>
                                                    <td><small>Total Sent</small><br />{totalCryptoAmount ? formatCurrency(totalCryptoAmount) : '-'}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                    </div>
                                    {/* <nav aria-label="Page navigation">
                                            <ul className="pagination mb-0 justify-content-end">
                                                <li className="page-item"><a className="page-link" href="/#">Previous</a></li>
                                                <li className="page-item active"><a className="page-link" href="/#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">3</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">Next</a></li>
                                            </ul>
                                        </nav> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    )

}

export default Withdrawals;