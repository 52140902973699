import React, { useState, useEffect, } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
import { formatCustomDate } from '../../util/date';

// const TIME_FORMAT = "HH:mm:ss a '( GMT +1 )'"
// const TIME_FORMAT = 'EEEE, d MMMM YYY - HH:mm:ss';
const TIME_FORMAT = 'd MMM YYY HH:mm:ss';
const SHORT_TIME_FORMAT = 'd-M-yyyy HH:mm:ss';
// const SHORT_TIME_FORMAT = 'HH:mm:ss'

const TimeBar = () => {
  const [curTime, setCurTime] = useState(new Date())
  useEffect(() => {
    const timerID = setInterval(() => {
      setCurTime(new Date())
    }, 1000)
    return function cleanup() {
      clearInterval(timerID)
    }
  })
  const mq = window.matchMedia('(max-width: 575px)').matches

  return (
    <div className='' style={{ padding: 4, borderRadius: 4 }}>
      <div className=''>
        {mq ? formatCustomDate(curTime, SHORT_TIME_FORMAT) : formatCustomDate(curTime, TIME_FORMAT)}<br />(UTC +0)
      </div>
    </div>
  )
}

export default TimeBar
