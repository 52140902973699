import {
	SIGNOUT_USER_SUCCESS,
	ADMIN_SET_USERS_SEARCH,
	ADMIN_SET_USERS_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	users: reportInitialState,
	searchTerm: '',
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case ADMIN_SET_USERS_SEARCH: {
			return {
				...state,
				users: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case ADMIN_SET_USERS_SEARCH_TERM: {
			return {
				...state,
				searchTerm: action.payload,
			};
		}

		default:
			return state;
	}
}
