import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const AuthRedirect = (props) => {
  const { token, authUser, initURL } = useSelector(({ user }) => user);

  if (window.location.pathname === '/') {
    if (token === null) {
      return (<Redirect to={'/login'} />);
    } else if (
      initURL === '' ||
      initURL === '/' ||
      initURL === '/login' ||
      initURL === '/register'
      //  || initURL === '/verifyemail' ||
      // initURL === '/forget' ||
      // initURL === '/resetpassword'
    ) {
      return (<Redirect to={'/app/home'} />);
    } else {
      return (<Redirect to={initURL} />);
    }
  } else {
    return <Redirect to={initURL} />
  }
};


export default AuthRedirect;
