import { BASE_URL } from "./api";

export const formatErrorsToString = (errors) => {
    return Object.values(errors).reduce((t, error) => t + '\n' + error, '')
}

export const getImageUrl = (imageUrl) =>
    `${BASE_URL.replace('/sushi', '/api')}${imageUrl}`

export const userRankingString = (ranking) => {
    let type
    switch (ranking) {
        case '0':
            type = 'No Rank'
            break
        case '1':
            type = 'VIP'
            break
        case '2':
            type = 'VVIP'
            break
        case '3':
            type = 'Achiever'
            break
        case '4':
            type = 'Director'
            break
        case '5':
            type = 'Group Director'
            break
        default:
            type = ranking
            break
    }
    return type
}
