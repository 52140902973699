// import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  DEPOSIT_SET_DATA,
  DEPOSIT_SET_SEARCH,
  DEPOSIT_SET_SUMMARY,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';
import { formatCurrency } from '../util/money';
import { formatDateTime } from '../util/date';
import { exportToExcel } from '../util/exportExcel';

export const setDeposits = (payload) => {
  return {
    type: DEPOSIT_SET_DATA,
    payload,
  };
};

export const setSummary = (payload) => {
  return {
    type: DEPOSIT_SET_SUMMARY,
    payload,
  };
};

export const setDepositSearch = (payload) => {
  return {
    type: DEPOSIT_SET_SEARCH,
    payload,
  };
};

export const getDeposits = ({
  username = "",
  email = "",
  fromNetwork = "",
  excludeNetwork = [],
  hash = "",
  address = "",
  approvalStartDate = null,
  approvalEndDate = null,
  completedStartDate = null,
  completedEndDate = null,
  startDate = null,
  endDate = null,
  status = '',
  page = 1,
  limit = 10,
}) => {

  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      startDate,
      endDate,
      status,
      username,
      email,
      hash,
      address,
      fromNetwork: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : fromNetwork,
      excludeNetwork,
      approvalStartDate,
      approvalEndDate,
      completedStartDate,
      completedEndDate,
    }
    dispatch(fetchStart());
    dispatch(setDepositSearch({ ...payload, page, limit }))
    axios.post(`/crackers/adm/users/getdeposit?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setDepositSearch(payload));
        dispatch(setDeposits({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const exportDeposits = () => {
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const payload = getStore().deposit.search
    axios.post(`/crackers/adm/users/getdeposit`, { ...payload, all: true }).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        exportToExcel({
          data: data.report,
          headers: [
            {
              key: 'id',
              label: "ID",
            },
            {
              key: 'username',
              label: "Username",
            },
            {
              key: 'email',
              label: "Email",
            },
            {
              key: 'type',
              label: 'Currency',
            },
            {
              key: 'amount',
              label: 'Requested Amount',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'cryptoAmount',
              label: 'Amount Received',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'adjustAmount',
              label: 'Adjusted Amount',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'address',
              label: 'Address',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'hash',
              label: 'Hash',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'status',
              label: 'Status',
            },
            {
              key: 'createdAt',
              label: 'Created Date',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },
            {
              key: 'approvedTimestamp',
              label: 'Approved Timestamp',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },
            {
              key: 'completedAt',
              label: 'Received Timestamp',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },
            {
              key: 'remarks',
              label: 'Remarks',
              formatter: (value) => value ? value : '-',
            },
          ],
          title: "Deposits"
        })
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};


export const getDepositSummary = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/depositsummary').then(({ data }) => {
      if (data && data.result) {
        dispatch(fetchSuccess());
        dispatch(setSummary(data.result));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getDepositSearch = ({ search = '', page = 1, limit = 10 }) => {
  const payload = { search }
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/users/depositsearch?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setDeposits({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const approveDeposit = ({
  id,
  hash,
  amount,
  remarks,
}) => {
  return (dispatch, getStore) => {
    const payload = {
      id,
      hash,
      amount,
      remarks,
    }
    const searchTerm = getStore().deposit.search
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/approvedeposit', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSummary(data.result));
        dispatch(showMessage('Approved Deposit'));
        dispatch(getDeposits(searchTerm))
        dispatch(getDepositSummary())
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
