import React from 'react';

const Status = ({ status }) => {
	let type;
	switch (status) {
		case "PENDING":
			type = <span className="tag tag-default">Pending</span>
			break;
		case "APPROVED":
		case "APPROVE":
			type = <span className="tag tag-success text-muted">Approved</span>
			break;
		case "ACTIVE":
			type = <span className="tag tag-info">Active</span>
			break;
		case "REJECT":
			type = <span className="tag tag-danger">Rejected</span>
			break;
		case "DISABLED":
			type = <span className="tag tag-danger">Disabled</span>
			break;
		case "FAILED":
			type = <span className="tag tag-danger">Failed</span>
			break;
		case "TERMINATED":
			type = <span className="tag tag-danger">Terminated</span>
			break;
		case "SUCCESS":
			type = <span className="tag tag-success text-muted">Success</span>
		case "LIVE":
			type = <span className="tag tag-success text-muted">Live</span>
			break;
		case "COMPLETED":
			type = <span className="tag tag-success text-muted">Completed</span>
			break;
		case "DONE":
			type = <span className="tag tag-success text-muted">Done</span>
			break;
		default:
			type = <span className="tag tag-default">Unknown {status}</span>
			break;
	}
	return type;
}


export default Status;