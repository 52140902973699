import { push } from 'connected-react-router';
import axios, { getFormData } from '../util/api';
import {
  SITE_GET_DETAILS,
  SITE_SET_USERS_SEARCH,
  SITE_SET_USERS_SEARCH_TERM,
  SITE_SET_USERS_FINANCES_SEARCH,
  SITE_SET_USERS_FINANCES_SEARCH_TERM,
  SITE_SET_USERS_FILTER_TERM,
  SITE_SET_PLACEMENT_SEARCH,
  SITE_SET_PLACEMENT_SEARCH_TERM,
  SITE_SET_LEADER_SEARCH,
  SITE_SET_LEADER_SEARCH_TERM,
  SITE_SET_ALL_LEADERS,
} from '../constants/actionTypes';


import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';
import { formatDateTime } from '../util/date';
import { exportToExcel } from '../util/exportExcel';
import { formatCurrency } from '../util/money';
import { userRankingString } from '../util/strings';


export const setSiteDetails = (payload) => {
  return {
    type: SITE_GET_DETAILS,
    payload,
  };
};

export const setSiteUsersSearch = (payload) => {
  return {
    type: SITE_SET_USERS_SEARCH,
    payload,
  };
};

export const setSiteUsersSearchTerm = (payload) => {
  return {
    type: SITE_SET_USERS_SEARCH_TERM,
    payload,
  };
};

export const setSiteUsersFilterTerms = (payload) => {
  return {
    type: SITE_SET_USERS_FILTER_TERM,
    payload,
  };
};

export const setSiteLeaderUsersSearch = (payload) => {
  return {
    type: SITE_SET_LEADER_SEARCH,
    payload,
  };
};

export const setSiteLeaderSearchTerm = (payload) => {
  return {
    type: SITE_SET_LEADER_SEARCH_TERM,
    payload,
  };
};

export const setPlacementSearch = (payload) => {
  return {
    type: SITE_SET_PLACEMENT_SEARCH,
    payload,
  };
};

export const setPlacementSearchTerm = (payload) => {
  return {
    type: SITE_SET_PLACEMENT_SEARCH_TERM,
    payload,
  };
};

export const setSiteAllLeaders = (payload) => {
  return {
    type: SITE_SET_ALL_LEADERS,
    payload,
  };
};

export const setSiteUsersFinancesSearch = (payload) => {
  return {
    type: SITE_SET_USERS_FINANCES_SEARCH,
    payload,
  };
};

export const setSiteUsersFinancesSearchTerm = (payload) => {
  return {
    type: SITE_SET_USERS_FINANCES_SEARCH_TERM,
    payload,
  };
};

export const getSiteDetails = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/site/details').then(({ data }) => {
      if (data && data.result) {
        dispatch(fetchSuccess());
        dispatch(setSiteDetails(data.result));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getSiteUsersSearch = ({
  search = "",
  email = "",
  startDate = null,
  endDate = null,
  leader = "",
  status = "",
  rank = "",
  fromNetwork = "",
  excludeNetwork = null,
  page = 1,
  limit = 10,
}) => {

  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      email,
      rank,
      status,
      fromNetwork: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : fromNetwork,
      excludeNetwork,
      startDate,
      endDate,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
    }
    dispatch(fetchStart());
    dispatch(setSiteUsersSearchTerm({
      search,
      startDate,
      endDate,
      leader,
      rank,
      status,
      email,
      fromNetwork,
      excludeNetwork,
      page,
      limit
    }));
    dispatch(setSiteUsersFilterTerms({}));
    axios.post(`/crackers/adm/site/users?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSiteUsersSearch({ report: data.report, meta: data.meta }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const exportUsers = () => {
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const payload = getStore().site.searchTerm
    axios.post(`/crackers/adm/site/users`, { ...payload, all: true }).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        exportToExcel({
          data: data.report,
          headers: [
            {
              key: 'id',
              label: "ID",
            },
            {
              key: 'firstname',
              label: "First Name",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'username',
              label: "Username",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'email',
              label: "Email",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'mobile',
              label: "Mobile",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'uid',
              label: "UID",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'referrerUsername',
              label: "Sponsor",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'packageID',
              label: "Largest Package ID",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'packageValue',
              label: "Largest Package Value",
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'leader',
              label: "Leader",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'ranking',
              label: 'Rank',
              formatter: (value) => value ? userRankingString(value) : '-',
            },
            {
              key: 'earnings',
              label: 'Earnings',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'sales',
              label: 'Sales',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'emailVerified',
              label: 'Email Verified',
              formatter: (value) => value ? "Yes" : 'No',
            },
            {
              key: 'canViewSpecial',
              label: 'View Special Package',
              formatter: (value) => value ? "Yes" : 'No',
            },
            {
              key: 'withdrawalBlocked',
              label: 'Is Withdrawal Blocked',
              formatter: (value) => value ? "Yes" : 'No',
            },
            {
              key: 'notPlaced',
              label: 'Referrals Not Placed',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'status',
              label: 'Status',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'createdAt',
              label: 'Created Date',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },
          ],
          title: "Users"
        })
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getSiteUsersFinancesSearch = ({
  search = "",
  email = "",
  startDate = null,
  endDate = null,
  leader = "",
  status = "",
  rank = "",
  page = 1,
  limit = 10,
}) => {

  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      email,
      rank,
      status,
      startDate,
      endDate,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
    }
    dispatch(fetchStart());
    dispatch(setSiteUsersFinancesSearchTerm({
      search,
      startDate,
      endDate,
      leader,
      rank,
      status,
      email,
      page,
      limit
    }));
    axios.post(`/crackers/adm/site/usersfinance?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSiteUsersFinancesSearch({ report: data.report, meta: data.meta }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const exportUsersFinances = () => {
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const payload = getStore().site.searchFinancesTerm
    axios.post(`/crackers/adm/site/usersfinance`, { ...payload, all: true }).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        exportToExcel({
          data: data.report,
          headers: [
            {
              key: 'id',
              label: "ID",
            },
            {
              key: 'username',
              label: "Username",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'email',
              label: "Email",
              formatter: (value) => value ? value : '-',
            },
            
            {
              key: 'leader',
              label: "Leader",
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'ranking',
              label: 'Rank',
              formatter: (value) => value ? userRankingString(value) : '-',
            },
            {
              key: 'deposits',
              label: "Successful Deposits",
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'withdrawals',
              label: "Successful Withdrawals",
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'totalTickets',
              label: "Total Tickets",
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'totalActiveTickets',
              label: "Total Active Tickets",
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
          ],
          title: "User_Finances"
        })
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getSiteLeaderSearch = ({ search = "", page = 1, limit = 10 }) => {
  const payload = {
    search,
  }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setSiteLeaderSearchTerm({ search, page, limit }));
    axios.post(`/crackers/adm/users/getleader?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSiteLeaderUsersSearch({ report: data.report, meta: data.meta }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const updateEditUsers = ({
  id,
  username,
  email,
  emailVerified,
  status,
  ranking,
  role,
}) => {
  const payload = {
    id,
    username,
    email,
    emailVerified,
    status,
    ranking,
    role,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/site/edituser', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('User updated'));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const updateEditUsersEmailOnly = ({
  id,
  email,
}) => {
  const payload = {
    id,
    email,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/site/edituseremailonly', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('User updated'));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const resendVerificationEmail = ({
  id,
}) => {
  const payload = {
    id,
  }
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/site/resendverification', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Email Sent'));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

const MEMBER_SITE =
  process.env.REACT_APP_API_URL === "https://sashimi-platter.com/sushi/"
    ? "https://sashimi-platter.com"
    : process.env.REACT_APP_API_URL === "https://sashimi.icaruslabs.tech/sushi/"
      ? "https://login.icaruslabs.tech"
      : "http://localhost:3000"

export const adminLoginAsUser = ({
  userID,
}) => {
  const payload = {
    userID,
  }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(fetchSuccess());
    axios.post('/crackers/adm/users/loginas', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Logging in'));
        window.open(`${MEMBER_SITE}/la?u=${JSON.stringify(data.user)}&t=${data.token}`, '_blank')
      }
    }).catch(function (error) {
      console.log('error', error)
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const toggleUserWithdrawalBlock = ({
  id, withdrawalBlocked
}) => {
  const status = withdrawalBlocked ? "ALLOWED" : "BLOCKED"
  const payload = {
    userID: id, status
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/users/withdrawalblock', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Withdrawal updated: ${status}`));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const autoPlaceForUserNoMatch = ({
  id,
}) => {
  const payload = {
    id,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/users/autoplaceforuser', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Auto Place For user started`));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createdBatchUsers = (data) => {
  const formattedData = getFormData(data)

  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/users/excelcreateusersbatch`, formattedData).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Success: ' + data.message));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const searchUserPlacements = ({ search = "", sponsor = "", page = 1, limit = 10 }) => {
  const payload = { search, sponsor }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setPlacementSearchTerm(payload));
    axios.post(`/crackers/adm/users/getplacement?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setPlacementSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createdBatchAdjustWallet = (data) => {
  const formattedData = getFormData(data)

  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/users/exceladjustwalletbatch`, formattedData).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Success: ' + data.message));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createLeader = ({ leader }) => {
  const payload = { leader }

  return (dispatch, getStore) => {
    const searchTerm = getStore().site.searchLeadersTerm;

    dispatch(fetchStart());
    axios.post(`/crackers/adm/users/createleader`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Success: added ${leader} as Leader`));
        dispatch(getSiteLeaderSearch(searchTerm))
        dispatch(getAllLeaders())
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getAllLeaders = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/users/getallleaders`).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSiteAllLeaders(data.report));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const toggleUserViewSpecialPackage = ({
  id,
  status,
}) => {
  const payload = {
    id,
    status,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/users/toggleviewspecialpackage', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Updated View Special ${status}`));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const toggleBlockUserSettings = ({
  id,
  type,
  status,
}) => {
  const payload = {
    id,
    type,
    status,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().site.searchTerm;
    axios.post('/crackers/adm/users/toggleuserblock', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Updated Block Status: ${status}`));
        dispatch(getSiteUsersSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
