export const formatMoney = (amount) => {
  return Number.parseFloat(amount).toFixed(2);
}

export const formatBTC = (btcAmount) => {
  return Number.parseFloat(btcAmount).toFixed(6);
}

export const formatCurrency = (number, fixLength = 2) => {
  if (number) {
    return (Number(number)).toFixed(fixLength).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  const zero = 0
  return zero.toFixed(fixLength);
}

export const formatHide = (text, fixLength = 2) => {
  const test = /[^,.;]/g
  if (text) {
    const strText = text + "";
    return (strText.replace(test, '*'))
  }
  const zero = 0;
  const strZero = zero.toFixed(fixLength)
  return (strZero.replace(test, '*'));
}