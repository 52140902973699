import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import isBefore from 'date-fns/isBefore'

import { getAdminAdjust } from '../../../actions/walletAction';
import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import { WalletType } from './Wallets';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';

const Action = ({ action }) => {
    let type;
    switch (action) {
        case "minus":
            type = <span className="tag tag-danger">Minus</span>
            break;
        case "add":
            type = <span className="tag tag-success text-muted">Add</span>
            break;
        default:
            type = <span className="tag tag-default">Add</span>
            break;
    }
    return type;
}

const ItemRow = ({
    id,
    username,
    email,
    leader,
    walletType,
    amount,
    action,
    status,
    remarks,
    createdAt,
    // authRole,
}) => {
    // const dispatch = useDispatch();

    return (
        <tr>
            <td>{id}</td>
            <td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
            <td>{username}<br /><small>{email}</small></td>
            <td>{leader}</td>
            <td>{walletType ? <WalletType walletType={walletType} /> : '-'}</td>
            <td >{action ? <Action action={action} /> : '-'}</td>
            <td>${formatCurrency(amount || 0)}</td>
            <td style={{ maxWidth: 150 }}>{remarks ? remarks : '-'}</td>
        </tr>
    )
}

const AdjustWallets = (props) => {
    const [searchStatus, setSearchStatus] = useState("ALL")
    const [search, setSearch] = useState("")
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchLeader, setSearchLeader] = useState('');
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })

    const dispatch = useDispatch();
    const dataLists = useSelector(({ wallet }) => wallet.adjustWallets.report)
    const meta = useSelector(({ wallet }) => wallet.adjustWallets.meta)

    const { authUser } = useSelector(({ user }) => user);
    const allLeaders = useSelector(({ site }) => site.allLeaders)

    useEffect(() => {
        dispatch(getAllLeaders())
    }, [])

    const handleDispatch = () => {
        const hasError = validateFilter();

        if (!hasError) {
            handleSearchAdminAdjust()
            setError('')

        } else {
            setError(hasError)
        }
    }
    const handleResetFilters = () => {
        setSearch("")
        setSearchStatus("ALL")
        setSearchLeader("")
        setSearchEmail("")
        setStartDate("")
        setEndDate("")
        setParams({ page: 1, limit: 10 })
    }
    const handleSearchAdminAdjust = () => {
        dispatch(getAdminAdjust({
            search,
            type: searchStatus === 'ALL' ? undefined : [searchStatus],
            leader: searchLeader,
            email: searchEmail,
            startDate,
            endDate,
            ...params
        }))
    }
    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    useEffect(() => {
        handleSearchAdminAdjust()
    }, [params])

    const validateFilter = () => {
        if (!searchStatus) {
            return ('No status selected');
        } else if (isBefore(new Date(endDate), new Date(startDate))) {
            return ('Invalid End Date');
        } else {
            return ('');
        }
    }

    const submitFilterDeposit = (e) => {
        e.preventDefault();
        handleDispatch()
    }

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div>
                            {/* <div className="card" style={{ paddingLeft: 20 }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        <li className="nav-item"><a className={`nav-link ${adjustTab === 0 ? "active" : ""}`} onClick={(e) => handleSetAdjustTab(e, 0)}>Search Date</a></li>
                                        <li className="nav-item"><a className={`nav-link ${adjustTab === 1 ? "active" : ""}`} onClick={(e) => handleSetAdjustTab(e, 1)}>Search Username</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row" onSubmit={submitFilterDeposit}>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Search Username</label>
                                                <div className="input-group">
                                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search username..." />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Search Email</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        value={searchEmail}
                                                        onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
                                                        placeholder="Search email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Leader</label>
                                                    <div className="input-group">
                                                        <select
                                                            disabled={authUser?.role == 2 && authUser.limitLeader}
                                                            className="form-control custom-select"
                                                            value={searchLeader}
                                                            onChange={(e) => setSearchLeader(e.target.value)}>
                                                            <option value="">All Leaders</option>
                                                            {allLeaders && allLeaders.length > 0 ?
                                                                allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                                : <></>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">&nbsp;</div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={endDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Action</label>
                                                <div className="multiselect_div">
                                                    <select className="custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                        <option value="ALL">All</option>
                                                        <option value="add">Add</option>
                                                        <option value="minus">Minus</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">&nbsp;</div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Search
                                                </button>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                    Reset
                                                </button>
                                            </div>
                                        </form>
                                        <div className="row">
                                            <div className="col-12">{renderError()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Adjustments</h3>
                                    {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Date</th>
                                                    <th>Username</th>
                                                    <th>Leader</th>
                                                    <th>Wallet Type</th>
                                                    <th>Action</th>
                                                    <th>Amount</th>
                                                    <th className="w150">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataLists && dataLists.length > 0 ? dataLists.map((w, i) => <ItemRow key={w.id} {...w} authRole={authUser.role} />)
                                                    :
                                                    <tr><td colSpan={7}><p className="text-center">No adjustments found</p></td></tr>
                                                }
                                            </tbody>
                                        </table>
                                        <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdjustWallets;