import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import isBefore from 'date-fns/isBefore'

import { getDeposits, getDepositSummary, getDepositSearch } from '../../../actions/depositAction';
import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import Wallets from './Wallets';
import AdjustWallets from './AdjustWallets';
import BatchAdjustWallets from './BatchAdjustWallets';

const Wallet = (props) => {
    const [tab, setTab] = useState(0);
    const { authUser } = useSelector(({ user }) => user);

    const handleSetTab = (e, index) => {
        e.preventDefault();
        setTab(index)
    }

    return (
        <>
            <div>
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Wallets</a></li>
                                {authUser?.role >= 8 && <li className="nav-item"><a className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 1)}>Batch Adjust Wallet Balance</a></li>}
                                {authUser?.role >= 4 && <li className="nav-item"><a className={`nav-link ${tab === 2 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 2)}>Adjustment History</a></li>}
                            </ul>
                        </div>
                    </div>
                </div>
                {tab === 0 && <Wallets />}
                {tab === 1 && <BatchAdjustWallets />}
                {tab === 2 && <AdjustWallets />}
            </div>
        </>
    )

}

export default Wallet;