import axios from 'axios';
import store from '../store'
import { setUnauthorized } from '../actions/userAction';

const API_URL = process.env.REACT_APP_API_URL
export const BASE_URL = API_URL || 'http://localhost:3010/';

const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

const token = JSON.parse(localStorage.getItem('admin_token'));
if (token) {
  client.defaults.headers.common['Authorization'] = token;
}

client.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    //place your reentry code
    const { dispatch } = store;
    dispatch(setUnauthorized());
  }
  return Promise.reject(error);
});

export const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    if (Array.isArray(object[key])) {
      object[key].forEach((item) => {
        formData.append(key, item)
      })
    } else {
      formData.append(key, object[key])
    }
    return formData
  }, new FormData())


export default client;