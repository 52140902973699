// Import all components here
// import React, { Component } from 'react'
import Dashboard from './HRMS/Dashboard/Dashboard';
import AdminUsers from './HRMS/AdminUsers/AdminUsers';
import Users from './HRMS/Users';
import Login from './Authentication/login';
import Signup from './Authentication/signup';
// import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import InternalServer from './Authentication/500';
import Withdrawals from './HRMS/Withdrawals';
import SupportTicketList from './HRMS/TicketList';
import Deposits from './HRMS/Deposits';
import Wallets from './HRMS/Wallets';
import Kyc from './HRMS/Kyc';
import Network from './HRMS/Network';
import Packages from './HRMS/Packages/Packages';
import PinPackages from './HRMS/Packages/UserPinPackages';
// import Stocks from './HRMS/Stocks';
import Campaign from './HRMS/Campaign';
import Payouts from './HRMS/Payouts';
import UserPlacements from './HRMS/Placement';
import Announcements from './HRMS/Announcements';
import Downloads from './HRMS/Downloads';
import SystemSettings from './HRMS/SystemSettings';

const Routes = [
    {
        path: "/app/home",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Dashboard
    },
    {
        path: "/app/users",
        name: 'site-users',
        exact: true,
        pageTitle: "Users",
        component: Users
    },
    {
        path: "/app/admin-users",
        name: 'admin-users',
        exact: true,
        pageTitle: "Admin Users",
        component: AdminUsers
    },
    {
        path: "/app/admin-package",
        name: 'admin-package',
        exact: true,
        pageTitle: "Admin Package",
        component: Packages
    },
    {
        path: "/app/user-pin-package",
        name: 'user-pin-package',
        exact: true,
        pageTitle: "User Tickets",
        component: PinPackages
    },
    {
        path: "/app/user-system-setting",
        name: 'user-system-setting',
        exact: true,
        pageTitle: "System Settings",
        component: SystemSettings
    },
    {
        path: "/app/campaign",
        name: 'campaign',
        exact: true,
        pageTitle: "Campaign",
        component: Campaign
    },
    {
        path: "/app/withdrawals",
        name: 'withdrawals',
        exact: true,
        pageTitle: "Withdrawals",
        component: Withdrawals
    },
    {
        path: "/app/payouts",
        name: 'payouts',
        exact: true,
        pageTitle: "Payouts",
        component: Payouts
    },
    {
        path: "/app/support",
        name: 'support',
        exact: true,
        pageTitle: "SupportTicketList",
        component: SupportTicketList
    },
    {
        path: "/app/deposits",
        name: 'deposits',
        exact: true,
        pageTitle: "Deposits",
        component: Deposits
    },
    {
        path: "/app/wallets",
        name: 'wallets',
        exact: true,
        pageTitle: "Wallets",
        component: Wallets
    },
    {
        path: "/app/kycs",
        name: 'kycs',
        exact: true,
        pageTitle: "Kyc",
        component: Kyc
    },
    {
        path: "/app/network",
        name: 'network',
        exact: true,
        pageTitle: "Network",
        component: Network
    },
    {
        path: "/app/user-binary-placement",
        name: 'user-binary-placement',
        exact: true,
        pageTitle: "User Binary",
        component: UserPlacements
    },
    {
        path: "/app/announcements",
        name: 'announcements',
        exact: true,
        pageTitle: "Announcements",
        component: Announcements
    },
    {
        path: "/app/downloads",
        name: 'downloads',
        exact: true,
        pageTitle: "Downloads",
        component: Downloads
    },
    // {
    //     path: "/app/stocks",
    //     name: 'stocks',
    //     exact: true,
    //     pageTitle: "Stocks",
    //     component: Stocks
    // },


    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Tables",
        component: Login
    },
    {
        path: "/register",
        name: 'signup',
        exact: true,
        pageTitle: "Tables",
        component: Signup
    },
    // {
    //     path: "/forgotpassword",
    //     name: 'forgotpassword',
    //     exact: true,
    //     pageTitle: "Tables",
    //     component: ForgotPassword
    // },
    {
        path: "/notfound",
        name: 'notfound',
        exact: true,
        pageTitle: "Tables",
        component: NotFound
    },
    {
        path: "/internalserver",
        name: 'internalserver',
        exact: true,
        pageTitle: "Tables",
        component: InternalServer
    },
];

export default Routes;