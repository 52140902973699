import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { searchUserPlacements } from '../../../actions/siteAction';

const ItemRow = ({
	id,
	username,
	email,
	placement,
	parent,
	left,
	right,
	notPlacedCount,
	uid,
	createdAt,
	updatedAt,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{username}</h6>
				<span>{email}</span>
			</td>
			<td>{placement ? "yes" : 'no'}</td>
			<td>{parent ? parent : '-'}</td>
			<td>{left ? left : '-'}</td>
			<td>{right ? right : '-'}</td>
			<td>{notPlacedCount ? notPlacedCount : '-'}</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			{/* <td>
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isDialogOpen} onClose={handleClose}>

					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View Pin Package</h5>
							<button type="button" className="close" onClick={handleClose}>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<span className="text-bold">Username: </span>{username}<br />
								<span className="text-bold">Email: </span>{email}<br />

								<span className="text-bold">Purchase Date: </span>{createdAt ? formatDateTime(createdAt) : '-'}<br />
								<span className="text-bold">Last Update Date: </span>{updatedAt ? formatDateTime(updatedAt) : '-'}<br />

							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</Dialog>


			</td> */}
		</tr >
	)
}

const UserPlacements = (props) => {
	const [search, setSearch] = useState('');
	const [sponsor, setSponsor] = useState('');

	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ site }) => site.placements.report)
	const meta = useSelector(({ site }) => site.placements.meta)

	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { authUser } = useSelector(({ user }) => user);


	useEffect(() => {
		dispatch(searchUserPlacements({ search, sponsor, ...params }))
	}, [params])


	const searchUsers = (e) => {
		e.preventDefault();
		dispatch(searchUserPlacements({ search, sponsor, ...params }))
	}

	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	return (
		<div>
			<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center">
						<ul className="nav nav-tabs page-header-tab">
							<li className="nav-item">
								<a
									className="nav-link active"
									id="package-tab"
									data-toggle="tab"
									href="#user-pin-package-list"
								>
									User Placement (Binary)
								</a>
							</li>
						</ul>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<form onSubmit={searchUsers}>
										<div className='row'>
											<div className='col'>

												<p className="mb-2">Search User</p>
												<div className="input-group mb-3">
													<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." />
												</div>
											</div>
											<div className='col'>
												<p className="mb-2">Search Sponsor</p>
												<div className="input-group mb-3">
													<input type="text" value={sponsor} onChange={(e) => setSponsor(e.target.value)} className="form-control" placeholder="Search sponsor..." />
												</div>
											</div>
										</div>
										<button type="submit" className="btn btn-primary">
											<i className="fe fe-search mr-2" />
											Search
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div className="tab-pane fade show active" id="user-pin-package-list" role="tabpanel">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">User Placements</h3>
									{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
											<thead>
												<tr>
													<th>ID</th>
													<th>Username</th>
													<th>Has Placement</th>
													<th>Parent</th>
													<th>Left</th>
													<th>Right</th>
													<th>Referrals Not Placed</th>
													<th>Created Date</th>
													{/* <th className="w100">Action</th> */}
												</tr>
											</thead>
											<tbody>
												{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={6}><p className="text-center">No data found</p></td></tr>}
												{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

											</tbody>
										</table>
										<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default UserPlacements;