import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createdBatchUsers, getAllLeaders } from '../../../actions/siteAction';
import formExcel from '../../../assets/files/sampleUserBatch.xlsx';

const Users = (props) => {
	const [error, setError] = useState('');
	const [upload, setUpload] = useState(null);

	const dispatch = useDispatch();
	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	const validateFiles = () => {
		if (!upload) {
			return ('Upload required File');
		} else {
			return ('');
		}
	}

	const handleFileChange = (e) => {
		const files = e.target.files
		setUpload(files)
	}

	const submitAddBatchUsers = (e) => {
		e.preventDefault();
		const hasError = validateFiles();

		if (!hasError) {
			dispatch(createdBatchUsers({
				file: upload[0]
			}))
			setError('')

		} else {
			setError(hasError)
		}
	}

	const renderError = () => {
		return (error ? <p className="text-danger  mb-2 mt-2">{error}</p> : <p className="mb-2 mt-2">&nbsp;</p>)
	}

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<div className="card">
						<div className="card-body">
							<div className="row clearfix">
								<div className="col-12">
									<hr className="mt-4" />
									<h6>Add Batch Users with Excel Upload</h6>
									<form onSubmit={submitAddBatchUsers}>
										<div className="row clearfix">
											<div className="col-lg-4 col-md-6 col-12">
												<div className="card">
													<div className="card-header" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start' }}>
														<h3 className="card-title">
															Upload user data<small>Upload only xlsx files or csv files</small>
														</h3>
														<br />
														<ul>
															<li>{"Column A - Username"}</li>
															<li>{"Column B - Email"}</li>
															<li>{"Column C - Password"}</li>
															<li>{"Column D - Name"}</li>
															<li>{"Column E - Mobile"}</li>
															<li>{"Column F - Pin"}</li>
															<li>{"Column G - Referrer"} Referrer is the unique email of the referrer</li>
															<li></li>
															<li>
																First row is headers and will be ignored
															</li>
															<li>{"Max File Size- 2 MB"}</li>
														</ul>
													</div>
													<div className="card-body">
														<div className="mb-1">You may download the template form:</div>
														<div className="mb-1">
															<div>
																<a className='btn btn-primary' href={formExcel} download="sample.xlsx">
																	Download Upload Form in XLSX
																</a>
															</div>
														</div>
													</div>
													<div className="card-body">
														<input
															onChange={handleFileChange}
															required
															name="file"
															type="file"
															className="dropify"
															data-allowed-file-extensions="xlsx csv"
															data-max-file-size="2M"
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">{renderError()}</div>
										</div>
										<button type="submit" className="btn btn-primary">
											Add
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default Users;