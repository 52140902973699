import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { NotificationContainer, NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import 'react-notifications/lib/notifications.css';

import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import AuthRedirect from './components/Authentication/AuthRedirect';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';
import axios from './util/api';
import { hideMessage } from './actions/apiAction';
import { formatErrorsToString } from './util/strings';

const RestrictedRoute = ({ component: Component, token, ...rest }) =>
	<Route
		{...rest}
		render={props =>
			token
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>}
	/>;


const App = (props) => {
	const dispatch = useDispatch();
	const { error, loading, message } = useSelector(({ api }) => api);
	const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = useSelector(({ settings }) => settings);
	const { token, authUser } = useSelector(({ user }) => user);
	const { match, location } = props;

	//load localstorage bearer token on start and set axios default
	useEffect(() => {
		if (token) {
			axios.defaults.headers.common['Authorization'] = token;
		}
	}, [token])

	useEffect(() => {
		if (message) {
			NotificationManager.success(message);
			setTimeout(() => {
				dispatch(hideMessage());
			}, 1000);
		}

	}, [message]);
	useEffect(() => {
		if (error) {
			if (typeof error === 'object') {
				NotificationManager.error(formatErrorsToString(error?.errors || error));
			} else {
				NotificationManager.error(error.error || error + "");
			}
			setTimeout(() => {
				dispatch(hideMessage());
			}, 1000);
		}
	}, [error]);


	return (
		<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "sidebar_dark" : ""} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
			<Router>
				<Switch>
					<RestrictedRoute path={`${match.url}app`} token={token} component={Layout} />
					<Route path="/forgotpassword" component={ForgotPassword} />
					<Route path="/internalserver" component={InternalServer} />
					<Route path="/login" component={Login} />
					<Route path='/' component={AuthRedirect} />
					<Route component={NotFound} />
				</Switch>

			</Router>
			{loading && <div className="loader-view">
				<CircularProgress />
			</div>}
			<NotificationContainer />
		</div>
	);
}

export default App;
