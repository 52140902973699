import {
  PLACEMENT_SET_DISPLAY_ROOT,
  PLACEMENT_RESET_DISPLAY_ROOT,
  PLACEMENT_SET_PLACEMENT,
  PLACEMENT_SET_REFERRALS,
  PLACEMENT_UPDATE_NODES,
  PLACEMENT_SET_NEW_USER,
  PLACEMENT_SET_SEARCH_USER,
} from "../constants/actionTypes";

import axios from '../util/api';
import { fetchStart, fetchError, fetchSuccess, showMessage } from './apiAction';

export const setDisplayRoot = (node) => {
  return {
    type: PLACEMENT_SET_DISPLAY_ROOT,
    node
  }
};
export const setSearchUserData = (payload) => {
  return {
    type: PLACEMENT_SET_SEARCH_USER,
    payload
  }
};

export const resetDisplayRoot = () => {
  return {
    type: PLACEMENT_RESET_DISPLAY_ROOT,
  }
};

export const setPlacements = (root, nodes) => {
  return {
    type: PLACEMENT_SET_PLACEMENT,
    root,
    nodes
  }
};

export const updatePlacementsNodes = (nodes) => {
  return {
    type: PLACEMENT_UPDATE_NODES,
    nodes
  }
};

export const setReferrals = (payload) => {
  return {
    type: PLACEMENT_SET_REFERRALS,
    payload
  }
};

export const setSignUpNewUser = (payload) => {
  return {
    type: PLACEMENT_SET_NEW_USER,
    payload
  }
};

export const placementGetAuthUser = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/placement/get').then(({ data }) => {
      if (data.placements) {
        dispatch(fetchSuccess());
        dispatch(setPlacements(data.placements.root, data.placements.nodes));
      } else {
        dispatch(fetchError({ error: 'Unable to get placements' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};

export const placementGetMoreAuthUser = ({ uid }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/placement/getmore', { uid }).then(({ data }) => {
      if (data.placements) {
        dispatch(fetchSuccess());
        dispatch(updatePlacementsNodes(data.placements.nodes));
      } else {
        dispatch(fetchError({ error: 'Unable to get placements' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};

export const placementSetUser = ({ parentID, targetUserID, position }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/placement/set', { puid: parentID, uid: targetUserID, position }).then(({ data }) => {
      if (data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Successfully placed user'));
        dispatch(placementGetAuthUser());
        dispatch(placementReferralsGetAuthUser());
        // TODO: set displayroot as current display root
      } else {
        dispatch(fetchError({ error: 'Unable to set placement' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};

export const placementReferralsGetAuthUser = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/placement/referrals').then(({ data }) => {
      if (data.referrals) {
        dispatch(fetchSuccess());
        dispatch(setReferrals(data.referrals));
      } else {
        dispatch(fetchError({ error: 'Unable to get placements' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};

export const placementSearchUserPlacement = (username) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/placement/searchuid', { username }).then(({ data }) => {
      if (data.placements) {
        dispatch(fetchSuccess());
        dispatch(updatePlacementsNodes(data.placements.nodes));
        dispatch(setDisplayRoot(data.placements.requestedUser));
      } else {
        dispatch(fetchError({ error: 'Unable to get user' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};

export const placementSearchUser = ({ username = '', page = 1, limit = 10 }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/placement/searchuser?page=${page}&limit=${limit}`, { username }).then(({ data }) => {
      if (data.success) {
        dispatch(fetchSuccess());
        dispatch(setSearchUserData({
          report: data.report,
          meta: data.meta
        }));
      } else {
        dispatch(fetchError({ error: 'Unable to get user' }))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.response && error.response.data && error.response.data.errors ? error.response.data.errors : { error: 'Unable to get a response' }));
    });
  }
};
