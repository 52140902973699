// import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  SYSTEM_SETTING_SET_DATA,
  SYSTEM_SETTING_SET_SEARCH_TERM,
  SYSTEM_SETTING_TOKEN_SET_DATA,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setSystemSettingLists = (payload) => {
  return {
    type: SYSTEM_SETTING_SET_DATA,
    payload,
  };
};

export const setSystemSettingSearchTerm = (payload) => {
  return {
    type: SYSTEM_SETTING_SET_SEARCH_TERM,
    payload,
  };
};

export const setTokenPriceSystemSettingLists = (payload) => {
  return {
    type: SYSTEM_SETTING_TOKEN_SET_DATA,
    payload,
  };
};

export const getTicketSettingsSearch = ({ search = '', leader = "", status = '', page = 1, limit = 10 }) => {
  const payload = { search, leader, status }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setSystemSettingSearchTerm(payload));
    axios.post(`/crackers/adm/systemsetting/getticketsetting?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setSystemSettingLists({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createOrUpdateSettings = ({
  type = "",
  value = "",
  status = "",
  leader = "",
}) => {
  const payload = {
    type,
    value,
    status,
    leader,
  }
  return (dispatch, getStore) => {
    const searchTerms = getStore().systemsettings.ticketTerms
    dispatch(fetchStart());
    axios.post(`/crackers/adm/systemsetting/createticketsetting`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(getTicketSettingsSearch(searchTerms));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        if (error.response.data instanceof Object) {
          dispatch(fetchError(error.response.data?.errors));
        } else {
          dispatch(fetchError(error.response.data));
        }
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getTokenSettingsSearch = ({ search = '', leader = "", status = '', page = 1, limit = 10 }) => {
  const payload = { search, leader, status }
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/systemsetting/gettokensetting?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setTokenPriceSystemSettingLists({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getCampaignSettingsSearch = ({ search = '', leader = "", status = '', page = 1, limit = 10 }) => {
  const payload = { search, leader, status }
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/systemsetting/getcampaignsetting?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setTokenPriceSystemSettingLists({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createOrUpdateTokenSettings = ({
  type = "",
  value = "",
  status = "",
  leader = "",
  isCampaign = false
}) => {
  const payload = {
    type,
    value,
    status,
    leader,
  }
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/systemsetting/setSystemSetting`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        if (isCampaign) {
          dispatch(getCampaignSettingsSearch({}));
        } else {
          dispatch(getTokenSettingsSearch({}));
        }
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        if (error.response.data instanceof Object) {
          dispatch(fetchError(error.response.data?.errors));
        } else {
          dispatch(fetchError(error.response.data));
        }
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
