import {
	SIGNOUT_USER_SUCCESS,
	ANNOUNCEMENT_SET_DATA,
	ANNOUNCEMENT_SET_SEARCH_TERM,
	DOWNLOADS_SET_DATA,
	DOWNLOADS_SET_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	announcements: reportInitialState,
	search: '',
	adjustWallets: [],
	adjustWalletsSearch: [],
	downloads: reportInitialState,
	searchDownload: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case ANNOUNCEMENT_SET_DATA: {
			return {
				...state,
				announcements: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case ANNOUNCEMENT_SET_SEARCH_TERM: {
			return {
				...state,
				search: action.payload,
			};
		}
		case DOWNLOADS_SET_DATA: {
			return {
				...state,
				downloads: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case DOWNLOADS_SET_SEARCH_TERM: {
			return {
				...state,
				searchDownload: action.payload,
			};
		}

		default:
			return state;
	}
}
