import {
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_SET_DATA,
	USER_TOKEN_SET,
	USER_SET_UNAUTHORIZED,
} from "../constants/actionTypes";

const INIT_STATE = {
	token: JSON.parse(localStorage.getItem('admin_token')),
	initURL: '',
	authUser: JSON.parse(localStorage.getItem('admin_user')),
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {


		case INIT_URL: {
			return { ...state, initURL: action.payload };
		}

		case USER_SET_UNAUTHORIZED:
		case SIGNOUT_USER_SUCCESS: {
			localStorage.removeItem('admin_token');
			localStorage.removeItem('admin_user');
			return {
				...state,
				token: null,
				authUser: null,
				initURL: '',
			}
		}

		case USER_SET_DATA: {
			return {
				...state,
				authUser: action.payload,
			};
		}

		case USER_TOKEN_SET: {
			return {
				...state,
				token: action.payload,
			};
		}
		default:
			return state;
	}
}
