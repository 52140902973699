import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

export default function TablePaginationWithLogic({ count, changePage, changeRows }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        if (changePage) {
            changePage(newPage + 1)
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = parseInt(event.target.value, 10)
        if (changeRows) {
            changeRows(pageSize)
        }
        setRowsPerPage(pageSize);
        // setPage(0);
    };

    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 50, 100, 200, 500]}
        />
    );
}