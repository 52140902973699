import {
	SIGNOUT_USER_SUCCESS,
	STOCK_FCN_SET_DATA,
	STOCK_FCN_SET_SEARCH_TERMS,
} from "../constants/actionTypes";

const INIT_STATE = {
	searchTerms: {},
	fcns: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case STOCK_FCN_SET_DATA: {
			return {
				...state,
				fcns: action.payload,
			};
		}
		case STOCK_FCN_SET_SEARCH_TERMS: {
			return {
				...state,
				searchTerms: action.payload,
			};
		}
		default:
			return state;
	}
}
