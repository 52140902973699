import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { DialogContent } from '@material-ui/core';
import { getAllLeaders } from '../../../actions/siteAction';
import { createOrUpdateSettings, getTicketSettingsSearch } from '../../../actions/systemSettingsAction';
import { formatSystemSettingType } from '../../../constants/labels';
import { formatDateTime } from '../../../util/date';
import StatusTypes from '../../Shared/StatusTypes';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';

const ItemRow = ({
    id,
    type,
    value,
    description,
    leaderId,
    leaderUsername,
    status,
    createdAt,
    updatedAt,
    allLeaders,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const [editStatus, setEditStatus] = useState(status);
    const [editLeader, setEditLeader] = useState(leaderUsername);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createOrUpdateSettings({
            type,
            value: editValue,
            status: editStatus,
            leader: editLeader,
        }));
        setIsDialogOpen(false);
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{formatDateTime(createdAt)}</td>
            <td>{formatSystemSettingType(type)}</td>
            <td>{value}</td>
            <td>{leaderUsername}</td>
            <td>{status ? <StatusTypes status={status} /> : '-'}</td>
            <td>
                <button
                    type="button"
                    className="btn btn-icon"
                    title="Edit"
                    onClick={handleClickOpen}
                >
                    <i className="text-success fa fa-edit" />
                </button>
                <Dialog open={isDialogOpen} onClose={handleClose}>
                    <div className="modal-content">
                        <DialogContent>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit {formatSystemSettingType(type)}</h5>
                                    <button type="button" className="close" onClick={handleClose}>
                                        {/* <span aria-hidden="true">&times;</span> */}
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {leaderUsername !== null && <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Status</label>
                                                <select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
                                                    <option value="ACTIVE">Active</option>
                                                    <option value="DISABLED">Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Value</label>
                                                <input type="number" className="form-control" placeholder="Value" value={editValue} onChange={(e) => setEditValue(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        {leaderUsername !== null && <div className="col-lg-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Leader</label>
                                                <select
                                                    className="form-control custom-select"
                                                    value={editLeader}
                                                    onChange={(e) => setEditLeader(e.target.value)}>
                                                    <option value="">No Leader</option>
                                                    {allLeaders && allLeaders.length > 0 ?
                                                        allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                        : <></>
                                                    }
                                                </select>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </DialogContent>
                    </div>
                </Dialog>
            </td>
        </tr>
    )
}

const AddNewRow = ({ allLeaders }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editType, setEditType] = useState('BUY_TICKET_PERCENTAGE_WITHDRAWAL_WALLET');
    const [editValue, setEditValue] = useState('');
    const [editStatus, setEditStatus] = useState('ACTIVE');
    const [editLeader, setEditLeader] = useState('');

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createOrUpdateSettings({
            type: editType,
            value: editValue,
            status: editStatus,
            leader: editLeader,
        }));
        setIsDialogOpen(false);
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                title="Edit"
                onClick={handleClickOpen}
            >
                Add New
            </button>
            <Dialog open={isDialogOpen} onClose={handleClose}>
                <div className="modal-content">
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title">Add new Buy Ticket Percentage Setting</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    {/* <span aria-hidden="true">&times;</span> */}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Type</label>
                                            <select className="form-control custom-select" value={editType} onChange={(e) => setEditType(e.target.value)}>
                                                <option value="BUY_TICKET_PERCENTAGE_WITHDRAWAL_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_WITHDRAWAL_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_DEPOSIT_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_DEPOSIT_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_PROFIT_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_PROFIT_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_BONUS_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_BONUS_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_PURCHASE_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_PURCHASE_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_CREDIT_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_CREDIT_WALLET")}</option>
                                                <option value="BUY_TICKET_PERCENTAGE_CREDIT_NEW_WALLET">{formatSystemSettingType("BUY_TICKET_PERCENTAGE_CREDIT_NEW_WALLET")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Status</label>
                                            <select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
                                                <option value="ACTIVE">Active</option>
                                                <option value="DISABLED">Disable</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Value</label>
                                            <input type="number" className="form-control" placeholder="Value" value={editValue} onChange={(e) => setEditValue(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">Leader</label>
                                            <select
                                                required
                                                className="form-control custom-select"
                                                value={editLeader}
                                                onChange={(e) => setEditLeader(e.target.value)}>
                                                <option value="">No Leader</option>
                                                {allLeaders && allLeaders.length > 0 ?
                                                    allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                    : <></>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </form>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

const TicketSettings = (props) => {
    const [search, setSearch] = useState("")
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [searchLeader, setSearchLeader] = useState('');

    const dispatch = useDispatch();
    const dataList = useSelector(({ systemsettings }) => systemsettings.tickets.report)
    const meta = useSelector(({ systemsettings }) => systemsettings.tickets.meta)
    const { authUser } = useSelector(({ user }) => user);
    const allLeaders = useSelector(({ site }) => site.allLeaders)

    useEffect(() => {
        dispatch(getAllLeaders())
    }, [])

    useEffect(() => {
        dispatch(getTicketSettingsSearch({ search, leader: searchLeader, ...params }))
    }, [params])

    const validateSearch = () => {
        // if (!search) {
        //     return ('No search');
        // } else {
        //     return ('');
        // }
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const hasError = validateSearch();

        if (!hasError) {
            dispatch(getTicketSettingsSearch({ search, leader: searchLeader, ...params }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    const renderError = () => {
        return (error ? <p className="text-danger mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="tab-content mt-3">
                    <div>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <form className="row" onSubmit={submitSearch}>
                                        <div className="col-5">
                                            <div className="form-group">
                                                <label>Search</label>
                                                <div className="input-group">
                                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search..." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Leader</label>
                                                <div className="input-group">
                                                    <select
                                                        disabled={authUser?.role == 2 && authUser.limitLeader}
                                                        className="form-control custom-select"
                                                        value={searchLeader}
                                                        onChange={(e) => setSearchLeader(e.target.value)}>
                                                        <option value="">All Leaders</option>
                                                        {allLeaders && allLeaders.length > 0 ?
                                                            allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                            : <></>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6">
                                            <label>&nbsp;</label>
                                            <button type="submit" className="btn btn-primary btn-block">
                                                Search
                                            </button>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">{renderError()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <h3 className="card-title">Ticket Settings</h3>
                                {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                <div><AddNewRow allLeaders={allLeaders} /></div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Created Date</th>
                                                <th>Setting</th>
                                                <th>Value</th>
                                                <th>Leader</th>
                                                <th>Status</th>
                                                <th className="w150">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataList && dataList.length > 0 ? dataList.map((w, i) => <ItemRow key={w.id} {...w} allLeaders={allLeaders} authRole={authUser.role} />)
                                                :
                                                <tr><td colSpan={7}><p className="text-center">No Entry found</p></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )

}

export default TicketSettings;