import {
	SIGNOUT_USER_SUCCESS,
	PACKAGE_SET_SEARCH,
	PACKAGE_SET_SEARCH_TERM,
	PACKAGE_SET_SEARCH_USER_PIN_PACKAGE,
	PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	packages: reportInitialState,
	searchTerm: '',
	userpins: reportInitialState,
	searchUserTerm: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case PACKAGE_SET_SEARCH: {
			return {
				...state,
				packages: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case PACKAGE_SET_SEARCH_TERM: {
			return {
				...state,
				searchTerm: action.payload,
			};
		}
		case PACKAGE_SET_SEARCH_USER_PIN_PACKAGE: {
			return {
				...state,
				userpins: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM: {
			return {
				...state,
				searchUserTerm: action.payload,
			};
		}

		default:
			return state;
	}
}
