import {
	SIGNOUT_USER_SUCCESS,
	PLACEMENT_SET_DISPLAY_ROOT,
	PLACEMENT_RESET_DISPLAY_ROOT,
	PLACEMENT_SET_PLACEMENT,
	PLACEMENT_SET_REFERRALS,
	PLACEMENT_UPDATE_NODES,
	PLACEMENT_SET_NEW_USER,
	PLACEMENT_SET_SEARCH_USER,
} from '../constants/actionTypes'

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	searchUser: reportInitialState,
	root: {},
	nodes: {},
	displayRoot: {},
	placement: {},
	referrals: [],
	newUserPlacement: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PLACEMENT_SET_SEARCH_USER: {
			return {
				...state,
				searchUser: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			}
		}
		case PLACEMENT_SET_DISPLAY_ROOT: {
			return { ...state, displayRoot: action.node };
		}
		case PLACEMENT_RESET_DISPLAY_ROOT: {
			return { ...state, displayRoot: state.root };
		}
		case PLACEMENT_SET_PLACEMENT: {
			return {
				...state,
				placement: {
					root: action.root,
					nodes: action.nodes
				},
				root: action.root,
				nodes: action.nodes,
				displayRoot: action.root
			};
		}
		case PLACEMENT_UPDATE_NODES: {
			return {
				...state,
				nodes: Object.assign(state.nodes, action.nodes),
			};
		}
		case PLACEMENT_SET_REFERRALS: {
			return {
				...state,
				referrals: action.payload
			};
		}
		case PLACEMENT_SET_NEW_USER: {
			return {
				...state,
				newUserPlacement: action.payload
			};
		}
		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE;
		}
		default:
			return state;
	}
}
