
const getChild = (userNodeID, nodes, list) => {
  const userNode = nodes[userNodeID]
  if (!userNode || (userNode && !userNode.parentID)) {
    return
  } else {
    const data = {
      uid: userNode.uid,
      name: userNode.name,
    }
    list.unshift(data)
    getChild(userNode.parentID, nodes, list)
  }
}

export const getBreadCrumbs = ({ nodes, targetNodeID }) => {
  const list = []
  //from bottom to initial
  getChild(targetNodeID, nodes, list)
  return list
}
