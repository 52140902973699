import React, { Component } from 'react';

export default class Footer extends Component {
	render() {
		return (
			<>
				<div>
					<div className="section-body">
						<footer className="footer">
							<div className="container-fluid">
								<div className="row">
									
								</div>
							</div>
						</footer>
					</div>
				</div>
			</>
		);
	}
}
