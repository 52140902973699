import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { adjustWalletBalance, getWalletSearch } from '../../../actions/walletAction';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { WALLETS, WALLET_TYPES } from '../../../constants/labels';
import formExcel from '../../../assets/files/sampleAdjustWalletBatch.xlsx';
import { createDownloads } from '../../../actions/announcementAction';

const UploadDownload = (props) => {
    const [upload, setUpload] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const files = e.target.files
        setUpload(files)
    }
    const validateFiles = () => {
        if (!upload) {
            return ('Upload required File');
        } else {
            return ('');
        }
    }
    const submitCreateDownload = (e) => {
        e.preventDefault();
        const hasError = validateFiles();

        if (!hasError) {
            dispatch(createDownloads({
                title: title,
                description: description,
                type: "REPORT",
                startDate: startDate,
                endDate: endDate,
                pdf: upload[0]
            }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="row clearfix">

                            <div className="col-12">
                                <h6>Create Download</h6>
                                <hr className="mt-4" />
                                <form onSubmit={submitCreateDownload}>
                                    <div className="row clearfix">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Title</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <input
                                                    required
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)} className="form-control"
                                                        placeholder="Start Date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        value={endDate}
                                                        onChange={(e) => setEndDate(e.target.value)} className="form-control"
                                                        placeholder="End Date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>File</label>
                                                <div className="input-group">
                                                    <input
                                                        onChange={handleFileChange}
                                                        required
                                                        name="file"
                                                        type="file"
                                                        className="dropify"
                                                        data-allowed-file-extensions="xlsx csv"
                                                        data-max-file-size="2M"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">{renderError()}</div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )

}

export default UploadDownload;