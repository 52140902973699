import {
	SIGNOUT_USER_SUCCESS,
	SITE_GET_DETAILS,
	SITE_SET_USERS_SEARCH,
	SITE_SET_USERS_SEARCH_TERM,
	SITE_SET_USERS_FILTER_TERM,
	SITE_SET_PLACEMENT_SEARCH,
	SITE_SET_PLACEMENT_SEARCH_TERM,
	SITE_SET_LEADER_SEARCH,
	SITE_SET_LEADER_SEARCH_TERM,
	SITE_SET_ALL_LEADERS,
	SITE_SET_USERS_FINANCES_SEARCH,
	SITE_SET_USERS_FINANCES_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	details: {},
	users: reportInitialState,
	usersFinances: reportInitialState,
	searchFinancesTerm: {},
	searchTerm: {},
	filterTerm: {},
	placements: reportInitialState,
	searchPlacementsTerm: {},
	leaders: reportInitialState,
	searchLeadersTerm: {},
	allLeaders: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case SITE_GET_DETAILS: {
			return {
				...state,
				details: action.payload,
			};
		}
		case SITE_SET_USERS_SEARCH: {
			return {
				...state,
				users: action.payload,
			};
		}
		case SITE_SET_USERS_SEARCH_TERM: {
			return {
				...state,
				searchTerm: action.payload,
			};
		}
		case SITE_SET_USERS_FINANCES_SEARCH: {
			return {
				...state,
				usersFinances: action.payload,
			};
		}
		case SITE_SET_USERS_FINANCES_SEARCH_TERM: {
			return {
				...state,
				searchFinancesTerm: action.payload,
			};
		}
		case SITE_SET_USERS_FILTER_TERM: {
			return {
				...state,
				filterTerm: action.payload,
			};
		}
		case SITE_SET_PLACEMENT_SEARCH: {
			return {
				...state,
				placements: action.payload,
			};
		}
		case SITE_SET_PLACEMENT_SEARCH_TERM: {
			return {
				...state,
				searchPlacementsTerm: action.payload,
			};
		}
		case SITE_SET_LEADER_SEARCH: {
			return {
				...state,
				leaders: action.payload,
			};
		}
		case SITE_SET_LEADER_SEARCH_TERM: {
			return {
				...state,
				searchLeadersTerm: action.payload,
			};
		}
		case SITE_SET_ALL_LEADERS: {
			return {
				...state,
				allLeaders: action.payload,
			};
		}
		default:
			return state;
	}
}
