import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createCampaign, deleteCampaign, editCampaign, searchCampaigns } from '../../../actions/campaignAction';
import { formatCustomDate, formatDate, formatDateTime } from '../../../util/date';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';
import NewCampaign from './NewCampaign';
import Campaigns from './Campaigns';
import CampaignInvests from './CampaignInvests';
import CampaignSettings from './CampaignSettings';

const ItemRow = ({
	id,
	title,
	duration,
	maxVolume,
	percentage,
	launchDate,
	startDate,
	endDate,
	status,
	actualVolume,
	currentVolume,
	cutOffDate,
	displayEndDate,
	displayPercentageMax,
	displayPercentageMin,
	interimPaid,
	payoutPaid,
	leaderUsername,
	leaderID,
	refund,
	minValue,
	minTotalValue,
	createdAt,
	updatedAt,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const [editTitle, setEditTitle] = useState(title);
	const [editDuration, setEditDuration] = useState(duration);
	const [editCurrentVolume, setEditCurrentVolume] = useState(currentVolume);
	const [editMaxVolume, setEditMaxVolume] = useState(maxVolume);
	const [editPercentage, setEditPercentage] = useState(percentage);
	const [editPercentageMin, setEditPercentageMin] = useState(displayPercentageMin);
	const [editPercentageMax, setEditPercentageMax] = useState(displayPercentageMax);
	const [editLaunchDate, setEditLaunchDate] = useState(formatCustomDate(launchDate));
	const [editStatus, setEditStatus] = useState(status);
	const [editLeader, setEditLeader] = useState(leaderUsername);
	const [editMinValue, setEditMinValue] = useState(minValue);
	const [editMinTotalValue, setEditMinTotalValue] = useState(minTotalValue);

	const { authUser } = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};
	const handleViewClickOpen = () => {
		setIsViewDialogOpen(true);
	};
	const handleDeleteClickOpen = () => {
		setIsDeleteDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const handleViewClose = () => {
		setIsViewDialogOpen(false);
	};
	const handleDeleteClose = () => {
		setIsDeleteDialogOpen(false);
	};

	const submitEdit = (e) => {
		e.preventDefault();
		dispatch(editCampaign({
			id,
			title: editTitle || title,
			duration: editDuration || duration,
			currentVolume: editCurrentVolume || currentVolume,
			maxVolume: editMaxVolume || maxVolume,
			percentage: editPercentage || percentage,
			percentageMin: editPercentageMin || displayPercentageMin,
			percentageMax: editPercentageMax || displayPercentageMax,
			launchDate: editLaunchDate || launchDate,
			status: editStatus || status,
			leader: editLeader || leaderUsername,
			minValue: editMinValue || minValue,
			minTotalValue: editMinTotalValue || minTotalValue,
		}));
		setIsDialogOpen(false);
	}

	const submitDelete = (e) => {
		e.preventDefault();
		dispatch(deleteCampaign({ id, }));
		setIsDeleteDialogOpen(false);
	}

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{title}</h6>
			</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : "-"}</td>
			<td>
				{leaderUsername ?
					<>{leaderUsername}<br />
						<small>ID: {leaderID}</small></>
					: '-'
				}
			</td>
			<td>
				{duration}
			</td>
			<td>
				{minValue ? minValue : '-'}
			</td>
			<td>
				{minTotalValue ? minTotalValue : '-'}
			</td>
			<td>
				{actualVolume}
			</td>
			<td>
				{currentVolume}/{maxVolume}
			</td>
			<td>
				{percentage}% ({displayPercentageMin}%-{displayPercentageMax}%)
			</td>
			<td>{launchDate ? formatDateTime(launchDate) : '-'}</td>
			<td>{startDate ? formatDateTime(startDate) : '-'}</td>
			<td>{endDate ? formatDateTime(endDate) : '-'}</td>
			<td>
				{payoutPaid ? "Paid" : "Not Paid"}
			</td>
			<td>
				{authUser?.role > 2 &&
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Edit"
							onClick={handleClickOpen}
						>
							<i className="fa fa-edit" />
						</button>
						<Dialog open={isDialogOpen} onClose={handleClose}>
							<div className="modal-content">
								<form onSubmit={submitEdit}>
									<div className="modal-header">
										<h5 className="modal-title">Edit {title}</h5>
										<button type="button" className="close" onClick={handleClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Title</label>
													<input type="text" className="form-control" placeholder="title" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Duration</label>
													<input type="number" className="form-control" value={editDuration} onChange={(e) => setEditDuration(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Ticket Value<br /><small>Min value of each ticket</small></label>
													<input type="number" className="form-control" value={editMinValue} onChange={(e) => setEditMinValue(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Total Ticket Value<br /><small>Min total sum of the tickets to join campaign</small></label>
													<input type="number" className="form-control" value={editMinTotalValue} onChange={(e) => setEditMinTotalValue(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Leader<br /><small className='text-muted'>Enter Leader username - only leader's referral network can see this campaign<br />Leave blank for all users to see campaign </small></label>
													<div className="input-group">
														<select
															className="form-control custom-select"
															value={editLeader}
															onChange={(e) => setEditLeader(e.target.value)}>
															<option value="">-</option>
															{allLeaders && allLeaders.length > 0 ?
																allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
																: <></>
															}
														</select>
													</div>
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Current Volume</label>
													<input type="number" className="form-control" value={editCurrentVolume} onChange={(e) => setEditCurrentVolume(e.target.value)} />

												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Max Volume</label>
													<input type="number" className="form-control" value={editMaxVolume} onChange={(e) => setEditMaxVolume(e.target.value)} />

												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Actual Percentage</label>
													<input type="number" className="form-control" value={editPercentage} onChange={(e) => setEditPercentage(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Min Display Percentage</label>
													<input type="number" className="form-control" value={editPercentageMin} onChange={(e) => setEditPercentageMin(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Max Display Percentage</label>
													<input type="number" className="form-control" value={editPercentageMax} onChange={(e) => setEditPercentageMax(e.target.value)} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Status</label>
													<select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
														<option value="PENDING">Pending</option>
														<option value="ACTIVE">Active</option>
														<option value="PENDING_RUNNING">Pending Running</option>
														<option value="COMPLETED">Completed</option>
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Launch Date</label>
													<input type="date" className="form-control" value={editLaunchDate} onChange={(e) => setEditLaunchDate(e.target.value)} />
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
										<button type="submit" className="btn btn-primary">Update Campaign</button>
									</div>
								</form>
							</div>
						</Dialog>
					</>
				}
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleViewClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isViewDialogOpen} onClose={handleViewClose}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View {title}</h5>
							<button type="button" className="close" onClick={handleViewClose}>
							</button>
						</div>
						<div className="modal-body" style={{ minWidth: '350px' }}>
							<p>
								<span className="text-bold">Title: </span>{title}<br />
								<span className="text-bold">Duration: </span>{duration}<br />
								<span className="text-bold">Min Ticket Value: </span>{minValue}<br />
								<span className="text-bold">Min Total Value: </span>{minTotalValue}<br />
								<span className="text-bold">Current Volume: </span>{currentVolume}<br />
								<span className="text-bold">Max Volume: </span>{maxVolume}<br />
								<span className="text-bold">Actual Volume: </span>{actualVolume}<br />
								<span className="text-bold">Actual Percentage: </span>{percentage}<br />
								<span className="text-bold">Display Min Percentage: </span>{displayPercentageMin}<br />
								<span className="text-bold">Display Max Percentage: </span>{displayPercentageMax}<br />
								<span className="text-bold">Launch Date: </span>{launchDate ? formatDate(launchDate) : '-'}<br />
								<span className="text-bold">Start Date: </span>{startDate ? formatDate(startDate) : '-'}<br />
								<span className="text-bold">End Date: </span>{endDate ? formatDate(endDate) : '-'}<br />
								<span className="text-bold">Status: </span>{status ? <StatusBadge status={status} /> : '-'}<br />
							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" onClick={handleViewClose}>Close</button>
						</div>
					</div>
				</Dialog>
				{authUser?.role > 2 &&
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Delete"
							onClick={handleDeleteClickOpen}
						>
							<i className="text-danger fa fa-trash" />
						</button>
						<Dialog open={isDeleteDialogOpen} onClose={handleDeleteClose}>
							<div className="modal-content">
								<form onSubmit={submitDelete}>
									<div className="modal-header">
										<h5 className="modal-title">Delete {title}</h5>
										<button type="button" className="close" onClick={handleDeleteClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<p>Are You Sure?</p>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleDeleteClose}>Close</button>
										<button type="submit" className="btn btn-primary bg-danger">Delete</button>
									</div>
								</form>

							</div>
						</Dialog>
					</>
				}
			</td>
		</tr >
	)
}

const Campaign = (props) => {
	const [tab, setTab] = useState(0);
	const { authUser } = useSelector(({ user }) => user);

	const handleSetTab = (e, index) => {
		e.preventDefault();
		setTab(index)
	}





	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)



	return (
		<div>
			<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center">
						<ul className="nav nav-tabs page-header-tab">
							<li className="nav-item">
								<a
									className={`nav-link ${tab === 0 ? "active" : ""}`}
									onClick={(e) => handleSetTab(e, 0)}
								>
									Campaign
								</a>
							</li>
							{authUser && Number(authUser.role) >= 4 && <li className="nav-item">
								<a className={`nav-link ${tab === 1 ? "active" : ""}`}
									onClick={(e) => handleSetTab(e, 1)}>
									Add New
								</a>
							</li>}
							<li className="nav-item">
								<a
									className={`nav-link ${tab === 2 ? "active" : ""}`}
									onClick={(e) => handleSetTab(e, 2)}
								>
									Campaign Tickets
								</a>
							</li>
							<li className="nav-item">
								<a
									className={`nav-link ${tab === 3 ? "active" : ""}`}
									onClick={(e) => handleSetTab(e, 3)}
								>
									Setting
								</a>
							</li>
						</ul>
					</div>
					{tab === 0 && <Campaigns />}
					{tab === 1 && <NewCampaign />}
					{tab === 2 && <CampaignInvests />}
					{tab === 3 && <CampaignSettings />}
				</div>
			</div>

		</div>
	);

}

export default Campaign;