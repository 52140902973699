import React from 'react';

const StatusBadge = ({ status }) => {
    let type;
    switch (status) {
        case "PENDING":
            type = <span className="tag tag-default">Pending</span>
            break;
        case "APPROVED":
            type = <span className="tag tag-info">Approved</span>
            break;
        case "RUNNING":
            type = <span className="tag tag-info">Running</span>
            break;
        case "REJECT":
            type = <span className="tag tag-danger">Rejected</span>
            break;
        case "FAILED":
            type = <span className="tag tag-danger">Failed</span>
            break;
        case "SUCCESS":
            type = <span className="tag tag-success text-muted">Success</span>
            break;
        case "ACTIVE":
            type = <span className="tag tag-success text-muted">Active</span>
            break;
        case "SUSPENDED":
            type = <span className="tag tag-danger">Suspended</span>
            break;
        case "PENDING_RUNNING":
            type = <span className="tag tag-success text-muted">Pending Running</span>
            break;
        case "DONE":
            type = <span className="tag tag-success text-muted">Done</span>
            break;
        case "SYSTEM_REVERTED":
            type = <span className="tag tag-danger">System Reverted</span>
            break;
        case "COMPLETED":
            type = <span className="tag tag-default">Completed</span>
            break;
        default:
            type = <span className="tag tag-default">{status}</span>
            break;
    }
    return type;
}

export default StatusBadge