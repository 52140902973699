import {
	SIGNOUT_USER_SUCCESS,
	KYC_SET_DATA,
	KYC_SET_SEARCH_TERM,
	KYC_SET_ADJUST_DATA,
	KYC_SET_ADJUST_SEARCH_TERM,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	kyc: reportInitialState,
	search: '',
	adjustWallets: [],
	adjustWalletsSearch: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case KYC_SET_DATA: {
			return {
				...state,
				kyc: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case KYC_SET_SEARCH_TERM: {
			return {
				...state,
				search: action.payload,
			};
		}
		case KYC_SET_ADJUST_DATA: {
			return {
				...state,
				adjustWallets: action.payload,
			};
		}
		case KYC_SET_ADJUST_SEARCH_TERM: {
			return {
				...state,
				adjustWalletsSearch: action.payload,
			};
		}

		default:
			return state;
	}
}
