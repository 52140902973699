import {
	SIGNOUT_USER_SUCCESS,
	DEPOSIT_SET_DATA,
	DEPOSIT_SET_SUMMARY,
	DEPOSIT_SET_SEARCH,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	deposits: reportInitialState,
	search: {},
	summary: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case DEPOSIT_SET_DATA: {
			return {
				...state,
				deposits: {
					report: action.payload.report,
					meta: action.payload.meta,
				},
			};
		}
		case DEPOSIT_SET_SUMMARY: {
			return {
				...state,
				summary: action.payload,
			};
		}
		case DEPOSIT_SET_SEARCH: {
			return {
				...state,
				search: action.payload,
			};
		}

		default:
			return state;
	}
}
