import axios from '../util/api';
import {
  PAYOUT_SET_SEARCH,
  PAYOUT_SET_SEARCH_TERM,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setPayoutSearch = (payload) => {
  return {
    type: PAYOUT_SET_SEARCH,
    payload,
  };
};

export const setPayoutSearchTerm = (payload) => {
  return {
    type: PAYOUT_SET_SEARCH_TERM,
    payload,
  };
};

export const searchPayout = ({
  search = "",
  type = "",
  status = "",
  leader = "",
  email = "",
  startDate = "",
  endDate = "",
  fromNetwork = "",
  excludeNetwork = null,
  page = 1,
  limit = 10,
}) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      type,
      status,
      fromNetwork: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : fromNetwork,
      excludeNetwork,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
      email,
      startDate,
      endDate,
    }
    dispatch(fetchStart());
    dispatch(setPayoutSearchTerm(search));
    axios.post(`/crackers/adm/payout/get?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setPayoutSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
