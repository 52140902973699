import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { adjustWalletBalance, getWalletSearch } from '../../../actions/walletAction';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { WALLETS, WALLET_TYPES } from '../../../constants/labels';
import { placementGetAuthUser, placementGetMoreAuthUser, placementReferralsGetAuthUser, placementSearchUser, placementSearchUserPlacement, resetDisplayRoot, setDisplayRoot } from '../../../actions/placementAction';
import CareerChart from './CareerChart';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';


const PlacementTree = ({
    placement,
    dispatchSetDisplayRoot,
    moveUpDisplay,
    resetDisplay,
    dispatchSearchByUsername,
}) => (
    <>
        <div className='row'>
            <div className='col-xl-12'>
                <CareerChart
                    root={placement.root}
                    rootNode={placement.displayRoot}
                    nodes={placement.nodes}
                    setRootDisplay={dispatchSetDisplayRoot}
                    dispatchSearchByUsername={dispatchSearchByUsername}
                    handleMoveUp={moveUpDisplay}
                />
            </div>
            {/* <div className="col-xl-4">
                </div> */}
        </div>
        {/* <div className="row">
      <div className="col-12">
        <a className="btn btn-warning" onClick={moveUpDisplay}>Move up</a>
        <a className="btn btn-success" onClick={resetDisplay}>Reset</a>
      </div>
    </div> */}
    </>
)

const NoPlacement = () => (
    <div className='row'>
        <div className='col-xl-12'>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title mb-4'>Binary</h4>
                    <div className='row' style={{ minHeight: '200px' }}>
                        <div className='col-12'>No placement found</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

const rows = [
    { calories: 1, fat: 5, carbs: 14, protein: 45, name: 'test' }
]

const Binary = (props) => {

    const [isSearchUserDialogOpen, setIsSearchUserDialogOpen] = useState(false)
    const [username, setUsername] = useState('')
    const dispatch = useDispatch();
    const { error, loading, message } = useSelector(({ api }) => api);
    const dataList = useSelector(({ placement }) => placement.searchUser.report)
    const meta = useSelector(({ placement }) => placement.searchUser.meta)
    const [params, setParams] = useState({ page: 1, limit: 10 })

    const placement = useSelector(({ placement }) => placement)

    useEffect(() => {
        dispatch(placementGetAuthUser())
        // dispatch(placementReferralsGetAuthUser())
    }, [])

    useEffect(() => {
        if (username) {
            dispatch(placementSearchUser({ username, ...params }))
            setIsSearchUserDialogOpen(true)
        }
    }, [params, username])

    const resetDisplay = (e) => {
        dispatch(resetDisplayRoot())
        e.preventDefault()
    }

    const moveUpDisplay = (e) => {
        if (placement.displayRoot.parentID) {
            dispatch(setDisplayRoot(placement.nodes[placement.displayRoot.parentID]))
        }
        e.preventDefault()
    }

    const dispatchSetDisplayRoot = (node) => {
        // TODO: check if node exist if not check
        if (!loading) {
            if (node.uid !== placement.root.uid) {
                dispatch(placementGetMoreAuthUser(node))
            }
            dispatch(setDisplayRoot(node))
        }
    }

    const dispatchViewPlacement = (UID) => {
        if (!loading && UID) {
            dispatch(placementSearchUserPlacement(UID))
            setIsSearchUserDialogOpen(false)
        }
    }

    const dispatchSearchByUsername = (username) => {
        if (!loading && username) {
            setUsername(username)
        }
        if (!isSearchUserDialogOpen) {
            setIsSearchUserDialogOpen(true)
        }
    }
    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <Typography variant='h3'>Binary</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        {placement && placement.root && placement.root.name ? (
                            <PlacementTree
                                placement={placement}
                                dispatchSetDisplayRoot={dispatchSetDisplayRoot}
                                moveUpDisplay={moveUpDisplay}
                                resetDisplay={resetDisplay}
                                dispatchSearchByUsername={dispatchSearchByUsername}
                            />
                        ) : (
                            <NoPlacement />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        {loading && (
                            <div className='flex justify-center items-center'>
                                <Typography>Loading...</Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Dialog open={isSearchUserDialogOpen} onClose={() => setIsSearchUserDialogOpen(false)}>
                    <DialogTitle>
                        Search Users
                    </DialogTitle>
                    <DialogContent style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell align="right">Username</TableCell>
                                                <TableCell align="right">UID</TableCell>
                                                <TableCell align="right">Placement</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataList && dataList.length > 0 ? dataList.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell align="right">{row.username}</TableCell>
                                                    <TableCell align="right">{row.uid}</TableCell>
                                                    <TableCell align="right">{row.hasPlacement ? <button
                                                        type="button"
                                                        className="btn btn-icon"
                                                        title="Edit"
                                                        onClick={() => dispatchViewPlacement(row.uid)}
                                                    >
                                                        <i className="fa fa-eye" />
                                                    </button> : "No Placement"}</TableCell>
                                                </TableRow>
                                            ))
                                                :
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        No Entry found
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsSearchUserDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )

}

export default Binary;