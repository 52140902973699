import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';

import { searchPackages, editPackage, createPackage, deletePackage } from '../../../actions/packagesAction';
import { formatDateTime } from '../../../util/date';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { formatCurrency } from '../../../util/money';

const PackageType = ({ typeName }) => {
	let type;
	switch (typeName) {
		case NORMAL:
			type = <span className="tag tag-default">NORMAL</span>
			break;
		case RANK:
			type = <span className="tag tag-default">RANK</span>
			break;
		case PIN:
			type = <span className="tag tag-success">PIN</span>
			break;
		case ALPHA:
			type = <span className="tag tag-info">ALPHA</span>
			break;
		default:
			type = <span className="tag tag-default">NORMAL</span>
			break;
	}
	return type;
}

const ItemRow = ({
	id,
	name,
	type,
	subtype,
	rank,
	value,
	purchasable,
	direct,
	pair,
	pairMin,
	ternary,
	infinity,
	daily,
	variable,
	earningsCompound,
	maxCapMultiplier,
	expiryDays,
	image,
	isSpecial,
	isNoCampaign,
	noGiveDirect,
	noGivePair,
	createdAt,
	updatedAt,
}) => {

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

	const [editName, setEditName] = useState(name);
	const [editType, setEditType] = useState(type || NORMAL);
	const [editRank, setEditRank] = useState(rank);
	const [editValue, setEditValue] = useState(value);
	const [editPurchasable, setEditPurchasable] = useState(purchasable);
	const [editDirect, setEditDirect] = useState(direct);
	const [editPair, setEditPair] = useState(pair);
	const [editPairMin, setEditPairMin] = useState('');
	const [editTernary, setEditTernary] = useState('');
	const [editInfinity, setEditInfinity] = useState('');
	const [editDaily, setEditDaily] = useState('');
	const [editVariable, setEditVariable] = useState('');
	const [editEarningsCompound, setEditEarningsCompound] = useState('');
	const [editMaxCapMultiplier, setEditMaxCapMultiplier] = useState(maxCapMultiplier);
	const [editExpiryDays, setEditExpiryDays] = useState('');
	const [editSpecial, setEditSpecial] = useState(isSpecial);
	const [editNoCampaign, setEditNoCampaign] = useState(isNoCampaign);
	const [editNoDirect, setEditNoDirect] = useState(noGiveDirect);
	const [editNoPair, setEditNoPair] = useState(noGivePair);
	const [editImage, setEditImage] = useState(image);
	const [editSubtype, setEditSubtype] = useState(subtype || "");

	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};
	const handleDeleteClickOpen = () => {
		setIsDeleteDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const handleDeleteClose = () => {
		setIsDeleteDialogOpen(false);
	};

	const submitEditUsers = (e) => {
		e.preventDefault();
		dispatch(editPackage({
			id,
			name: editName,
			type: editType,
			rank: editRank,
			value: editValue,
			purchasable: editPurchasable,
			direct: editDirect,
			pair: editPair,
			pairMin: editPairMin,
			ternary: editTernary,
			infinity: editInfinity,
			daily: editDaily,
			variable: editVariable,
			earningsCompound: editEarningsCompound,
			maxCapMultiplier: editMaxCapMultiplier,
			expiryDays: editExpiryDays,
			special: editSpecial,
			noCampaign: editNoCampaign,
			noGiveDirect: editNoDirect,
			noGivePair: editNoPair,
			image: editImage,
			subtype: editSubtype,
		}));
		setIsDialogOpen(false);
	}

	const submitDeleteUsers = (e) => {
		e.preventDefault();
		dispatch(deletePackage({ id, }));
		setIsDeleteDialogOpen(false);
	}

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{name}</h6>
			</td>
			<td>
				<PackageType typeName={type} />
			</td>
			<td>
				<PackageType typeName={subtype} />
			</td>
			<td>{value ? formatCurrency(value) : '-'}</td>
			<td>{maxCapMultiplier ? maxCapMultiplier : '-'}</td>
			<td>{purchasable ? "Yes" : 'No'}</td>
			<td>{updatedAt ? formatDateTime(updatedAt) : '-'}</td>
			<td>
				<>
					<button
						type="button"
						className="btn btn-icon"
						title="Edit"
						onClick={handleClickOpen}
					>
						<i className="fa fa-edit" />
					</button>
					<Dialog open={isDialogOpen} onClose={handleClose}>
						<div className="modal-content">
							<form onSubmit={submitEditUsers}>
								<div className="modal-header">
									<h5 className="modal-title">Edit {name}</h5>
									<button type="button" className="close" onClick={handleClose}>
										{/* <span aria-hidden="true">&times;</span> */}
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Name</label>
												<input type="text" className="form-control" placeholder="name" value={editName} onChange={(e) => setEditName(e.target.value)} />
											</div>
										</div>

										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Value</label>
												<input type="number" className="form-control" value={editValue} onChange={(e) => setEditValue(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Image</label>
												<select className="form-control custom-select" value={editImage} onChange={(e) => setEditImage(e.target.value)}>
													<option value="1">$500</option>
													<option value="2">$1000</option>
													<option value="3">$3000</option>
													<option value="4">$5000</option>
													<option value="5">$10000</option>
													<option value="15k">$15000</option>
													<option value="6">$30000</option>
													<option value="3k-promo">$3000 promo</option>
													<option value="5k-promo">$5000 promo</option>
													<option value="10k-promo">$10000 promo</option>
													<option value="30k-promo">$30000 promo</option>
													<option value="a5k">Alpha $5000</option>
													<option value="a10k">Alpha $10000</option>
													<option value="a30k">Alpha $30000</option>
												</select>
											</div>
										</div>
										<div className='col-12'></div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Type<br /><small>PIN type are tickets<br />Normal type are like Zeta Subscribe</small></label>
												<select className="form-control custom-select" value={editType} onChange={(e) => setEditType(e.target.value)}>
													<option value="NORMAL">NORMAL</option>
													<option value="RANK">RANK</option>
													<option value="PIN">PIN</option>
												</select>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Rank of Type<br /><small>This rank affects the user when buying new package and preserving the direct or pairing bonus obtained<br />Eg. $1000 Ticket has rank 1 and gives 5% direct, $5000 has rank 2 and gives 6% direct. if user buys $5000, he will always get 6% direct until he buys a higher rank package</small></label>
												<input
													required
													type="number"
													className="form-control"
													value={editRank}
													onChange={(e) => setEditRank(e.target.value)}

												/>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Subtype<br /><small>Subtype Alpha for Alpha tickets<br />Normal subtype are normal tickets</small></label>
												<select
													className="form-control custom-select"
													value={editSubtype}
													onChange={(e) => setEditSubtype(e.target.value)}
												>
													<option value=''>{NORMAL}</option>
													<option value={ALPHA}>{ALPHA}</option>
												</select>
											</div>
										</div>
										<div className='col-12' />
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Max Cap Multiplier</label>
												<input type="number" className="form-control" value={editMaxCapMultiplier} onChange={(e) => setEditMaxCapMultiplier(e.target.value)} />
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Recommendation Bonus %</label>
												<input
													required
													type="number"
													className="form-control"
													value={editDirect}
													onChange={(e) => setEditDirect(e.target.value)}

												/>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Community Bonus %</label>
												<input
													required
													type="number"
													className="form-control"
													value={editPair}
													onChange={(e) => setEditPair(e.target.value)}

												/>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Can Purchase</label>
												<div className="form-group">
													<label className="fancy-radio custom-color-green"><input name="purchasable" value="true" type="radio" onChange={() => setEditPurchasable(true)} checked={editPurchasable === "true" || editPurchasable === true} /> Yes</label><br />
													<label className="fancy-radio custom-color-green"><input name="purchasable" value="false" type="radio" onChange={() => setEditPurchasable(false)} checked={editPurchasable === "false" || editPurchasable === false} /> No</label>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Is Special Package <br /><small>Only users who can see special package can view (set user setting on user list)</small></label>
												<div className="form-group">
													<label className="fancy-radio custom-color-green"><input name="special" value="true" type="radio" onChange={() => setEditSpecial(true)} checked={editSpecial === "true" || editSpecial === true} /> Yes</label><br />
													<label className="fancy-radio custom-color-green"><input name="special" value="false" type="radio" onChange={() => setEditSpecial(false)} checked={editSpecial === "false" || editSpecial === false} /> No</label>
												</div>
											</div>
										</div>
										<div className="col-6">
											<div className="form-group">
												<label className="form-label">No Recommendation for Sponsor <br /><small>If Yes then will not generate Direct Sponsor bonus for upline</small></label>
												<div className="form-group">
													<label className="fancy-radio custom-color-green"><input name="noDirect" value="true" type="radio" onChange={() => setEditNoDirect(true)} checked={editNoDirect === "true" || editNoDirect === true} /> Yes</label><br />
													<label className="fancy-radio custom-color-green"><input name="noDirect" value="false" type="radio" onChange={() => setEditNoDirect(false)} checked={editNoDirect === "false" || editNoDirect === false} /> No</label>
												</div>
											</div>
										</div>
										<div className="col-6">
											<div className="form-group">
												<label className="form-label">No Community For uplines <br /><small>If Yes then will not generate matching volume for uplines</small></label>
												<div className="form-group">
													<label className="fancy-radio custom-color-green"><input name="noPairing" value="true" type="radio" onChange={() => setEditNoPair(true)} checked={editNoPair === "true" || editNoPair === true} /> Yes</label><br />
													<label className="fancy-radio custom-color-green"><input name="noPairing" value="false" type="radio" onChange={() => setEditNoPair(false)} checked={editNoPair === "false" || editNoPair === false} /> No</label>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label className="form-label">Cannot Join Campaign <br /><small>If Yes then users cannot use this ticket to join Campaign</small></label>
												<div className="form-group">
													<label className="fancy-radio custom-color-green"><input name="editNoCampaign" value="true" type="radio" onChange={() => setEditNoCampaign(true)} checked={editNoCampaign === "true" || editNoCampaign === true} /> Yes</label><br />
													<label className="fancy-radio custom-color-green"><input name="editNoCampaign" value="false" type="radio" onChange={() => setEditNoCampaign(false)} checked={editNoCampaign === "false" || editNoCampaign === false} /> No</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
									<button type="submit" className="btn btn-primary">Update Package</button>
								</div>
							</form>

						</div>
					</Dialog>
				</>
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					data-toggle="modal"
					data-target={`#packagemodal-view-${id}`}
				>
					<i className="fa fa-eye" />
				</button>
				<div className="modal fade" id={`packagemodal-view-${id}`} tabIndex="-1" role="dialog" aria-hidden="true">
					<div className="modal-dialog" >
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">View {name}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>
									<span className="text-bold">Name: </span>{name}<br />
									<span className="text-bold">Type: </span><PackageType typeName={type} /><br />
									<span className="text-bold">Subtype: </span><PackageType typeName={subtype} /><br />
									<span className="text-bold">Rank of Type: </span>{rank}<br />
									<span className="text-bold">Value: </span>{value}<br />
									<span className="text-bold">Max Cap Multiplier: </span>{maxCapMultiplier}<br />
									<span className="text-bold">Recommendation %: </span>{direct ? direct : '-'}%<br />
									< span className="text-bold">Community %: </span>{pair ? pair : '-'}%<br />
									< span className="text-bold">Is Special Package: </span>{isSpecial ? 'Yes' : 'No'}<br />
									< span className="text-bold">No Recommendation (direct) for upline: </span>{noGiveDirect ? 'Yes' : 'No'}<br />
									< span className="text-bold">No Community (binary) for upline: </span>{noGivePair ? 'Yes' : 'No'}<br />
									< span className="text-bold">Cannot Join Campaign: </span>{isNoCampaign ? 'Yes' : 'No'}<br />
								</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-icon"
					title="Delete"
					onClick={handleDeleteClickOpen}
				>
					<i className="text-danger fa fa-trash" />
				</button>
				<Dialog open={isDeleteDialogOpen} onClose={handleDeleteClose}>
					<div className="modal-content">
						<form onSubmit={submitDeleteUsers}>
							<div className="modal-header">
								<h5 className="modal-title">Delete {name}</h5>
								<button type="button" className="close" onClick={handleDeleteClose}>
									{/* <span aria-hidden="true">&times;</span> */}
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<p>Are You Sure?</p>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={handleDeleteClose}>Close</button>
								<button type="submit" className="btn btn-primary bg-danger">Delete Package</button>
							</div>
						</form>

					</div>
				</Dialog>
			</td>
		</tr >
	)
}

const NORMAL = 'NORMAL'
const RANK = 'RANK'
const PIN = 'PIN'
const ALPHA = 'ALPHA'

const Packages = (props) => {
	const [search, setSearch] = useState('');

	const [addName, setAddName] = useState('');
	const [addType, setAddType] = useState(NORMAL);
	const [addRank, setAddRank] = useState('');
	const [addValue, setAddValue] = useState('');
	const [addPurchasable, setAddPurchasable] = useState(true);
	const [addDirect, setAddDirect] = useState('');
	const [addPair, setAddPair] = useState('');
	const [addPairMin, setAddPairMin] = useState('');
	const [addTernary, setAddTernary] = useState('');
	const [addInfinity, setAddInfinity] = useState('');
	const [addDaily, setAddDaily] = useState('');
	const [addVariable, setAddVariable] = useState('');
	const [addEarningsCompound, setAddEarningsCompound] = useState('');
	const [addMaxCapMultiplier, setAddMaxCapMultiplier] = useState('');
	const [addExpiryDays, setAddExpiryDays] = useState('');
	const [addSpecial, setAddSpecial] = useState(false);
	const [addNoCampaign, setAddNoCampaign] = useState(false);
	const [addNoDirect, setAddNoDirect] = useState(false);
	const [addNoPair, setAddNoPair] = useState(false);
	const [addImage, setAddImage] = useState(1);
	const [addSubtype, setAddSubtype] = useState('');

	const [error, setError] = useState('');
	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ packages }) => packages.packages.report)
	const meta = useSelector(({ packages }) => packages.packages.meta)

	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { authUser } = useSelector(({ user }) => user);


	useEffect(() => {
		dispatch(searchPackages({ search, ...params }))
	}, [params])


	const searchUsers = (e) => {
		e.preventDefault();
		dispatch(searchPackages({ search, ...params }))
	}

	const clearFields = (e) => {
		e.preventDefault();
		setAddName('');
		setAddType(NORMAL);
		setAddSubtype('');
		setAddRank('');
		setAddValue('');
		setAddPurchasable(true);
		setAddDirect('');
		setAddPair('');
		setAddPairMin('');
		setAddTernary('');
		setAddInfinity('');
		setAddDaily('');
		setAddVariable('');
		setAddEarningsCompound('');
		setAddMaxCapMultiplier('');
		setAddExpiryDays('');
		setAddSpecial(false);
		setAddNoCampaign(false);
		setAddImage(1);
		setAddNoDirect(false);
		setAddNoPair(false);
		setError('')
	}

	const validateAddPackage = () => {
		if (!addName) {
			return ('No Name');
		} else if (!addType) {
			return ('No Type');
		} else if (addRank && !(Number.isInteger(Number(addRank)))) {
			return ('Rank must be an Integer');
		} else if (!addValue) {
			return ('No Value');
		} else if (addDirect && (isNaN(Number(addDirect)))) {
			return ('direct % must be a number');
		} else if (addPair && (isNaN(Number(addPair)))) {
			return ('pair % must be a number');
		} else if (addPairMin && (isNaN(Number(addPairMin)))) {
			return ('pair min amount must be a number');
		} else if (addTernary && (isNaN(Number(addTernary)))) {
			return ('ternary % amount must be a number');
		} else if (addInfinity && (isNaN(Number(addInfinity)))) {
			return ('Infinity amount must be a number');
		} else if (addDaily && (isNaN(Number(addDaily)))) {
			return ('daily % amount must be a number');
		} else if (addVariable && (isNaN(Number(addVariable)))) {
			return ('variable % amount must be a number');
		} else if (addEarningsCompound && (isNaN(Number(addEarningsCompound)))) {
			return ('earnings compound % amount must be a number');
		} else if (addMaxCapMultiplier && (isNaN(Number(addMaxCapMultiplier)))) {
			return ('Max Cap Multiplier must be a number');
		} else if (addExpiryDays && (isNaN(Number(addExpiryDays)))) {
			return ('expiry days must be a number');
		} else {
			return ('');
		}
	}

	const submitAddPackage = (e) => {
		e.preventDefault();
		const hasError = validateAddPackage();

		if (!hasError) {
			dispatch(createPackage({
				name: addName,
				type: addType,
				subtype: addSubtype,
				rank: addRank,
				value: addValue,
				purchasable: addPurchasable,
				direct: addDirect,
				pair: addPair,
				pairMin: addPairMin,
				ternary: addTernary,
				infinity: addInfinity,
				daily: addDaily,
				variable: addVariable,
				earningsCompound: addEarningsCompound,
				maxCapMultiplier: addMaxCapMultiplier,
				expiryDays: addExpiryDays,
				image: addImage,
				special: addSpecial,
				noCampaign: addNoCampaign,
				noGiveDirect: addNoDirect,
				noGivePair: addNoPair,
			}))
			clearFields(e)
		} else {
			setError(hasError)
		}
	}

	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })


	const renderError = () => {
		return (error ? <p className="text-danger">{error}</p> : <p>&nbsp;</p>)
	}

	return (
		<div>
			<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center">
						<ul className="nav nav-tabs page-header-tab">
							<li className="nav-item">
								<a
									className="nav-link active"
									id="package-tab"
									data-toggle="tab"
									href="#package-list"
								>
									Packages
								</a>
							</li>
							{authUser && Number(authUser.role) >= 4 && <li className="nav-item">
								<a className="nav-link" id="add-package-tab" data-toggle="tab" href="#package-add">
									Add New Package
								</a>
							</li>}
						</ul>
						{/* <div className="header-action">
								<button type="button" className="btn btn-primary">
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</div> */}
					</div>
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<form onSubmit={searchUsers}>
										<p className="mb-2">Search Name</p>
										<div className="input-group mb-3">
											<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." />
										</div>
										<button type="submit" className="btn btn-primary">
											<i className="fe fe-search mr-2" />
											Search
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div className="tab-pane fade show active" id="package-list" role="tabpanel">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Packages</h3>
									{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
											<thead>
												<tr>
													<th>ID</th>
													<th>Name</th>
													<th>Type</th>
													<th>Subtype</th>
													<th>Value</th>
													<th>Multiplier</th>
													<th>Can Purchase</th>
													<th>Updated Date</th>
													<th className="w100">Action</th>
												</tr>
											</thead>
											<tbody>
												{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={6}><p className="text-center">No data found</p></td></tr>}
												{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

											</tbody>
										</table>
										<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />

									</div>

								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="package-add" role="tabpanel">
							<div className="card">
								<div className="card-body">
									<form onSubmit={submitAddPackage}>
										<div className="row clearfix">
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Name</label>
													<input
														required
														type="text"
														className="form-control"
														placeholder="Name"
														value={addName}
														onChange={(e) => setAddName(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-8 col-sm-12">
												<div className="form-group">
													<label className="form-label">Value</label>
													<input
														required
														type="number"
														className="form-control"
														value={addValue}
														onChange={(e) => setAddValue(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Image</label>
													<select className="form-control custom-select" value={addImage} onChange={(e) => setAddImage(e.target.value)}>
														<option value="1">$500</option>
														<option value="2">$1000</option>
														<option value="3">$3000</option>
														<option value="4">$5000</option>
														<option value="5">$10000</option>
														<option value="15k">$15000</option>
														<option value="6">$30000</option>
														<option value="3k-promo">$3000 promo</option>
														<option value="5k-promo">$5000 promo</option>
														<option value="10k-promo">$10000 promo</option>
														<option value="30k-promo">$30000 promo</option>
														<option value="a5k">Alpha $5000</option>
														<option value="a10k">Alpha $10000</option>
														<option value="a30k">Alpha $30000</option>
													</select>
												</div>
											</div>
											<div className='col-12'></div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Type<br /><small>PIN type are tickets<br />Normal type are like Zeta Subscribe</small></label>
													<select
														required
														className="form-control custom-select"
														value={addType}
														onChange={(e) => setAddType(e.target.value)}
													>
														<option value={NORMAL}>{NORMAL}</option>
														<option value={RANK}>{RANK}</option>
														<option value={PIN}>{PIN}</option>
													</select>
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Rank of Type<br /><small>This rank affects the user when buying new package and preserving the direct or pairing bonus obtained<br />Eg. $1000 Ticket has rank 1 and gives 5% direct, $5000 has rank 2 and gives 6% direct. if user buys $5000, he will always get 6% direct until he buys a higher rank package</small></label>
													<input
														required
														type="number"
														className="form-control"
														value={addRank}
														onChange={(e) => setAddRank(e.target.value)}

													/>
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Subtype<br /><small>Subtype Alpha for Alpha tickets<br />Normal subtype are normal tickets</small></label>
													<select
														className="form-control custom-select"
														value={addSubtype}
														onChange={(e) => setAddSubtype(e.target.value)}
													>
														<option value=''>{NORMAL}</option>
														<option value={ALPHA}>{ALPHA}</option>
													</select>
												</div>
											</div>
											<div className='col-12' />
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Max Cap Multiplier</label>
													<input
														required
														type="number"
														className="form-control"
														value={addMaxCapMultiplier}
														onChange={(e) => setAddMaxCapMultiplier(e.target.value)}

													/>
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Recommendation Bonus %</label>
													<input
														required
														type="number"
														className="form-control"
														value={addDirect}
														onChange={(e) => setAddDirect(e.target.value)}

													/>
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<label className="form-label">Community Bonus % (Enter 4 for 4%)</label>
													<input
														required
														type="number"
														className="form-control"
														value={addPair}
														onChange={(e) => setAddPair(e.target.value)}

													/>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Is Special Package <br /><small>Only users who can see special package can view (set user setting on user list)</small></label>
													<div className="form-group">
														<label className="fancy-radio custom-color-green"><input name="special" value="true" type="radio" onChange={() => setAddSpecial(true)} checked={addSpecial === "true" || addSpecial === true} /> Yes</label><br />
														<label className="fancy-radio custom-color-green"><input name="special" value="false" type="radio" onChange={() => setAddSpecial(false)} checked={addSpecial === "false" || addSpecial === false} /> No</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="form-group">
													<label className="form-label">No Recommendation for Sponsor <br /><small>If Yes then will not generate Direct Sponsor bonus for upline</small></label>
													<div className="form-group">
														<label className="fancy-radio custom-color-green"><input name="noDirect" value="true" type="radio" onChange={() => setAddNoDirect(true)} checked={addNoDirect === "true" || addNoDirect === true} /> Yes</label><br />
														<label className="fancy-radio custom-color-green"><input name="noDirect" value="false" type="radio" onChange={() => setAddNoDirect(false)} checked={addNoDirect === "false" || addNoDirect === false} /> No</label>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="form-group">
													<label className="form-label">No Community For uplines <br /><small>If Yes then will not generate matching volume for uplines</small></label>
													<div className="form-group">
														<label className="fancy-radio custom-color-green"><input name="noPairing" value="true" type="radio" onChange={() => setAddNoPair(true)} checked={addNoPair === "true" || addNoPair === true} /> Yes</label><br />
														<label className="fancy-radio custom-color-green"><input name="noPairing" value="false" type="radio" onChange={() => setAddNoPair(false)} checked={addNoPair === "false" || addNoPair === false} /> No</label>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Cannot Join Campaign <br /><small>If Yes then users cannot use this ticket to join Campaign</small></label>
													<div className="form-group">
														<label className="fancy-radio custom-color-green"><input name="noCampaign" value="true" type="radio" onChange={() => setAddNoCampaign(true)} checked={addNoCampaign === "true" || addNoCampaign === true} /> Yes</label><br />
														<label className="fancy-radio custom-color-green"><input name="noCampaign" value="false" type="radio" onChange={() => setAddNoCampaign(false)} checked={addNoCampaign === "false" || addNoCampaign === false} /> No</label>
													</div>
												</div>
											</div>
											<div className="col-12">
												{renderError()}
											</div>
											<div className="col-12">
												<button type="submit" className="btn btn-primary mr-2">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary"
													onClick={clearFields}
												>
													Clear
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default Packages;