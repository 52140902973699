import React, { useEffect } from 'react';
// import Piechart from '../../common/piechart';
// import Columnchart from '../../common/columnchart';
// import Stackedchart from '../../common/stackedchart';
// import Sparklineschart from '../../common/sparklineschart';
// import { Link } from 'react-router-dom';
// import Donutchart from '../../common/donutchart';
// import { connect } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";

// import CountUp from 'react-countup';

import { getAllLeaders, getSiteDetails } from '../../../actions/siteAction';
import { formatCurrency } from '../../../util/money';

const Dashboard = (props) => {
	const dispatch = useDispatch();
	const details = useSelector(({ site }) => site.details)
	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { token, authUser } = useSelector(({ user }) => user);

	const refreshDetails = () => {
		dispatch(getSiteDetails())
		dispatch(getAllLeaders())
	}
	useEffect(() => {
		refreshDetails()
	}, [])

	const {
		dailySales,
		totalSales,
		pendingWithdrawal,
		totalWithdrawal,
		monthSales,
	} = details;
	return (
		<>
			<div>
				<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-lg-12">
								<div className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}>
									<h4>Welcome, {authUser && authUser.username ? authUser.username : ''}!</h4>
								</div>
							</div>
							<div className="col-lg-12 text-right mb-4">
								<button
									type="button"
									className="btn btn-primary"
									onClick={refreshDetails}>
									<i className="fa fa-refresh" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3 col-md-6">
								<div className="card">
									<div className="card-body w_sparkline">
										<div className="details">
											<span>Daily Sales</span>
											<h3 className="mb-0">
												<span className="counter">${formatCurrency(dailySales || 0)}</span>
											</h3>
										</div>
										<div className="w_chart">
											<div id="mini-bar-chart1" className="mini-bar-chart" />
										</div>
									</div>
								</div>
								{/* 
											<div className="w_chart">
													<span
														ref={this.sparkline1}
														id="mini-bar-chart1"
														className="mini-bar-chart"
													></span>
												</div>
										*/}
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card">
									<div className="card-body w_sparkline">
										<div className="details">
											<span>Month Till Date Sales</span>
											<h3 className="mb-0">
												${formatCurrency(monthSales || 0)}
												{/* <span >124</span> */}
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card">
									<div className="card-body w_sparkline">
										<div className="details">
											<span>Total Sales</span>
											<h3 className="mb-0">
												${formatCurrency(totalSales || 0)}
												{/* <span >124</span> */}
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card">
									<div className="card-body w_sparkline">
										<div className="details">
											<span>Total Withdrawal</span>
											<h3 className="mb-0 counter">${formatCurrency(totalWithdrawal || 0)}</h3>
										</div>

									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="card">
									<div className="card-body w_sparkline">
										<div className="details">
											<span>Pending Withdrawal</span>
											<h3 className="mb-0 counter">${formatCurrency(pendingWithdrawal || 0)}</h3>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</>
	);

}

export default Dashboard;