import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_SET_DATA,
  USER_TOKEN_SET,
  USER_SET_UNAUTHORIZED,
} from '../constants/actionTypes';


import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';


export const setUsertoken = (payload) => {
  return {
    type: USER_TOKEN_SET,
    payload,
  };
};

export const setUserData = (payload) => {
  return {
    type: USER_SET_DATA,
    payload,
  };
};

export const setUserSignOut = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const setUnauthorized = () => {
  return {
    type: USER_SET_UNAUTHORIZED,
  }
}


export const userSignIn = ({
  username,
  password,
  // captcha, captchaCode 
}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/login', {
      username,
      password,
      // captcha,
      // captchaCode
    }
    ).then(({ data }) => {
      if (data.token && data.user) {
        localStorage.setItem('admin_token', JSON.stringify(data.token));
        localStorage.setItem('admin_user', JSON.stringify(data.user));
        axios.defaults.headers.common['Authorization'] = data.token;
        dispatch(showMessage('Logged In'));
        dispatch(fetchSuccess());
        dispatch(setUsertoken(data.token));
        dispatch(setUserData(data.user));
        dispatch(push('/app/home'));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem('admin_token');
    localStorage.removeItem('admin_user');
    dispatch(setUserSignOut());
    dispatch(showMessage('Logged Out'));
    dispatch(push('/'));
  }
};
