import React, { useState } from 'react';
// import CountUp from 'react-countup'

import UploadDownload from './UploadDownload';
import DownloadList from './DownloadList';

const Downloads = (props) => {
    const [tab, setTab] = useState(0);

    const handleSetTab = (e, index) => {
        e.preventDefault();
        setTab(index)
    }

    return (
        <>
            <div>
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Downloads</a></li>
                                <li className="nav-item"><a className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 1)}>New Upload</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {tab === 0 && <DownloadList />}
                {tab === 1 && <UploadDownload />}
            </div>
        </>
    )

}

export default Downloads;