import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { approveKYC, getKYCSearch } from '../../../actions/kycAction';
import { adjustWalletBalance } from '../../../actions/walletAction';
import { KYC_STATUSES } from '../../../constants/labels';
import { formatDate, formatDateTime } from '../../../util/date';
import StatusTypes from '../../Shared/StatusTypes';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getImageUrl } from '../../../util/strings';
import { DialogContent } from '@material-ui/core';
import Image from '../../Image';
import { getAllLeaders } from '../../../actions/siteAction';

const ItemRow = ({
    id,
    username,
    uid,
    authRole,
    status,
    createdAt,
    dob,
    email,
    fileBack,
    fileFront,
    firstname,
    lastname,
    gender,
    identityNo,
    maritalStatus,
    nationality,
    approvalBy,
    approvalDate,
    remarks,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editAction, setEditAction] = useState('APPROVE');
    const [editRemarks, setEditRemarks] = useState('');

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(approveKYC({
            id,
            action: editAction,
            remarks: editRemarks,
        }));
        setIsDialogOpen(false);
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{formatDateTime(createdAt)}</td>
            <td>{username}</td>
            <td>{status ? <StatusTypes status={status} /> : '-'}</td>
            <td>{approvalBy ? approvalBy : '-'}</td>
            <td>{approvalDate ? formatDateTime(approvalDate) : '-'}</td>
            <td>
                {authRole && Number(authRole) >= 4 ?
                    <>
                        <button
                            type="button"
                            className="btn btn-icon"
                            title="Edit"
                            onClick={handleClickOpen}
                        >
                            <i className="text-success fa fa-edit" />
                        </button>
                        <Dialog open={isDialogOpen} onClose={handleClose}>
                            <div className="modal-content">
                                <DialogContent>
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-header">
                                            <h5 className="modal-title">KYC {username}</h5>
                                            <button type="button" className="close" onClick={handleClose}>
                                                {/* <span aria-hidden="true">&times;</span> */}
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Username</label>
                                                    <p>{username}</p>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">UID</label>
                                                    <p>{uid}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">First Name</label>
                                                    <p>{firstname}</p>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Last Name</label>
                                                    <p>{lastname}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Email</label>
                                                    <p>{email}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Date Of Birth</label>
                                                    <p>{formatDate(dob)}</p>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Gender</label>
                                                    <p>{gender}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Nationality</label>
                                                    <p>{nationality}</p>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <label className="form-label">Identity No</label>
                                                    <p>{identityNo}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="form-label">Marital Status</label>
                                                    <p>{maritalStatus}</p>
                                                </div>
                                            </div>

                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">ID Front</label>
                                                    <div className='h-4 w-4'>
                                                        <Image
                                                            src={getImageUrl(fileFront.urlPath)}
                                                            alt="Front"
                                                            zoom
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">ID Back</label>
                                                    <div>
                                                        <Image
                                                            src={getImageUrl(fileBack.urlPath)}
                                                            alt="Back"
                                                            zoom
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div className="col-12">
                                                    <label className="form-label">Remarks</label>
                                                    <p>{remarks}</p>
                                                </div>
                                            </div>
                                            {status !== "APPROVE" && <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Approval</label>
                                                            <select className="form-control custom-select" value={editAction} onChange={(e) => setEditAction(e.target.value)}>
                                                                <option value="APPROVE">Approve</option>
                                                                <option value="REJECT">Reject</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Remarks</label>
                                                            <input type="text" className="form-control" placeholder="Remarks" value={editRemarks} onChange={(e) => setEditRemarks(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                            {status !== "APPROVE" && <button type="submit" className="btn btn-primary">Submit</button>}
                                        </div>
                                    </form>
                                </DialogContent>
                            </div>
                        </Dialog>
                    </> : ''}
            </td>
        </tr>
    )
}

const KYCs = (props) => {
    const [search, setSearch] = useState("")
    const [searchStatus, setSearchStatus] = useState("ALL")
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [searchEmail, setSearchEmail] = useState('');
    const [searchLeader, setSearchLeader] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [approvalStart, setapprovalStart] = useState("")
    const [approvalEnd, setapprovalEnd] = useState("")

    const dispatch = useDispatch();
    const dataList = useSelector(({ kyc }) => kyc.kyc.report)
    const meta = useSelector(({ kyc }) => kyc.kyc.meta)
    const { authUser } = useSelector(({ user }) => user);
    const allLeaders = useSelector(({ site }) => site.allLeaders)

    useEffect(() => {
        dispatch(getAllLeaders())
    }, [])

    useEffect(() => {
        handleSearchKyc()
    }, [params])

    const validateSearch = () => {
        // if (!search) {
        //     return ('No search');
        // } else {
        //     return ('');
        // }
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const hasError = validateSearch();

        if (!hasError) {
            handleSearchKyc()
            setError('')

        } else {
            setError(hasError)
        }
    }

    const handleResetFilters = () => {
        setSearchStatus("ALL")
        setSearchLeader("")
        setSearch("")
        setSearchEmail("")
        setStartDate("")
        setEndDate("")
        setapprovalStart("")
        setapprovalEnd("")
        setParams({ page: 1, limit: 10 })
        setError('')
    }
    const handleSearchKyc = () => {
        dispatch(getKYCSearch({
            search,
            status: searchStatus === 'ALL' ? undefined : searchStatus,
            leader: searchLeader,
            email: searchEmail,
            startDate,
            endDate,
            approvalStartDate: approvalStart,
            approvalEndDate: approvalEnd,
            ...params
        }))
    }


    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) =>
        setParams({ ...params, limit: pageSize })

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="tab-content mt-3">
                    <div>
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <form className="row" onSubmit={submitSearch}>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Search Username</label>
                                            <div className="input-group">
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search username..." />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Search Email</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    value={searchEmail}
                                                    onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
                                                    placeholder="Search email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Status</label>
                                            <select className="form-control custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                <option value="">ALL</option>
                                                {KYC_STATUSES.map(s =>
                                                    <option key={s.value} value={s.value}>{s.label}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="form-group">
                                                <label>Leader</label>
                                                <div className="input-group">
                                                    <select
                                                        disabled={authUser?.role == 2 && authUser.limitLeader}
                                                        className="form-control custom-select"
                                                        value={searchLeader}
                                                        onChange={(e) => setSearchLeader(e.target.value)}>
                                                        <option value="">All Leaders</option>
                                                        {allLeaders && allLeaders.length > 0 ?
                                                            allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                            : <></>
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Created Start Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Created End Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Approval Start Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={approvalStart}
                                                    onChange={(e) => setapprovalStart(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <label>Approval End Date</label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={approvalEnd}
                                                    onChange={(e) => setapprovalEnd(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">&nbsp;</div>
                                        <div className="col-md-2 col-sm-6">
                                            <button type="submit" className="btn btn-primary btn-block">
                                                Search
                                            </button>
                                        </div>
                                        <div className="col-md-2 col-sm-6">
                                            <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                Reset
                                            </button>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">{renderError()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">KYCs</h3>
                                {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Created Date</th>
                                                <th>Username</th>
                                                <th>Status</th>
                                                <th>Approved By</th>
                                                <th>Approved Date</th>
                                                <th className="w150">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataList && dataList.length > 0 ? dataList.map((w, i) => <ItemRow key={w.id} {...w} authRole={authUser.role} />)
                                                :
                                                <tr><td colSpan={7}><p className="text-center">No Entry found</p></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default KYCs;