import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';

import { searchPayout } from '../../../actions/payoutAction';
import { formatDateTime } from '../../../util/date';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { formatCurrency } from '../../../util/money';
import StatusBadge from '../../Badges/StatusBadge';
import { userRankingString } from '../../../util/strings';
import { WalletType } from '../Wallets/Wallets';
import { getAllLeaders } from '../../../actions/siteAction';


const CAMPAIGN_BONUS = 'CAMPAIGN_BONUS'
const DIRECT_SPONSOR = 'DIRECT_SPONSOR'
const PAIRING_BONUS = 'PAIRING_BONUS'
const RANK_MATCH_BONUS = 'RANK_MATCH_BONUS'
const RANK_ROI_POOL = 'RANK_ROI_POOL'

const stringPayoutType = (typeName) => {
	let type;
	switch (typeName) {
		case CAMPAIGN_BONUS:
			type = "Campaign Roi"
			break;
		case DIRECT_SPONSOR:
			type = "Recommendation Reward"
			break;
		case PAIRING_BONUS:
			type = "Community Rewards"
			break;
		case RANK_MATCH_BONUS:
			type = "Rank Building Entitlement"
			break;
		case RANK_ROI_POOL:
			type = "Rank Director Entitlement"
			break;
		default:
			type = typeName
			break;
	}
	return type;
}
const PayoutType = ({ typeName }) => {
	let type;
	switch (typeName) {
		case CAMPAIGN_BONUS:
			type = <span className="tag tag-info">{stringPayoutType(typeName)}</span>
			break;
		case DIRECT_SPONSOR:
			type = <span className="tag tag-warning">{stringPayoutType(typeName)}</span>
			break;
		case PAIRING_BONUS:
			type = <span className="tag tag-default">{stringPayoutType(typeName)}</span>
			break;
		case RANK_MATCH_BONUS:
			type = <span className="tag tag-primary">{stringPayoutType(typeName)}</span>
			break;
		case RANK_ROI_POOL:
			type = <span className="tag tag-success">{stringPayoutType(typeName)}</span>
			break;
		default:
			type = <span className="tag tag-default">{typeName}</span>
			break;
	}
	return type;
}

const TransactionsRow = ({
	id,
	transaction_type,
	amount,
	to_wallet,
	to_balance,
	status,
	createdAt,
}) => {
	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<PayoutType typeName={transaction_type} />
			</td>
			<td>${amount ? formatCurrency(amount) : '-'}</td>
			<td>{to_wallet.type ? <WalletType walletType={to_wallet.type} /> : '-'}</td>
			<td>${to_balance ? formatCurrency(to_balance) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : '-'}</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
		</tr>
	)
}

const ItemRow = ({
	id,
	type,
	amount,
	basedOn,
	user,
	transactions,
	status,
	createdAt,
}) => {

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	// const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<PayoutType typeName={type} />
			</td>
			<td>${amount ? formatCurrency(amount) : '-'}</td>
			<td>${basedOn ? formatCurrency(basedOn) : '-'}</td>
			<td>{user?.username}<br /><small>{user?.email}</small></td>
			<td>{user?.leader}</td>
			<td>{status ? <StatusBadge status={status} /> : '-'}</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>
				<>
					<button
						type="button"
						className="btn btn-icon"
						title="View"
						onClick={handleClickOpen}
					>
						<i className="fa fa-eye" />
					</button>
					<Dialog open={isDialogOpen} onClose={handleClose} maxWidth="lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">View payout</h5>
								<button type="button" className="close" onClick={handleClose}>
									{/* <span aria-hidden="true">&times;</span> */}
								</button>
							</div>
							<div className="modal-body">
								<p>
									<span className="text-bold">Type: </span><PayoutType typeName={type} /><br />
									<span className="text-bold">Amount: </span>${amount ? formatCurrency(amount) : '-'}<br />
									<span className="text-bold">Based On: </span>${basedOn ? formatCurrency(basedOn) : '-'}<br />
									< span className="text-bold">Status: </span>{status ? <StatusBadge status={status} /> : '-'}<br />
									< span className="text-bold">Created At: </span>{createdAt ? formatDateTime(createdAt) : '-'}<br />
									<span className="text-bold">Username: </span>{user?.username}<br />
									<span className="text-bold">User ID: </span>{user?.uid}<br />
									<span className="text-bold">Email: </span>{user?.email}<br />
									<span className="text-bold">Rank: </span>{user?.ranking ? userRankingString(user?.ranking) : '-'}<br />
									<span className="text-bold">Contracts: </span>${user?.packageValue ? formatCurrency(user?.packageValue) : '-'}<br />
									<span className="text-bold">Total Earnings: </span>${user?.profits ? formatCurrency(user?.profits) : '-'}<br />
									<span className="text-bold">Total Sales: </span>${user?.totalSales ? formatCurrency(user?.totalSales) : '-'}<br />
								</p>

								<h4>Transactions</h4>
								{transactions && transactions.length > 0 ?
									<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
										<thead>
											<tr>
												<th>ID</th>
												<th>Type</th>
												<th>Amount</th>
												<th>Wallet Type</th>
												<th>Wallet Balance (ATT)</th>
												<th>Status</th>
												<th>Created Date</th>
											</tr>
										</thead>
										<tbody>
											{transactions.map(d => <TransactionsRow key={d.id} {...d} />)}
										</tbody>
									</table>
									:
									<p>No Related Transactions</p>
								}

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
							</div>
						</div>
					</Dialog>
				</>
			</td>
		</tr >
	)
}


const Payouts = (props) => {
	const [search, setSearch] = useState('');
	const [searchType, setSearchType] = useState('');
	const [searchStatus, setSearchStatus] = useState("")
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [searchEmail, setSearchEmail] = useState('');
	const [searchLeader, setSearchLeader] = useState('');
	const [fromNetwork, setfromNetwork] = useState('');
	const [excludeNetwork, setexcludeNetwork] = useState([]);
	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ payout }) => payout.payouts.report)
	const meta = useSelector(({ payout }) => payout.payouts.meta)

	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { authUser } = useSelector(({ user }) => user);
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		handleSearchPayout()
	}, [params])

	const searchForm = (e) => {
		e.preventDefault();
		handleSearchPayout()
	}
	const handleResetFilters = () => {
		setSearch("")
		setSearchType("")
		setSearchStatus("")
		setSearchLeader("")
		setSearchEmail("")
		setStartDate("")
		setEndDate("")
		setParams({ page: 1, limit: 10 })
	}
	const handleSearchPayout = () => {
		dispatch(searchPayout({
			search,
			type: searchType,
			status: searchStatus,
			leader: searchLeader,
			email: searchEmail,
			fromNetwork,
			excludeNetwork,
			startDate,
			endDate,
			...params
		}))
	}
	
	const handleAddExcludeNetwork = () => {
		setexcludeNetwork([...excludeNetwork, ""])
	}
	const handleSetExcludeNetwork = (data, id) => {
		setexcludeNetwork([
			...excludeNetwork.slice(0, id),
			data,
			...excludeNetwork.slice(id + 1)
		])
	}
	const handleRemoveExcludeNetwork = (id) => {
		setexcludeNetwork([
			...excludeNetwork.slice(0, id),
			...excludeNetwork.slice(id + 1)
		])
	}
	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	return (
		<div>
			<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center">
						<ul className="nav nav-tabs page-header-tab">
							<li className="nav-item">
								<a
									className="nav-link active"
									id="package-tab"
									data-toggle="tab"
									href="#package-list"
								>
									Payouts
								</a>
							</li>
						</ul>
					</div>

					<div className="card">
						<div className="card-body">
							<form className="row" onSubmit={searchForm}>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search User</label>
									<div className="input-group mb-3">
										<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." />
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Email</label>
									<div className="input-group">
										<input
											type="text"
											value={searchEmail}
											onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
											placeholder="Search email"
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Leader</label>
										<div className="input-group">
											<select
												disabled={authUser?.role == 2 && authUser.limitLeader}
												className="form-control custom-select"
												value={searchLeader}
												onChange={(e) => setSearchLeader(e.target.value)}>
												<option value="">All Leaders</option>
												{allLeaders && allLeaders.length > 0 ?
													allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
													: <></>
												}
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Payout Type</label>
									<select className="form-control custom-select" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
										<option value="">ALL</option>
										<option value={CAMPAIGN_BONUS}>{stringPayoutType(CAMPAIGN_BONUS)}</option>
										<option value={DIRECT_SPONSOR}>{stringPayoutType(DIRECT_SPONSOR)}</option>
										<option value={PAIRING_BONUS}>{stringPayoutType(PAIRING_BONUS)}</option>
										<option value={RANK_MATCH_BONUS}>{stringPayoutType(RANK_MATCH_BONUS)}</option>
										<option value={RANK_ROI_POOL}>{stringPayoutType(RANK_ROI_POOL)}</option>
									</select>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Status</label>
									<div className="multiselect_div">
										<select className="custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
											<option value="">All</option>
											<option value="DONE">Done</option>
											<option value="SYSTEM_REVERTED">Reverted</option>
											<option value="DONE_NO_PAYOUT">Done No Payout</option>
										</select>
									</div>
								</div>
								<div className="col-12">&nbsp;</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Start Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>End Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12" />
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label className="mt-1">From Network</label>
									<div className="input-group">
										<input
											disabled={authUser?.role == 2 && authUser.limitLeader}
											type="text"
											className="form-control"
											value={fromNetwork}
											onChange={(e) => setfromNetwork(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label className="mt-1">Exclude Network</label>
									{excludeNetwork.map((data, i) =>
										<div className="input-group mb-1" key={i}>
											<input
												required
												type="text"
												className="form-control"
												value={data}
												onChange={(e) => handleSetExcludeNetwork(e.target.value, i)}
											/>
											<button
												type="button"
												className="btn btn-icon"
												title="Delete"
												onClick={() => handleRemoveExcludeNetwork(i)}
											>
												<i className="text-danger fa fa-trash" />
											</button>
										</div>
									)}
									<br />
									<button
										type="button"
										className="btn btn-secondary"
										title="Add"
										onClick={() => handleAddExcludeNetwork()}
									>
										<i className="text-success fa fa-plus" /> Add
									</button>
								</div>
								<div className="col-12">&nbsp;</div>
								<div className="col-md-2 col-sm-6">
									<button type="submit" className="btn btn-primary btn-block mt-2">
										<i className="fe fe-search mr-2" />
										Search
									</button>
								</div>
								<div className="col-md-2 col-sm-6">
									<button type="button" className="btn btn-secondary btn-block mt-2" onClick={handleResetFilters}>
										Reset
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div className="tab-pane fade show active" id="package-list" role="tabpanel">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Payouts</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
											<thead>
												<tr>
													<th>ID</th>
													<th>Type</th>
													<th>Amount</th>
													<th>Based On</th>
													<th>Username</th>
													<th>Leader</th>
													<th>Status</th>
													<th>Created Date</th>
													<th className="w100">Action</th>
												</tr>
											</thead>
											<tbody>
												{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={6}><p className="text-center">No data found</p></td></tr>}
											</tbody>
										</table>
										<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />

									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	);

}

export default Payouts;