import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { adjustWalletBalance, getWalletSearch } from '../../../actions/walletAction';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { WALLETS, WALLET_TYPES } from '../../../constants/labels';
import { getAllLeaders } from '../../../actions/siteAction';

export const WalletType = ({ walletType }) => {
    let type;
    switch (walletType) {
        case "EARNINGS_WALLET":
            type = <span className="tag tag-info">Earnings</span>
            break;
        case WALLETS.CREDIT_WALLET:
            type = <span className="tag tag-info">Broker Points</span>
            break;
        case WALLETS.PROFIT_WALLET:
            type = <span className="tag tag-warning">Profit</span>
            break;
        case WALLETS.WITHDRAWAL_WALLET:
            type = <span className="tag tag-primary">Withdrawal</span>
            break;
        case WALLETS.BONUS_WALLET:
            type = <span className="tag tag-success">Bonus</span>
            break;
        case WALLETS.DEPOSIT_WALLET:
            type = <span className="tag tag-secondary">Deposit</span>
            break;
        case WALLETS.PURCHASE_WALLET:
            type = <span className="tag tag-danger">IL Points</span>
            break;
        case WALLETS.CREDIT_NEW_WALLET:
            type = <span className="tag tag-danger">Credit</span>
            break;
        case WALLETS.TOKEN_WALLET:
            type = <span className="tag tag-info">$ILT</span>
            break;
        case WALLETS.CAMPAIGN_WALLET:
            type = <span className="tag tag-warning">$CT</span>
            break;
        default:
            type = <span className="tag tag-default">{walletType}</span>
            break;
    }
    return type;
}

const ItemRow = ({
    id,
    userID,
    username,
    email,
    leader,
    walletType,
    amount,
    authRole,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [editAction, setEditAction] = useState('add');
    const [editAmount, setEditAmount] = useState('');
    const [editWalletType, setEditWalletType] = useState(walletType);
    const [editRemarks, setEditRemarks] = useState('');

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const submitEditUsers = (e) => {
        e.preventDefault();
        dispatch(adjustWalletBalance({
            userID,
            action: editAction,
            amount: editAmount,
            walletType: editWalletType,
            remarks: editRemarks,
        }));
        setIsDialogOpen(false);
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{username}<br /><small>{email}</small></td>
            <td>{leader}</td>
            <td>{walletType ? <WalletType walletType={walletType} /> : '-'}</td>
            <td>${formatCurrency(amount || 0)}</td>
            <td>
                {authRole && Number(authRole) >= 8 ?
                    <>
                        <button
                            type="button"
                            className="btn btn-icon"
                            title="Edit"
                            onClick={handleClickOpen}
                        >
                            <i className="text-success fa fa-edit" />
                        </button>
                        <Dialog open={isDialogOpen} onClose={handleClose}>
                            <div className="modal-content">
                                <form onSubmit={submitEditUsers}>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit {username}</h5>
                                        <button type="button" className="close" onClick={handleClose}>
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">Username</label>
                                                    <p>{username}</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Wallet Type</label>
                                                <p>{walletType ? <WalletType walletType={walletType} /> : '-'}</p>
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Balance</label>
                                                <p>${formatCurrency(amount || 0)}</p>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">Action</label>
                                                    <select className="form-control custom-select" value={editAction} onChange={(e) => setEditAction(e.target.value)}>
                                                        <option value="add">Add</option>
                                                        <option value="minus">Minus</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">Amount</label>
                                                    <input type="number" className="form-control" placeholder="Amount" value={editAmount} onChange={(e) => setEditAmount(e.target.value)} required />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label">Remarks</label>
                                                    <input type="text" className="form-control" placeholder="Remarks" value={editRemarks} onChange={(e) => setEditRemarks(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                        <button type="submit" className="btn btn-primary">Adjust balance</button>
                                    </div>
                                </form>

                            </div>
                        </Dialog>
                    </> : ''}
            </td>
        </tr>
    )
}

const Wallets = (props) => {
    const [search, setSearch] = useState("")
    const [searchType, setSearchType] = useState("")
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [searchEmail, setSearchEmail] = useState('');
    const [searchLeader, setSearchLeader] = useState('');

    const dispatch = useDispatch();
    const dataList = useSelector(({ wallet }) => wallet.wallets.report)
    const meta = useSelector(({ wallet }) => wallet.wallets.meta)

    const { authUser } = useSelector(({ user }) => user);
    const allLeaders = useSelector(({ site }) => site.allLeaders)

    useEffect(() => {
        dispatch(getAllLeaders())
    }, [])

    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) => setParams({ ...params, limit: pageSize })

    useEffect(() => {
        handleSearchAdminAdjust()
    }, [params])

    const validateSearch = () => {
        // if (!search) {
        //     return ('No search');
        // } else {
        //     return ('');
        // }
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const hasError = validateSearch();

        if (!hasError) {
            handleSearchAdminAdjust()
            setError('')

        } else {
            setError(hasError)
        }
    }
    const handleResetFilters = () => {
        setSearch("")
        setSearchType("")
        setSearchLeader("")
        setSearchEmail("")
        setParams({ page: 1, limit: 10 })
        setError("")
    }
    const handleSearchAdminAdjust = () => {
        dispatch(getWalletSearch({
            search,
            type: searchType,
            leader: searchLeader,
            email: searchEmail,
            ...params
        }))
    }
    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div>
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row" onSubmit={submitSearch}>
                                            <div className="col-5">
                                                <label>Search Username</label>
                                                <div className="input-group">
                                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search username..." />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Search Email</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        value={searchEmail}
                                                        onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
                                                        placeholder="Search email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Leader</label>
                                                    <div className="input-group">
                                                        <select
                                                            disabled={authUser?.role == 2 && authUser.limitLeader}
                                                            className="form-control custom-select"
                                                            value={searchLeader}
                                                            onChange={(e) => setSearchLeader(e.target.value)}>
                                                            <option value="">All Leaders</option>
                                                            {allLeaders && allLeaders.length > 0 ?
                                                                allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                                : <></>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <label>Wallet Type</label>
                                                <select className="form-control custom-select" value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                                                    <option value="">ALL</option>
                                                    {WALLET_TYPES.map(s =>
                                                        <option key={s.value} value={s.value}>{s.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-12">&nbsp;</div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Search
                                                </button>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                    Reset
                                                </button>
                                            </div>
                                        </form>
                                        <div className="row">
                                            <div className="col-12">{renderError()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Wallets</h3>
                                    {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username</th>
                                                    <th>Leader</th>
                                                    <th>Wallet</th>
                                                    <th>Balance</th>
                                                    <th className="w150">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataList && dataList.length > 0 ? dataList.map((w, i) => <ItemRow key={w.id} {...w} authRole={authUser.role} />)
                                                    :
                                                    <tr><td colSpan={7}><p className="text-center">No user wallets found</p></td></tr>
                                                }
                                            </tbody>
                                        </table>
                                        <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Wallets;