import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createLeader, getAllLeaders, getSiteLeaderSearch } from '../../../actions/siteAction';
import { formatDateTime } from '../../../util/date';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';

const LeaderList = ({
	id,
	uid,
	username,
	createdAt,
}) => {
	return (
		<tr>
			<td className="width45">
				{uid}
			</td>
			<td>
				<h6 className="mb-0">{username}</h6>
			</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
		</tr >
	)
}

const Users = (props) => {
	const [error, setError] = useState('');
	const [leaderParams, setLeaderParams] = useState({ page: 1, limit: 10 })
	const [addLeader, setAddLeader] = useState('');
	const [searchLeader, setSearchLeader] = useState('');


	const dispatch = useDispatch();
	const leaders = useSelector(({ site }) => site.leaders.report)
	const leaderMeta = useSelector(({ site }) => site.leaders.meta)
	const { authUser } = useSelector(({ user }) => user);


	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		if (authUser.role > 2) {
			dispatch(getSiteLeaderSearch({ search: searchLeader, ...leaderParams }))
		}
	}, [leaderParams])

	const handleLeaderPageChange = (page) =>
		setLeaderParams({ ...leaderParams, page })

	const handleLeaderPageSizeChange = (pageSize) =>
		setLeaderParams({ ...leaderParams, limit: pageSize })


	const validateLeaders = () => {
		if (!addLeader) {
			return ('Leader Username required');
		} else {
			return ('');
		}
	}

	const submitAddLeader = (e) => {
		e.preventDefault();
		const hasError = validateLeaders();

		if (!hasError) {
			dispatch(createLeader({
				leader: addLeader
			}))
			setError('')

		} else {
			setError(hasError)
		}
	}

	const renderError = () => {
		return (error ? <p className="text-danger  mb-2 mt-2">{error}</p> : <p className="mb-2 mt-2">&nbsp;</p>)
	}

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="card">
					<div className="card-body">
						<div className="row clearfix">
							<div className="col-12">
								<h6>Add Leader</h6>
								<form onSubmit={submitAddLeader}>
									<div className="row clearfix">
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<label className="form-label">Leader</label>
												<input
													required
													type="text"
													className="form-control"
													placeholder="Leader Username"
													value={addLeader}
													onChange={(e) => setAddLeader(e.target.value)}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">{renderError()}</div>
									</div>
									<button type="submit" className="btn btn-primary">
										Add Leader
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">Leaders List</h3>
						{/* <div className="card-options">
							<form>
								<div className="input-group">
									<input
										type="text"
										className="form-control form-control-sm"
										placeholder="Search something..."
										name="s"
									/>
									<span className="input-group-btn ml-2">
										<button className="btn btn-sm btn-default" type="submit">
											<span className="fe fe-search" />
										</button>
									</span>
								</div>
							</form>
						</div> */}
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
								<thead>
									<tr>
										<th>ID</th>
										<th>Username</th>
										<th>Created At</th>
									</tr>
								</thead>
								<tbody>
									{leaders && Array.isArray(leaders) && leaders.length > 0 ? leaders.map(user => <LeaderList key={user.id} {...user} />) : <tr><td colSpan={2}><p className="text-center">No leaders found</p></td></tr>}
								</tbody>
							</table>
							<TablePaginationWithLogic count={leaderMeta.itemCount} changePage={handleLeaderPageChange} changeRows={handleLeaderPageSizeChange} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default Users;