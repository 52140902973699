import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import settings from './settings';
import user from './user';
import api from './api';
import site from './site';
import admin from './admin';
import withdrawal from './withdrawal';
import support from './support';
import deposit from './deposit';
import wallet from './wallet';
import stock from './stock';
import kyc from './kyc';
import placement from './placement';
import packages from './packages';
import campaign from './campaign';
import payout from './payout';
import announcement from './announcement';
import systemsettings from './systemsettings';

export default (history) => combineReducers({
    router: connectRouter(history),
    settings,
    user,
    api,
    site,
    admin,
    withdrawal,
    support,
    deposit,
    wallet,
    stock,
    kyc,
    placement,
    packages,
    campaign,
    payout,
    announcement,
    systemsettings,
});