import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import isBefore from 'date-fns/isBefore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Divider } from '@material-ui/core';
import { adminLoginAsUser, autoPlaceForUserNoMatch, createLeader, createdBatchUsers, exportUsers, getAllLeaders, getSiteLeaderSearch, getSiteUsersSearch, resendVerificationEmail, toggleBlockUserSettings, toggleUserViewSpecialPackage, toggleUserWithdrawalBlock, updateEditUsers } from '../../../actions/siteAction';
import { formatDateTime } from '../../../util/date';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';

const UserRoles = ({ role }) => {
	let type;
	switch (role) {
		case "1":
			type = <span className="tag tag-default">Guest</span>
			break;
		case "2":
			type = <span className="tag tag-success">User</span>
			break;
		case "4":
			type = <span className="tag tag-info">Network</span>
			break;
		case "8":
			type = <span className="tag tag-danger">Admin</span>
			break;
		case "16":
		case "32":
			type = <span className="tag tag-danger">Super Admin</span>
			break;
		default:
			type = <span className="tag tag-default">User</span>
			break;
	}
	return type;
}

const userRolesString = (role) => {
	let type;
	switch (role) {
		case "1":
			type = "Guest"
			break;
		case "2":
			type = "User"
			break;
		case "4":
			type = "Network"
			break;
		case "8":
			type = "Admin"
			break;
		case "16":
			type = "Super Admin"
			break;
		default:
			type = "Client"
			break;
	}
	return type;
}

const UserRanking = ({ ranking }) => {
	let type;
	switch (ranking) {
		case "0":
			type = <span className="tag tag-default">Client</span>
			break;
		case "1":
			type = <span className="tag tag-default">Deputy Supervisor</span>
			break;
		case "2":
			type = <span className="tag tag-default">Supervisor</span>
			break;
		case "3":
			type = <span className="tag tag-default">Deputy Manager</span>
			break;
		case "4":
			type = <span className="tag tag-success">Manager</span>
			break;
		case "5":
			type = <span className="tag tag-success">Deputy Director</span>
			break;
		case "6":
			type = <span className="tag tag-info">Director</span>
			break;
		case "7":
			type = <span className="tag tag-info">Senior Director</span>
			break;
		case "8":
			type = <span className="tag tag-danger">Managing Director</span>
			break;
		default:
			type = <span className="tag tag-default">Client</span>
			break;
	}
	return type;
}

export const userRankingString = (ranking) => {
	let type;
	switch (ranking) {
		case "0":
			type = "No Rank"
			break;
		case "1":
			type = "VIP"
			break;
		case "2":
			type = "VVIP"
			break;
		case "3":
			type = "Achiever"
			break;
		case "4":
			type = "Director"
			break;
		case "5":
			type = "Group Director"
			break;
		default:
			type = ranking
			break;
	}
	return type;
}

// const userBORankingString = (ranking) => {
// 	let type;
// 	switch (ranking) {
// 		case "0":
// 			type = "Client"
// 			break;
// 		case "1":
// 			type = "Deputy Supervisor"
// 			break;
// 		case "2":
// 			type = "Supervisor"
// 			break;
// 		case "3":
// 			type = "Deputy Manager"
// 			break;
// 		case "4":
// 			type = "Manager"
// 			break;
// 		case "5":
// 			type = "Deputy Director"
// 			break;
// 		case "6":
// 			type = "Director"
// 			break;
// 		case "7":
// 			type = "Senior Director"
// 			break;
// 		case "8":
// 			type = "Managing Director"
// 			break;
// 		default:
// 			type = "Client"
// 			break;
// 	}
// 	return type;
// }

const UserRow = ({
	id,
	username,
	email,
	nationality,
	role,
	ranking,
	uid,
	referrerUsername,
	referralUID,
	earnings,
	emailVerified,
	leader,
	status,
	createdAt,
	authRole,
	withdrawalBlocked,
	notPlaced,
	canViewSpecial,
	isRoiBlocked,
	isSponsorBlocked,
	isPairingBlocked,
	isJoinCampaignBlocked,
	isTransferPurchaseBlocked,
	isTransferCreditBlocked,
	isTransferWithdrawalBlocked,
	isTransferDepositBlocked,
	isTransferProfitBlocked,
	isTransferBonusBlocked,
	isTransferCreditNewBlocked,
	isTransferTokenBlocked,
	isConvertTokenBlocked,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [editUsername, setEditUsername] = useState(username);
	const [editEmail, setEditEmail] = useState(email);
	const [editEmailVerified, setEditEmailVerified] = useState(emailVerified);
	const [editRanking, setEditRanking] = useState(ranking || '');
	const [editRole, setEditRole] = useState(role);
	const [editStatus, setEditStatus] = useState(status);

	const { username: adminUsername } = useSelector(({ user }) => user.authUser);
	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const submitEditUsers = (e) => {
		e.preventDefault();
		dispatch(updateEditUsers({
			id,
			username: editUsername,
			email: editEmail,
			emailVerified: editEmailVerified,
			status: editStatus,
			ranking: editRanking,
			role: editRole,
		}));
		setIsDialogOpen(false);
	}

	// const submitEditEmailOnlyUsers = (e) => {
	// 	e.preventDefault();
	// 	dispatch(updateEditUsersEmailOnly({
	// 		id,
	// 		username: editUsername,
	// 		email: editEmail,
	// 	}));
	// 	setIsDialogOpen(false);
	// }

	const handleResendVerificationEmail = (e) => {
		e.preventDefault();
		dispatch(resendVerificationEmail({ id, }));
	}

	const handleLoginAsUser = (e) => {
		e.preventDefault();
		dispatch(adminLoginAsUser({ userID: id, }));
	}

	const handleToggleUserWithdrawalBlock = (e) => {
		e.preventDefault();
		dispatch(toggleUserWithdrawalBlock({ id, withdrawalBlocked }));
		setIsDialogOpen(false);
	}
	const handleTriggerAutoPlace = (e) => {
		e.preventDefault();
		dispatch(autoPlaceForUserNoMatch({ id }));
		setIsDialogOpen(false);
	}
	const handleToggleUserViewSpecialPackage = (e) => {
		e.preventDefault();
		dispatch(toggleUserViewSpecialPackage({ id, status: !canViewSpecial ? "ACTIVE" : "DISABLED" }));
		setIsDialogOpen(false);
	}
	const handleToggleUserBlockSettings = (e, type, status) => {
		e.preventDefault();
		dispatch(toggleBlockUserSettings({ id, type, status: !status ? "ACTIVE" : "DISABLED" }));
		setIsDialogOpen(false);
	}

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{username}</h6>
				<span>{email}</span>
			</td>
			<td>
				<UserRoles role={role} />
			</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>{nationality}</td>
			<td>{notPlaced}</td>
			<td>{ranking ? userRankingString(ranking) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : '-'}</td>
			<td>{leader}</td>
			<td>
				{authRole && Number(authRole) >= 4 &&
					<>
						<button
							type="button"
							className="btn btn-icon"
							title="Edit"
							onClick={handleClickOpen}
						>
							<i className="text-success fa fa-edit" />
						</button>
						<Dialog open={isDialogOpen} onClose={handleClose}>
							<div className="modal-content">
								<form onSubmit={submitEditUsers}>
									<div className="modal-header">
										<h5 className="modal-title">Edit {username}</h5>
										<button type="button" className="close" onClick={handleClose}>
											{/* <span aria-hidden="true">&times;</span> */}
										</button>
									</div>
									<div className="modal-body">
										<div className="row">
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Username</label>
													<input
														type="text"
														className="form-control"
														disabled={Number(authRole) < 16}
														placeholder="username"
														value={editUsername}
														onChange={(e) => setEditUsername(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Email address</label>
													<input
														type="email"
														disabled={Number(authRole) < 16}
														className="form-control"
														placeholder="Email"
														value={editEmail}
														onChange={(e) => setEditEmail(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Email Verified</label>
													<select className="form-control custom-select" value={editEmailVerified} onChange={(e) => setEditEmailVerified(e.target.value)}>
														<option value={true}>Verified</option>
														<option value={false}>Unverified</option>
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Ranking</label>
													<select className="form-control custom-select" value={editRanking} onChange={(e) => setEditRanking(e.target.value)}>
														<option value="0">{userRankingString("0")}</option>
														<option value="1">{userRankingString("1")}</option>
														<option value="2">{userRankingString("2")}</option>
														<option value="3">{userRankingString("3")}</option>
														<option value="4">{userRankingString("4")}</option>
														<option value="5">{userRankingString("5")}</option>
														{/* <option value="6">{userRankingString("6")}</option>
														<option value="7">{userRankingString("7")}</option>
														<option value="8">{userRankingString("8")}</option> */}
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Role</label>
													<select className="form-control custom-select" value={editRole} onChange={(e) => setEditRole(e.target.value)}>
														<option value="1">{userRolesString("1")}</option>
														<option value="2">{userRolesString("2")}</option>
														<option value="4">{userRolesString("4")}</option>
														<option value="8">{userRolesString("8")}</option>
													</select>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<label className="form-label">Status</label>
													<select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
														<option value="ACTIVE">ACTIVE</option>
														<option value="SUSPENDED">SUSPENDED</option>
													</select>
												</div>
											</div>
											{authRole && Number(authRole) >= 4 && !emailVerified &&
												<div className="col-12">
													<button type="button" className="btn btn-primary" onClick={handleResendVerificationEmail}>Resend Verification Email</button>
												</div>
											}
											<div className="col-12">
												<button type="button" className="btn btn-primary mt-2" onClick={handleLoginAsUser}>
													<i className="text-success fa fa-sign-in" />
													&nbsp;&nbsp;Login as user
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<p className='mt-4'>
													<span className="text-bold">Withdrawal Locked: </span> {withdrawalBlocked ?
														<i className="text-danger fa fa-lock" />
														: <i className="text-success fa fa-unlock" />}
													<br />
												</p>
											</div>
											<div className="col-12">
												<button type="button" className="btn btn-primary" onClick={handleToggleUserWithdrawalBlock}>{withdrawalBlocked ? 'Unlock' : 'Lock'} Withdrawal</button>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<p className='mt-4'>
													<span className="text-bold">Referrals Not Placed: </span> {notPlaced}<br />
												</p>
											</div>
											{notPlaced > 0 && <div className="col-12">
												<button type="button" className="btn btn-primary" onClick={handleTriggerAutoPlace}>Auto Place No Match</button>
											</div>}
										</div>
										<div className="row">
											<div className="col-12">
												<FormControlLabel
													labelPlacement="start"
													label={<>Can View Special Packages:<br /><small className='text-muted'>can this user view special tickets</small></>}
													control={
														<Switch
															checked={canViewSpecial}
															onChange={handleToggleUserViewSpecialPackage}
															name="viewSpecial"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User ROI blocked:<br /><small className='text-muted'>block user from getting campaign payout</small></>}
													control={
														<Switch
															checked={isRoiBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_ROI", isRoiBlocked)}
															name="isRoiBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Referral Bonus blocked:<br /><small className='text-muted'>block user from getting recommendation bonus from his downline</small></>}
													control={
														<Switch
															checked={isSponsorBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_DIRECT_SPONSOR_BONUS", isSponsorBlocked)}
															name="isSponsorBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Community Bonus blocked:<br /><small className='text-muted'>block user from getting Community bonus from his binary tree</small></>}
													control={
														<Switch
															checked={isPairingBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_PAIRING_BONUS", isPairingBlocked)}
															name="isPairingBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Join Campaign blocked:<br /><small className='text-muted'>block user from joining any campaigns</small></>}
													control={
														<Switch
															checked={isJoinCampaignBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_JOIN_CAMPAIGN", isJoinCampaignBlocked)}
															name="isJoinCampaignBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from IL Points blocked:<br /><small className='text-muted'>block user from transferring from his IL Points Wallet</small></>}
													control={
														<Switch
															checked={isTransferPurchaseBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_PURCHASE_WALLET", isTransferPurchaseBlocked)}
															name="isTransferPurchaseBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Broker Points blocked:<br /><small className='text-muted'>block user from transferring from his Broker Points Wallet</small></>}
													control={
														<Switch
															checked={isTransferCreditBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_CREDIT_WALLET", isTransferCreditBlocked)}
															name="isTransferCreditBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Withdrawal blocked:<br /><small className='text-muted'>block user from transferring from his Withdrawal Wallet</small></>}
													control={
														<Switch
															checked={isTransferWithdrawalBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_WITHDRAWAL_WALLET", isTransferWithdrawalBlocked)}
															name="isTransferWithdrawalBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Deposit blocked:<br /><small className='text-muted'>block user from transferring from his Deposit Wallet</small></>}
													control={
														<Switch
															checked={isTransferDepositBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_DEPOSIT_WALLET", isTransferDepositBlocked)}
															name="isTransferDepositBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Profit blocked:<br /><small className='text-muted'>block user from transferring from his Profit Wallet</small></>}
													control={
														<Switch
															checked={isTransferProfitBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_PROFIT_WALLET", isTransferProfitBlocked)}
															name="isTransferProfitBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Bonus blocked:<br /><small className='text-muted'>block user from transferring from his Bonus Wallet</small></>}
													control={
														<Switch
															checked={isTransferBonusBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_BONUS_WALLET", isTransferBonusBlocked)}
															name="isTransferBonusBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from Credit blocked:<br /><small className='text-muted'>block user from transferring from his Credit Wallet</small></>}
													control={
														<Switch
															checked={isTransferCreditNewBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_CREDIT_NEW_WALLET", isTransferCreditNewBlocked)}
															name="isTransferCreditNewBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Transfer from ILT blocked:<br /><small className='text-muted'>block user from transferring from his ILT Wallet</small></>}
													control={
														<Switch
															checked={isTransferTokenBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_TRANSFER_FROM_TOKEN_WALLET", isTransferTokenBlocked)}
															name="isTransferTokenBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
										<div className="row">
											<div className="col-12 mt-2">
												<FormControlLabel
													labelPlacement="start"
													label={<>User Convert from ILT blocked:<br /><small className='text-muted'>block user from converting from his ILT Wallet</small></>}
													control={
														<Switch
															checked={isConvertTokenBlocked}
															onChange={(e) => handleToggleUserBlockSettings(e, "USER_BLOCK_CONVERT_FROM_TOKEN_WALLET", isConvertTokenBlocked)}
															name="isConvertTokenBlocked"
															color="primary"
														/>
													}
												/>
												<Divider />
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
										<button type="submit" className="btn btn-primary">Update User</button>
									</div>
								</form>

							</div>
						</Dialog>
					</>}
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					data-toggle="modal"
					data-target={`#usermodal-view-${id}`}
				>
					<i className="fa fa-eye" />
				</button>
				<div className="modal fade" id={`usermodal-view-${id}`} tabIndex="-1" role="dialog" aria-hidden="true">
					<div className="modal-dialog" >
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">View {username}</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p>
									<span className="text-bold">Username: </span> {username} <br />
									<span className="text-bold">Email: </span> {email} <br />
									<span className="text-bold">Verified: </span> {emailVerified ? <span className="text-success"><i className="fa fa-check" /></span> : <span className="text-danger"><i className="fa fa-times" /></span>} <br />
									<span className="text-bold">Ranking: </span> {ranking} <br />
									<span className="text-bold">Ranking Name: </span> <UserRanking ranking={ranking} /> <br />
									<span className="text-bold">Sponsor: </span> {referrerUsername} <br />
									<span className="text-bold">SponsorUID: </span> {referralUID} <br />
									<span className="text-bold">Status: </span> {status} <br />
									<span className="text-bold">Withdrawal Locked: </span> {withdrawalBlocked ? <i className="text-danger fa fa-lock" /> : <i className="text-success fa fa-unlock" />}<br />
									<span className="text-bold">Referrals with no Placement: </span> {notPlaced}<br />
								</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
			</td>
		</tr >
	)
}

const Users = (props) => {
	const [search, setSearch] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [error, setError] = useState('');
	const [params, setParams] = useState({ page: 1, limit: 10 })
	const [searchLeader, setSearchLeader] = useState('');
	const [searchEmail, setSearchEmail] = useState('');
	const [searchRank, setSearchRank] = useState('ALL');
	const [searchStatus, setSearchStatus] = useState('ALL');
	const [fromNetwork, setfromNetwork] = useState('');
	const [excludeNetwork, setexcludeNetwork] = useState([]);

	const dispatch = useDispatch();
	const users = useSelector(({ site }) => site.users.report)
	const meta = useSelector(({ site }) => site.users.meta)
	const allLeaders = useSelector(({ site }) => site.allLeaders)
	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	const handlePageChange = (page) =>
		setParams({ ...params, page })

	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	useEffect(() => {
		handleSearchUser()
	}, [params])

	const searchUsers = (e) => {
		e.preventDefault();
		const hasError = validateFilter();

		if (!hasError) {
			handleSearchUser()
			setError('')
		} else {
			setError(hasError)
		}
	}

	const validateFilter = () => {
		if (isBefore(new Date(endDate), new Date(startDate))) {
			return ('Invalid End Date');
		} else {
			return ('');
		}
	}

	const handleSearchUser = () => {
		dispatch(getSiteUsersSearch({
			search,
			startDate,
			endDate,
			leader: searchLeader,
			email: searchEmail,
			fromNetwork,
			excludeNetwork,
			rank: searchRank === 'ALL' ? undefined : searchRank,
			status: searchStatus === 'ALL' ? undefined : searchStatus,
			...params
		}))
	}

	const handleAddExcludeNetwork = () => {
		setexcludeNetwork([...excludeNetwork, ""])
	}
	const handleSetExcludeNetwork = (data, id) => {
		setexcludeNetwork([
			...excludeNetwork.slice(0, id),
			data,
			...excludeNetwork.slice(id + 1)
		])
	}
	const handleRemoveExcludeNetwork = (id) => {
		setexcludeNetwork([
			...excludeNetwork.slice(0, id),
			...excludeNetwork.slice(id + 1)
		])
	}
	const renderError = () => {
		return (error ? <p className="text-danger  mb-2 mt-2">{error}</p> : <p className="mb-2 mt-2">&nbsp;</p>)
	}

	const handleResetFilters = () => {
		setSearchStatus("ALL")
		setSearchRank("ALL")
		setSearchLeader("")
		setSearch("")
		setSearchEmail("")
		setParams({ page: 1, limit: 10 })
		setError('')
	}

	const handleExportData = () => {
		dispatch(exportUsers())
	}

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">

					<div className="card">
						<div className="card-body">
							<form className="row" onSubmit={searchUsers}>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Username</label>
									<div className="input-group">
										<input
											type="text"
											value={search}
											onChange={(e) => setSearch(e.target.value)} className="form-control"
											placeholder="Search here..."
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Email</label>
									<div className="input-group">
										<input
											type="text"
											value={searchEmail}
											onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
											placeholder="Search email"
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Start Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>End Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Leader</label>
										<div className="input-group">
											<select
												disabled={authUser?.role == 2 && authUser.limitLeader}
												className="form-control custom-select"
												value={searchLeader}
												onChange={(e) => setSearchLeader(e.target.value)}>
												<option value="">All Leaders</option>
												{allLeaders && allLeaders.length > 0 ?
													allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
													: <></>
												}
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Status</label>
										<div className="input-group">
											<select
												className="form-control custom-select"
												value={searchStatus}
												onChange={(e) => setSearchStatus(e.target.value)}>
												<option value="ALL">All</option>
												<option value="ACTIVE">ACTIVE</option>
												<option value="SUSPENDED">SUSPENDED</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Rank</label>
										<div className="input-group">
											<select
												className="form-control custom-select"
												value={searchRank}
												onChange={(e) => setSearchRank(e.target.value)}>
												<option value="ALL">All</option>
												<option value="0">{userRankingString("0")}</option>
												<option value="1">{userRankingString("1")}</option>
												<option value="2">{userRankingString("2")}</option>
												<option value="3">{userRankingString("3")}</option>
												<option value="4">{userRankingString("4")}</option>
												<option value="5">{userRankingString("5")}</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-12" />
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label className="mt-1">From Network</label>
									<div className="input-group">
										<input
											disabled={authUser?.role == 2 && authUser.limitLeader}
											type="text"
											className="form-control"
											value={fromNetwork}
											onChange={(e) => setfromNetwork(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label className="mt-1">Exclude Network</label>
									{excludeNetwork.map((data, i) =>
										<div className="input-group mb-1" key={i}>
											<input
												required
												type="text"
												className="form-control"
												value={data}
												onChange={(e) => handleSetExcludeNetwork(e.target.value, i)}
											/>
											<button
												type="button"
												className="btn btn-icon"
												title="Delete"
												onClick={() => handleRemoveExcludeNetwork(i)}
											>
												<i className="text-danger fa fa-trash" />
											</button>
										</div>
									)}
									<br />
									<button
										type="button"
										className="btn btn-secondary"
										title="Add"
										onClick={() => handleAddExcludeNetwork()}
									>
										<i className="text-success fa fa-plus" /> Add
									</button>
								</div>
								<div className='col-12'>
									{renderError()}
									<button type="submit" className="btn btn-primary">
										<i className="fe fe-search mr-2" />
										Search
									</button>
									<button type="button" className="btn btn-secondary ml-2" onClick={handleResetFilters}>
										Reset
									</button>
									<button type="button" className="btn btn-outline-secondary ml-2" onClick={handleExportData}>
										Export Excel
									</button>
								</div>
							</form>

						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">User List</h3>
						{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
								<thead>
									<tr>
										<th>ID</th>
										<th>Name</th>
										<th>Role</th>
										<th>Created Date</th>
										<th>Nationality</th>
										<th>Referrals Not Placed</th>
										<th>Rank</th>
										<th>Status</th>
										<th>Leader</th>
										<th className="w150">Action</th>
									</tr>
								</thead>
								<tbody>
									{users && Array.isArray(users) && users.length > 0 ? users.map(user => <UserRow key={user.id} {...user} authRole={authUser.role || 0} />) : <tr><td colSpan={10}><p className="text-center">No users found</p></td></tr>}
									{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

								</tbody>
							</table>
							<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default Users;