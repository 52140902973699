// import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  WALLET_SET_DATA,
  WALLET_SET_SEARCH_TERM,
  WALLET_SET_ADJUST_DATA,
  WALLET_SET_ADJUST_SEARCH_TERM,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setWallets = (payload) => {
  return {
    type: WALLET_SET_DATA,
    payload,
  };
};

export const setWalletsSearchTerm = (payload) => {
  return {
    type: WALLET_SET_SEARCH_TERM,
    payload,
  };
};

export const setWalletsAdjustData = (payload) => {
  return {
    type: WALLET_SET_ADJUST_DATA,
    payload,
  };
};

export const setWalletsAdjustSearchTerm = (payload) => {
  return {
    type: WALLET_SET_ADJUST_SEARCH_TERM,
    payload,
  };
};

export const getWalletSearch = ({
  search = '',
  type = '',
  email = '',
  leader = '',
  page = 1,
  limit = 10
}) => {

  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser
    const payload = {
      search,
      type,
      email,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
    }
    dispatch(fetchStart());
    dispatch(setWalletsSearchTerm({ ...payload, page, limit }));
    axios.post(`/crackers/adm/users/walletsearch?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setWallets({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const adjustWalletBalance = ({
  userID,
  action,
  amount,
  walletType,
  remarks,
}) => {
  const payload = {
    userID,
    action,
    amount,
    walletType,
    remarks,
  }
  return (dispatch, getStore) => {
    const searchTerm = getStore().wallet.walletSearch;
    dispatch(fetchStart());
    axios.post('/crackers/adm/users/adjustwallet', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Wallet Adjusted'));
        dispatch(getWalletSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getAdminAdjust = ({
  search = "",
  leader = "",
  email = "",
  startDate = null,
  endDate = null,
  type = '',
  page = 1,
  limit = 10,
}) => {
  const payload = { startDate, endDate, type, search, leader, email }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setWalletsAdjustSearchTerm(payload))
    axios.post(`/crackers/adm/users/adjusthistory?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setWalletsAdjustData({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
