import {
	SIGNOUT_USER_SUCCESS,
	SYSTEM_SETTING_SET_DATA,
	SYSTEM_SETTING_SET_SEARCH_TERM,
	SYSTEM_SETTING_TOKEN_SET_DATA,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	tickets: reportInitialState,
	tokens: reportInitialState,
	ticketTerms: {},

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case SYSTEM_SETTING_SET_DATA: {
			return {
				...state,
				tickets: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case SYSTEM_SETTING_TOKEN_SET_DATA: {
			return {
				...state,
				tokens: {
					report: action.payload.report,
					meta: action.payload.meta,
				}
			};
		}
		case SYSTEM_SETTING_SET_SEARCH_TERM: {
			return {
				...state,
				ticketTerms: action.payload,
			};
		}
		default:
			return state;
	}
}
