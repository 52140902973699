// import { push } from 'connected-react-router';
import axios, { getFormData } from '../util/api';
import {
  ANNOUNCEMENT_SET_DATA,
  ANNOUNCEMENT_SET_SEARCH_TERM,
  DOWNLOADS_SET_DATA,
  DOWNLOADS_SET_SEARCH_TERM,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setAnnouncements = (payload) => {
  return {
    type: ANNOUNCEMENT_SET_DATA,
    payload,
  };
};

export const setAnnouncementsSearchTerm = (payload) => {
  return {
    type: ANNOUNCEMENT_SET_SEARCH_TERM,
    payload,
  };
};

export const setDownloads = (payload) => {
  return {
    type: DOWNLOADS_SET_DATA,
    payload,
  };
};

export const setDownloadsSearchTerm = (payload) => {
  return {
    type: DOWNLOADS_SET_SEARCH_TERM,
    payload,
  };
};

export const getAnnouncementSearch = ({
  search = '',
  status = '',
  leaderSearch = '',
  startDate = '',
  endDate = '',
  page = 1,
  limit = 10
}) => {
  const payload = { search, status, leaderSearch, startDate, endDate }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setAnnouncementsSearchTerm({ ...payload, page, limit }));
    axios.post(`/crackers/adm/announcement/get?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(setAnnouncements({
          report: data.report,
          meta: data.meta
        }));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createAnnouncement = ({
  title,
  description,
  leader,
  type,
  status,
  startDate,
  endDate,
  image,
}) => {
  const payload = {
    title,
    description,
    leader,
    type,
    status,
    startDate,
    endDate,
    image,
  }
  const formattedData = getFormData(payload)

  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().announcement.search;
    axios.post(`/crackers/adm/announcement/create`, formattedData).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Created'));
        dispatch(getAnnouncementSearch({ search: searchTerm, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const updateAnnouncement = ({
  id,
  title,
  description,
  status,
  startDate,
  endDate,
  leader,
}) => {
  const payload = {
    id,
    title,
    description,
    status,
    startDate,
    endDate,
    leader,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().announcement.search;
    axios.post(`/crackers/adm/announcement/update`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Updated'));
        dispatch(getAnnouncementSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const deleteAnnouncement = ({ id }) => {
  const payload = { id }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().announcement.search;
    axios.post(`/crackers/adm/announcement/delete`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Deleted'));
        dispatch(getAnnouncementSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const getDownloadSearch = ({
  search = '',
  status = '',
  leaderSearch = '',
  startDate = '',
  endDate = '',
  page = 1,
  limit = 10
}) => {
  const payload = { search, status, leaderSearch, startDate, endDate }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setDownloadsSearchTerm({ ...payload, page, limit }));
    axios.post(`/crackers/adm/announcement/getdownload?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(setDownloads({
          report: data.report,
          meta: data.meta
        }));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createDownloads = (data) => {
  const formattedData = getFormData(data)

  return (dispatch) => {
    dispatch(fetchStart());
    axios.post(`/crackers/adm/announcement/createreport`, formattedData).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Created'));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const updateDownloadReport = ({
  id,
  title,
  description,
  status,
  startDate,
  endDate,
  leader,
}) => {
  const payload = {
    id,
    title,
    description,
    status,
    startDate,
    endDate,
    leader,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().announcement.searchDownload;
    axios.post(`/crackers/adm/announcement/updatedownload`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Updated'));
        dispatch(getDownloadSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const deleteDownloadReport = ({ id }) => {
  const payload = { id }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().announcement.searchDownload;
    axios.post(`/crackers/adm/announcement/deletedownload`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Deleted'));
        dispatch(getDownloadSearch(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
