import {
	SIGNOUT_USER_SUCCESS,
	SUPPORT_SET_TICKETS,
	SUPPORT_SET_TICKETS_TERMS,
	SUPPORT_SET_CHAT_CONVERSATIONS,
	SUPPORT_SET_CHAT_SUPPORTID,
	SUPPORT_SET_CHAT_TICKET_DETAILS,
} from "../constants/actionTypes";

const INIT_STATE = {
	searchTerms: {},
	tickets: [],
	chatConversations: [],
	chatSupportID: null,
	chatTicketDetails: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case SUPPORT_SET_TICKETS: {
			return {
				...state,
				tickets: action.payload,
			};
		}
		case SUPPORT_SET_TICKETS_TERMS: {
			return {
				...state,
				searchTerms: action.payload,
			};
		}
		case SUPPORT_SET_CHAT_CONVERSATIONS: {
			const conversations = action.payload && action.payload.conversationData ? action.payload.conversationData : []
			return {
				...state,
				chatConversations: conversations,
			};
		}
		case SUPPORT_SET_CHAT_SUPPORTID: {
			return {
				...state,
				chatSupportID: action.payload,
			};
		}
		case SUPPORT_SET_CHAT_TICKET_DETAILS: {
			return {
				...state,
				chatTicketDetails: action.payload,
			};
		}
		default:
			return state;
	}
}
