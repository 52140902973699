import React, { useState } from 'react';

import TicketSettings from './TicketSettings';
import TokenSettings from './TokenSettings';

const SystemSettings = (props) => {
    const [tab, setTab] = useState(0);

    const handleSetTab = (e, index) => {
        e.preventDefault();
        setTab(index)
    }

    return (

        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Ticket Setting</a></li>
                            <li className="nav-item"><a className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 1)}>$ILT Price Setting</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {tab === 0 && <TicketSettings />}
            {tab === 1 && <TokenSettings />}
        </div>
    )

}

export default SystemSettings;