import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { searchUserPinPackages } from '../../../actions/packagesAction';
import { formatDateTime } from '../../../util/date';
import { formatCurrency } from '../../../util/money';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';

const ItemRow = ({
	id,
	pack,
	value,
	maxProfit,
	multiplier,
	profit,
	user,
	status,
	createdAt,
	updatedAt,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{user?.username}<br /><small>{user?.email}</small></h6>
			</td>
			<td>
				{pack.name}
			</td>
			<td>{value ? formatCurrency(value) : '-'}</td>
			<td>{multiplier ? multiplier : '-'}</td>
			<td>{profit} / {maxProfit}</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : '-'}</td>
			<td>
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isDialogOpen} onClose={handleClose}>

					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View Pin Package</h5>
							<button type="button" className="close" onClick={handleClose}>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<span className="text-bold">Username: </span>{user.username}<br />
								<span className="text-bold">Email: </span>{user.email}<br />
								<span className="text-bold">Package Name: </span>{pack.name}<br />
								<span className="text-bold">Value: </span>{value}<br />
								<span className="text-bold">Multiplier: </span>{multiplier}<br />
								<span className="text-bold">Profit: </span>{profit}<br />
								<span className="text-bold">Max Profit: </span>{maxProfit}<br />
								<span className="text-bold">Status: </span>{status ? <StatusBadge status={status} /> : '-'}<br />
								<span className="text-bold">Purchase Date: </span>{createdAt ? formatDateTime(createdAt) : '-'}<br />
								<span className="text-bold">Last Update Date: </span>{updatedAt ? formatDateTime(updatedAt) : '-'}<br />

							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
						</div>
					</div>
				</Dialog>


			</td>
		</tr >
	)
}

const UserPinPackages = (props) => {
	const [search, setSearch] = useState('');
	const [searchPackName, setSearchPackName] = useState('');
	const [searchStatus, setSearchStatus] = useState('');
	const [searchEmail, setSearchEmail] = useState('');
	const [searchLeader, setSearchLeader] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ packages }) => packages.userpins.report)
	const meta = useSelector(({ packages }) => packages.userpins.meta)

	const fixNavbar = useSelector(({ settings }) => settings.isFixNavbar)
	const { authUser } = useSelector(({ user }) => user);
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		handleSearchUserPinPack()
	}, [params])


	const searchUsers = (e) => {
		e.preventDefault();
		handleSearchUserPinPack()
	}
	const handleResetFilters = () => {
		setSearch("")
		setSearchPackName("")
		setSearchStatus("")
		setSearchLeader("")
		setSearchEmail("")
		setStartDate("")
		setEndDate("")
		setParams({ page: 1, limit: 10 })
	}
	const handleSearchUserPinPack = () => {
		console.log('searchLeader',searchLeader)
		dispatch(searchUserPinPackages({
			search,
			searchPackName: searchPackName,
			status: searchStatus,
			leader: searchLeader,
			email: searchEmail,
			startDate,
			endDate,
			...params
		}))
	}
	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	return (
		<div>
			<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center">
						<ul className="nav nav-tabs page-header-tab">
							<li className="nav-item">
								<a
									className="nav-link active"
									id="package-tab"
									data-toggle="tab"
									href="#user-pin-package-list"
								>
									User Pin Packages
								</a>
							</li>
						</ul>
					</div>
					<div className="card">
						<div className="card-body">
							<form className="row" onSubmit={searchUsers}>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Username</label>
									<div className="input-group">
										<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search username..." />
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Email</label>
									<div className="input-group">
										<input
											type="text"
											value={searchEmail}
											onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
											placeholder="Search email"
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Package Name</label>
									<div className="input-group">
										<input
											type="text"
											value={searchPackName}
											onChange={(e) => setSearchPackName(e.target.value)} className="form-control"
											placeholder="Search package name"
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Status</label>
									<select className="form-control custom-select" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
										<option value="">ALL</option>
										<option value="ACTIVE">ACTIVE</option>
										<option value="COMPLETED">COMPLETED</option>
									</select>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Leader</label>
										<div className="input-group">
											<select
												className="form-control custom-select"
												disabled={authUser?.role == 2 && authUser.limitLeader}
												value={searchLeader}
												onChange={(e) => setSearchLeader(e.target.value)}>
												<option value="">All Leaders</option>
												{allLeaders && allLeaders.length > 0 ?
													allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
													: <></>
												}
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Created Start Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Created End Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-12">&nbsp;</div>
								<div className="col-md-2 col-sm-6">
									<button type="submit" className="btn btn-primary btn-block">
										<i className="fe fe-search mr-2" />
										Search
									</button>
								</div>
								<div className="col-md-2 col-sm-6">
									<button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
										Reset
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div className="tab-pane fade show active" id="user-pin-package-list" role="tabpanel">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">User Tickets</h3>
									{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
											<thead>
												<tr>
													<th>ID</th>
													<th>Username</th>
													<th>Package Name</th>
													<th>Value</th>
													<th>Multiplier</th>
													<th>Revenue / NAV Profit</th>
													<th>Purchase Date</th>
													<th>Status</th>
													<th className="w100">Action</th>
												</tr>
											</thead>
											<tbody>
												{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={9}><p className="text-center">No data found</p></td></tr>}
												{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

											</tbody>
										</table>
										<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default UserPinPackages;