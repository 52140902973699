import { push } from 'connected-react-router';
import axios from '../util/api';
import {
  ADMIN_SET_USERS_SEARCH,
  ADMIN_SET_USERS_SEARCH_TERM,
} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';

export const setAdminUsersSearch = (payload) => {
  return {
    type: ADMIN_SET_USERS_SEARCH,
    payload,
  };
};

export const setAdminSearchTerm = (payload) => {
  return {
    type: ADMIN_SET_USERS_SEARCH_TERM,
    payload,
  };
};

export const searchAdminUsers = ({ search = "", page = 1, limit = 10 }) => {
  const payload = { search }
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(setAdminSearchTerm(search));
    axios.post(`/crackers/adm/admin/search?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setAdminUsersSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const editAdminUsers = ({
  id,
  email,
  status,
  role,
  leader
}) => {
  const payload = {
    id,
    email,
    status,
    role,
    leader,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().admin.searchTerm;
    axios.post('/crackers/adm/admin/editadminuser', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Admin user updated'));
        dispatch(searchAdminUsers(searchTerm));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const addAdminUsers = ({
  username,
  email,
  password,
  role,
  leader,
}) => {
  const payload = {
    username,
    email,
    password,
    role,
    leader,
  }
  return (dispatch, getStore) => {
    const searchTerm = getStore().admin.searchTerm
    dispatch(fetchStart());
    axios.post('/crackers/adm/admin/createadmin', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Admin user created'));
        dispatch(searchAdminUsers({ search: searchTerm }))
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
