import React, { useState, useCallback, useEffect } from 'react'

const styles = {
    lightbox: {
        position: 'fixed',
        zIndex: 999,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        visibility: 'hidden',
        opacity: 0,
        transition: 'opacity ease 0.4s',
        visibility: 'visible',
        opacity: 1,
    },
    showImage: {
        width: '100vw',
        height: '100vh',
        objectFit: 'contain',
    }
}

const Image = ({ zoom, ...rest }) => {
    const [click, setClick] = useState(false)

    const setFlag = () => {
        setClick(true)
    }

    const unsetFlag = () => {
        setClick(false)
    }

    if (!zoom) return <img {...rest} />
    else
        return (
            <>
                {click ? (
                    <div onClick={unsetFlag} style={styles.lightbox} className='relative'>
                        <img {...rest} style={styles.showImage}></img>
                    </div>
                ) : (
                    <img {...rest} onClick={setFlag}></img>
                )}
            </>
        )
}

export default Image
