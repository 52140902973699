export const KYC_STATUSES = [
    {
        label: 'Approved',
        value: 'APPROVE',
    },
    {
        label: 'Pending',
        value: 'PENDING',
    },
    {
        label: 'Rejected',
        value: 'REJECT',
    },
    {
        label: 'Not Started',
        value: 'NOT_STARTED',
    },
]

export const WALLETS = {
    WITHDRAWAL_WALLET: 'WITHDRAWAL_WALLET',
    DEPOSIT_WALLET: 'DEPOSIT_WALLET',
    PROFIT_WALLET: 'PROFIT_WALLET',
    BONUS_WALLET: 'BONUS_WALLET',
    PURCHASE_WALLET: 'PURCHASE_WALLET',
    CREDIT_WALLET: 'CREDIT_WALLET',
    CREDIT_NEW_WALLET: 'CREDIT_NEW_WALLET',
    TOKEN_WALLET: 'TOKEN_WALLET',
    CAMPAIGN_WALLET: 'CAMPAIGN_WALLET',
}

export const WALLET_TYPES = [
    {
        label: 'Withdrawal',
        value: 'WITHDRAWAL_WALLET',
    },
    {
        label: 'Credit',
        value: 'CREDIT_WALLET',
    },
    {
        label: 'Profit',
        value: 'PROFIT_WALLET',
    },
    {
        label: 'Bonus',
        value: 'BONUS_WALLET',
    },
    {
        label: 'Deposit',
        value: 'DEPOSIT_WALLET',
    },
    {
        label: 'Purchase',
        value: 'PURCHASE_WALLET',
    },
    {
        label: '$ILT',
        value: 'TOKEN_WALLET',
    },
    {
        label: '$CT',
        value: 'CAMPAIGN_WALLET',
    },
]

export const formatSystemSettingType = (key) => {
    switch (key) {
        case 'BUY_TICKET_PERCENTAGE_WITHDRAWAL_WALLET':
            return 'Buy Percentage For Withdrawal Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_DEPOSIT_WALLET':
            return 'Buy Percentage For Deposit Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_PROFIT_WALLET':
            return 'Buy Percentage For Profit Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_BONUS_WALLET':
            return 'Buy Percentage For Bonus Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_PURCHASE_WALLET':
            return 'Buy Percentage For IL Points Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_CREDIT_WALLET':
            return 'Buy Percentage For Broker Points Wallet'
            break;
        case 'BUY_TICKET_PERCENTAGE_CREDIT_NEW_WALLET':
            return 'Buy Percentage For Credit Wallet'
            break;
        case 'TOKEN_PRICE_ILT':
            return '$ILT Price'
            break;
        case 'SETTING_PAY_CAMPAIGN_TOKEN':
            return 'Campaign Payout in Campaign Token'
            break;
        case 'SETTING_PAY_BONUS_IN_CAMPAIGN_TOKEN':
            return 'Bonus Payout in Campaign Token'
        case 'PURCHASE_ILT_WITH_POINTS_PERCENTAGE_DEPOSIT_WALLET':
            return 'Points Wallet Purchase $ILT with Deposit Wallet Percentage'
        case 'PURCHASE_ILT_WITH_POINTS_PERCENTAGE_PROFIT_WALLET':
            return 'Points Wallet Purchase $ILT with Profit Wallet Percentage'
        case 'PURCHASE_ILT_WITH_POINTS_PERCENTAGE_BONUS_WALLET':
            return 'Points Wallet Purchase $ILT with Bonus Wallet Percentage'
            break;
        default:
            return key
            break;
    }
}