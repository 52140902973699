import React, { useEffect, useState } from 'react';

import Binary from './Binary';

const Network = (props) => {
    const [tab, setTab] = useState(0);

    const handleSetTab = (e, index) => {
        e.preventDefault();
        setTab(index)
    }

    return (

        <div>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs page-header-tab">
                            <li className="nav-item"><a className={`nav-link ${tab === 0 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 0)}>Binary</a></li>
                            {/* <li className="nav-item"><a className={`nav-link ${tab === 1 ? "active" : ""}`} onClick={(e) => handleSetTab(e, 1)}>Referrals</a></li> */}
                            {/* <li className="nav-item"><a className="nav-link" id="Accounts-tab" data-toggle="tab" href="#Accounts-Expenses">Expenses</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            {tab === 0 && <Binary />}
            {/* {tab === 1 && <AdjustWallets />} */}

        </div>

    )

}

export default Network;