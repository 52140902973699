import {
	SIGNOUT_USER_SUCCESS,
	WITHDRAWAL_SET_DATA,
	WITHDRAWAL_SET_FILTER_TERMS,
	WITHDRAWAL_SET_SUMMARY,
} from "../constants/actionTypes";

const reportInitialState = {
	report: [],
	meta: { itemCount: 0, page: 1, pageCount: 1 },
}

const INIT_STATE = {
	filterTerms: {},
	withdrawals: reportInitialState,
	summary: {},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SIGNOUT_USER_SUCCESS: {
			return INIT_STATE
		}

		case WITHDRAWAL_SET_DATA: {
			return {
				...state,
				withdrawals: {
					report: action.payload.report,
					meta: action.payload.meta,
				},
			};
		}
		case WITHDRAWAL_SET_FILTER_TERMS: {
			return {
				...state,
				filterTerms: action.payload,
			};
		}
		case WITHDRAWAL_SET_SUMMARY: {
			return {
				...state,
				summary: action.payload,
			};
		}

		default:
			return state;
	}
}
