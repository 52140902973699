import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { adjustWalletBalance, getWalletSearch } from '../../../actions/walletAction';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { WALLETS, WALLET_TYPES } from '../../../constants/labels';
import formExcel from '../../../assets/files/sampleAdjustWalletBatch.xlsx';
import { createdBatchAdjustWallet } from '../../../actions/siteAction';

const BatchAdjustWallets = (props) => {
    const [upload, setUpload] = useState(null);

    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const files = e.target.files
        setUpload(files)
    }
    const validateFiles = () => {
        if (!upload) {
            return ('Upload required File');
        } else {
            return ('');
        }
    }
    const submitBatchAdjust = (e) => {
        e.preventDefault();
        const hasError = validateFiles();

        if (!hasError) {
            dispatch(createdBatchAdjustWallet({
                file: upload[0]
            }))
            setError('')

        } else {
            setError(hasError)
        }
    }

    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className="row clearfix">

                            <div className="col-12">
                                <h6>Batch Adjust Wallet with Excel Upload</h6>
                                <hr className="mt-4" />
                                <form onSubmit={submitBatchAdjust}>
                                    <div className="row clearfix">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="card">
                                                <div className="card-header" style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'flex-start' }}>
                                                    <h3 className="card-title">
                                                        Upload adjust wallet data<small>Upload only xlsx files or csv files</small>
                                                    </h3>
                                                    <br />
                                                    <ul>
                                                        <li>{"Column A - Username"}</li>
                                                        <li>{"Column B - Email"}</li>
                                                        <li>{"Column C - Amount"} (Must be positive)</li>
                                                        <li>{"Column D - Wallet Type"} (Check below for type mapping)</li>
                                                        <li>{"Column E - Action"} (Must be either add or minus)</li>
                                                        <li></li>
                                                        <li>
                                                            First row is headers and will be ignored
                                                        </li>
                                                        <li>{"Max File Size- 2 MB"}</li>
                                                        <li>
                                                        </li>
                                                    </ul>
                                                    Wallet Mapping
                                                    <ul>
                                                        <li>Withdrawal wallet: {WALLETS.WITHDRAWAL_WALLET}</li>
                                                        <li>Deposit Wallet: {WALLETS.DEPOSIT_WALLET}</li>
                                                        <li>Bonus Wallet: {WALLETS.BONUS_WALLET}</li>
                                                        <li>Profit Wallet: {WALLETS.PROFIT_WALLET}</li>
                                                        <li>Broker Points: {WALLETS.CREDIT_WALLET}</li>
                                                        <li>IL Points: {WALLETS.PURCHASE_WALLET}</li>
                                                        <li>Credit: {WALLETS.CREDIT_NEW_WALLET}</li>
                                                        <li>$ILT: {WALLETS.TOKEN_WALLET}</li>
                                                    </ul>

                                                    <p>Example row: <br />
                                                        boss | boss@test.com | 1000 | DEPOSIT_WALLET | add<br />
                                                        <small>this will add 1000 to the deposit wallet of the user with the username boss </small>
                                                    </p>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-1">You may download the template form:</div>
                                                    <div className="mb-1">
                                                        <div>
                                                            <a className='btn btn-primary' href={formExcel} download="sample.xlsx">
                                                                Download Upload Form in XLSX
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <input
                                                        onChange={handleFileChange}
                                                        required
                                                        name="file"
                                                        type="file"
                                                        className="dropify"
                                                        data-allowed-file-extensions="xlsx csv"
                                                        data-max-file-size="2M"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">{renderError()}</div>
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        Add
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BatchAdjustWallets;