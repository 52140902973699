import Dialog from '@material-ui/core/Dialog';
import isBefore from 'date-fns/isBefore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { exportUsersFinances, getAllLeaders, getSiteUsersFinancesSearch } from '../../../actions/siteAction';
import { formatCurrency } from '../../../util/money';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';

const UserRanking = ({ ranking }) => {
	let type;
	switch (ranking) {
		case "0":
			type = <span className="tag tag-default">Client</span>
			break;
		case "1":
			type = <span className="tag tag-default">Deputy Supervisor</span>
			break;
		case "2":
			type = <span className="tag tag-default">Supervisor</span>
			break;
		case "3":
			type = <span className="tag tag-default">Deputy Manager</span>
			break;
		case "4":
			type = <span className="tag tag-success">Manager</span>
			break;
		case "5":
			type = <span className="tag tag-success">Deputy Director</span>
			break;
		case "6":
			type = <span className="tag tag-info">Director</span>
			break;
		case "7":
			type = <span className="tag tag-info">Senior Director</span>
			break;
		case "8":
			type = <span className="tag tag-danger">Managing Director</span>
			break;
		default:
			type = <span className="tag tag-default">Client</span>
			break;
	}
	return type;
}

export const userRankingString = (ranking) => {
	let type;
	switch (ranking) {
		case "0":
			type = "No Rank"
			break;
		case "1":
			type = "VIP"
			break;
		case "2":
			type = "VVIP"
			break;
		case "3":
			type = "Achiever"
			break;
		case "4":
			type = "Director"
			break;
		case "5":
			type = "Group Director"
			break;
		default:
			type = ranking
			break;
	}
	return type;
}

const UserRow = ({
	id,
	username,
	email,
	ranking,
	leader,
	deposits,
	withdrawals,
	totalTickets,
	totalActiveTickets,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const dispatch = useDispatch();
	const handleClickOpen = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>
				<h6 className="mb-0">{username}</h6>
				<span>{email}</span>
			</td>
			<td>{ranking ? userRankingString(ranking) : '-'}</td>
			<td>{leader}</td>
			<td>{deposits ? formatCurrency(deposits) : '-'}</td>
			<td>{withdrawals ? formatCurrency(withdrawals) : '-'}</td>
			<td>{totalTickets ? formatCurrency(totalTickets) : '-'}</td>
			<td>{totalActiveTickets ? formatCurrency(totalActiveTickets) : '-'}</td>
			<td>
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isDialogOpen} onClose={handleClose}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View {username}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<span className="text-bold">Username: </span> {username} <br />
								<span className="text-bold">Email: </span> {email} <br />
								<span className="text-bold">Ranking: </span> {ranking} <br />
								<span className="text-bold">Ranking Name: </span> <UserRanking ranking={ranking} /> <br />
								<span className="text-bold">Deposits: </span> {deposits ? formatCurrency(deposits) : '-'} <br />
								<span className="text-bold">Withdrawals: </span> {withdrawals ? formatCurrency(withdrawals) : '-'} <br />
								<span className="text-bold">Total Tickets: </span> {totalTickets ? formatCurrency(totalTickets) : '-'} <br />
								<span className="text-bold">Total Active Tickets: </span> {totalActiveTickets ? formatCurrency(totalActiveTickets) : '-'} <br />
							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
						</div>
					</div>
				</Dialog>
			</td >
		</tr >
	)
}

const UserFinances = (props) => {
	const [search, setSearch] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [error, setError] = useState('');
	const [params, setParams] = useState({ page: 1, limit: 10 })
	const [searchLeader, setSearchLeader] = useState('');
	const [searchEmail, setSearchEmail] = useState('');
	const [searchRank, setSearchRank] = useState('ALL');
	const [searchStatus, setSearchStatus] = useState('ALL');

	const dispatch = useDispatch();
	const users = useSelector(({ site }) => site.usersFinances.report)
	const meta = useSelector(({ site }) => site.usersFinances.meta)
	const allLeaders = useSelector(({ site }) => site.allLeaders)
	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	const handlePageChange = (page) =>
		setParams({ ...params, page })

	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	useEffect(() => {
		handleSearchUser()
	}, [params])

	const searchUsers = (e) => {
		e.preventDefault();
		const hasError = validateFilter();

		if (!hasError) {
			handleSearchUser()
			setError('')
		} else {
			setError(hasError)
		}
	}

	const validateFilter = () => {
		if (isBefore(new Date(endDate), new Date(startDate))) {
			return ('Invalid End Date');
		} else {
			return ('');
		}
	}

	const handleSearchUser = () => {
		dispatch(getSiteUsersFinancesSearch({
			search,
			startDate,
			endDate,
			leader: searchLeader,
			email: searchEmail,
			rank: searchRank === 'ALL' ? undefined : searchRank,
			status: searchStatus === 'ALL' ? undefined : searchStatus,
			...params
		}))
	}

	const renderError = () => {
		return (error ? <p className="text-danger  mb-2 mt-2">{error}</p> : <p className="mb-2 mt-2">&nbsp;</p>)
	}

	const handleResetFilters = () => {
		setSearchStatus("ALL")
		setSearchRank("ALL")
		setSearchLeader("")
		setSearch("")
		setSearchEmail("")
		setParams({ page: 1, limit: 10 })
		setError('')
	}

	const handleExportData = () => {
		dispatch(exportUsersFinances())
	}

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">

					<div className="card">
						<div className="card-body">
							<form className="row" onSubmit={searchUsers}>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Username</label>
									<div className="input-group">
										<input
											type="text"
											value={search}
											onChange={(e) => setSearch(e.target.value)} className="form-control"
											placeholder="Search here..."
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Search Email</label>
									<div className="input-group">
										<input
											type="text"
											value={searchEmail}
											onChange={(e) => setSearchEmail(e.target.value)} className="form-control"
											placeholder="Search email"
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>Start Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<label>End Date</label>
									<div className="input-group">
										<input
											type="date"
											className="form-control"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Leader</label>
										<div className="input-group">
											<select
												disabled={authUser?.role == 2 && authUser.limitLeader}
												className="form-control custom-select"
												value={searchLeader}
												onChange={(e) => setSearchLeader(e.target.value)}>
												<option value="">All Leaders</option>
												{allLeaders && allLeaders.length > 0 ?
													allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
													: <></>
												}
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Status</label>
										<div className="input-group">
											<select
												className="form-control custom-select"
												value={searchStatus}
												onChange={(e) => setSearchStatus(e.target.value)}>
												<option value="ALL">All</option>
												<option value="ACTIVE">ACTIVE</option>
												<option value="SUSPENDED">SUSPENDED</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-4 col-sm-6">
									<div className="form-group">
										<label>Rank</label>
										<div className="input-group">
											<select
												className="form-control custom-select"
												value={searchRank}
												onChange={(e) => setSearchRank(e.target.value)}>
												<option value="ALL">All</option>
												<option value="0">{userRankingString("0")}</option>
												<option value="1">{userRankingString("1")}</option>
												<option value="2">{userRankingString("2")}</option>
												<option value="3">{userRankingString("3")}</option>
												<option value="4">{userRankingString("4")}</option>
												<option value="5">{userRankingString("5")}</option>
											</select>
										</div>
									</div>
								</div>
								<div className='col-12'>
									{renderError()}
									<button type="submit" className="btn btn-primary">
										<i className="fe fe-search mr-2" />
										Search
									</button>
									<button type="button" className="btn btn-secondary ml-2" onClick={handleResetFilters}>
										Reset
									</button>
									<button type="button" className="btn btn-outline-secondary ml-2" onClick={handleExportData}>
										Export Excel
									</button>
								</div>
							</form>

						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">User Finances List</h3>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
								<thead>
									<tr>
										<th>ID</th>
										<th>Name</th>
										<th>Ranking</th>
										<th>Leader</th>
										<th>Successful Deposits</th>
										<th>Successful Withdrawals</th>
										<th>Total Tickets</th>
										<th>Total Active Tickets</th>
										<th className="w150">Action</th>
									</tr>
								</thead>
								<tbody>
									{users && Array.isArray(users) && users.length > 0 ? users.map(user => <UserRow key={user.id} {...user} authRole={authUser.role || 0} />) : <tr><td colSpan={10}><p className="text-center">No users found</p></td></tr>}
									{/* <tr>
														<td className="width45">
															<span
																className="avatar avatar-blue"
																data-toggle="tooltip"
																data-placement="top"
																data-original-title="Avatar Name"
															>
																NG
															</span>
														</td>
														<td>
															<h6 className="mb-0">Marshall Nichols</h6>
															<span>marshall-n@gmail.com</span>
														</td>
														<td>
															<span className="tag tag-danger">Super Admin</span>
														</td>
														<td>24 Jun, 2015</td>
														<td>CEO and Founder</td>
														{authUser && authUser.role >= 6 ? <td>
															<button
																type="button"
																className="btn btn-icon"
																title="Edit"
															>
																<i className="fa fa-edit" />
															</button>
															<button
																type="button"
																className="btn btn-icon js-sweetalert"
																title="Delete"
																data-type="confirm"
															>
																<i className="fa fa-trash-o text-danger" />
															</button>
														</td> : <td />}

													</tr> */}

								</tbody>
							</table>
							<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default UserFinances;