import axios from '../util/api';
import {
  CAMPAIGN_SET_SEARCH,
  CAMPAIGN_SET_SEARCH_TERM,
  CAMPAIGN_SET_INVEST_SEARCH,
  CAMPAIGN_SET_INVEST_SEARCH_TERM,

} from '../constants/actionTypes';

import { fetchStart, fetchSuccess, fetchError, showMessage } from './apiAction';
import { formatCurrency } from '../util/money';
import { exportToExcel } from '../util/exportExcel';
import { formatDateTime } from '../util/date';

export const setCampaignSearch = (payload) => {
  return {
    type: CAMPAIGN_SET_SEARCH,
    payload,
  };
};

export const setCampaignSearchTerm = (payload) => {
  return {
    type: CAMPAIGN_SET_SEARCH_TERM,
    payload,
  };
};

export const setCampaignInvestSearch = (payload) => {
  return {
    type: CAMPAIGN_SET_INVEST_SEARCH,
    payload,
  };
};

export const setCampaignInvestSearchTerm = (payload) => {
  return {
    type: CAMPAIGN_SET_INVEST_SEARCH_TERM,
    payload,
  };
};

export const searchCampaigns = ({ search = "", leader = "", status = "", id = "", repeat, page = 1, limit = 10 }) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser

    const payload = {
      id,
      title: search,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
      status,
      repeat,
    }
    dispatch(fetchStart());
    dispatch(setCampaignSearchTerm(search));
    axios.post(`/crackers/adm/campaign/get?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setCampaignSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const createCampaign = ({
  title,
  duration,
  maxVolume,
  percentage,
  percentageMin,
  percentageMax,
  launchDate,
  startDate,
  leader, //leader username
  minValue,
  minTotalValue,
  isRepeat,
  repeatFrequency,
}) => {
  const payload = {
    title,
    duration,
    maxVolume,
    percentage,
    percentageMin,
    percentageMax,
    launchDate,
    startDate,
    leader,
    minValue,
    minTotalValue,
    isRepeat,
    repeatFrequency,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().campaign.searchTerm;
    axios.post('/crackers/adm/campaign/create', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Created Campaign'));
        dispatch(searchCampaigns({ search: searchTerm, page: 1, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const editCampaign = ({
  id,
  title,
  duration,
  currentVolume,
  maxVolume,
  percentage,
  percentageMin,
  percentageMax,
  launchDate,
  startDate,
  status,
  leader,
  minValue,
  minTotalValue,
  isRepeat,
  repeatFrequency,
}) => {
  const payload = {
    id,
    title,
    duration,
    currentVolume,
    maxVolume,
    percentage,
    percentageMin,
    percentageMax,
    launchDate,
    startDate,
    status,
    leader,
    minValue,
    minTotalValue,
    isRepeat,
    repeatFrequency,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().packages.searchTerm;
    axios.post('/crackers/adm/campaign/update', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Campaign updated'));
        dispatch(searchCampaigns({ search: searchTerm, page: 1, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const deleteCampaign = ({
  id,
}) => {
  const payload = {
    id,
  }
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const searchTerm = getStore().packages.searchTerm;
    axios.post('/crackers/adm/campaign/delete', payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(showMessage('Campaign deleted'));
        dispatch(searchCampaigns({ search: searchTerm, page: 1, }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const searchCampaignInvests = ({ search = "", leader = "", status = "", investStatus = "", page = 1, limit = 10 }) => {
  return (dispatch, getStore) => {
    const authUser = getStore().user?.authUser

    const payload = {
      search: search,
      leader: authUser?.role == 2 && authUser.limitLeader ? authUser.limitLeader : leader,
      status,
      investStatus,
    }
    dispatch(fetchStart());
    dispatch(setCampaignInvestSearchTerm(payload));
    axios.post(`/crackers/adm/campaign/getinvest?page=${page}&limit=${limit}`, payload).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        dispatch(setCampaignInvestSearch({
          report: data.report,
          meta: data.meta
        }));
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};

export const exportCampaignInvests = () => {
  return (dispatch, getStore) => {
    dispatch(fetchStart());
    const payload = getStore().campaign.searchInvestTerm
    axios.post(`/crackers/adm/campaign/getinvest`, { ...payload, all: true }).then(({ data }) => {
      if (data && data.success) {
        dispatch(fetchSuccess());
        exportToExcel({
          data: data.report,
          headers: [
            {
              key: 'id',
              label: "ID",
            },
            {
              key: 'username',
              label: "Username",
            },
            {
              key: 'amount',
              label: 'Invest Amount',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'profit',
              label: 'Profit',
              formatter: (value) => value ? formatCurrency(value) : '-',
              s: {
                alignment: {
                  horizontal: 'right',
                },
              },
            },
            {
              key: 'status',
              label: 'Ticket Status',
            },
            {
              key: 'campaignID',
              label: "Campaign ID",
            },
            {
              key: 'title',
              label: "Campaign Title",
            },
            {
              key: 'campaignStatus',
              label: 'Campaign Status',
            },
            {
              key: 'leaderUsername',
              label: 'Campaign Leader',
              formatter: (value) => value ? value : '-',
            },
            {
              key: 'createdAt',
              label: 'Created Date',
              formatter: (value) => value ? formatDateTime(value) : '-',
            },

          ],
          title: "CampaignInvestments"
        })
      }
    }).catch(function (error) {
      if (error && error.response && error.response.data) {
        dispatch(fetchError(error.response.data));
      } else {
        dispatch(fetchError('Unable to get a response'));
      }
    });
  }
};
