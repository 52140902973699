import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTicketConversations, replyTicketConversation } from '../../../../actions/supportAction';
import { formatDateTime } from '../../../../util/date';

const ChatBubbles = ({ type, message, sentAt, isLast }) => {
    const ClientMessage = () => {
        const divEl = useRef(null);
        useEffect(() => {
            if (isLast) {
                divEl.current.scrollIntoView();
            }
        }, []);
        return (
            <li className="other-message" ref={divEl}>
                <img className="avatar mr-3" src="../assets/images/xs/avatar2.jpg" alt="avatar" />
                <div className="message">
                    <p className="bg-light-blue">{message}</p>
                    <span className="time">{sentAt ? formatDateTime(new Date(sentAt)) : ''}</span>
                </div>
            </li>
        )
    }
    const MyMessage = () => {
        const divEl = useRef(null);
        useEffect(() => {
            if (isLast) {
                divEl.current.scrollIntoView();
            }
        }, []);
        return (
            <li className="my-message" ref={divEl}>
                <div className="message">
                    <p className="bg-light-gray">{message}</p>
                    <span className="time">{sentAt ? formatDateTime(new Date(sentAt)) : ''}</span>
                </div>
            </li>
        )
    }
    return (
        type === 'sent' ? <ClientMessage /> : <MyMessage />
    )
}

const AppChart = ({ setIsChatDialogOpen }) => {
    const [inputText, setInputText] = useState("");

    const dispatch = useDispatch();
    const conversations = useSelector(({ support }) => support.chatConversations);
    const supportID = useSelector(({ support }) => support.chatSupportID);
    const ticketDetails = useSelector(({ support }) => support.chatTicketDetails);
    useEffect(() => {
        dispatch(getTicketConversations());
    }, [supportID])

    const handleRefreshConversations = () => {
        dispatch(getTicketConversations());
    }

    const handleCloseDialog = () => {
        setIsChatDialogOpen(false);
    }

    const handleSubmitReply = (e) => {
        e.preventDefault();
        dispatch(replyTicketConversation(inputText))
        setInputText('')
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmitReply(e);
        }
    };

    return (
        <div className="section-light py-3 chat_app">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card bg-none b-none">
                            <div className="card-header bline pt-1">
                                <h3 className="card-title" style={{ textTransform: 'initial' }}>{ticketDetails && ticketDetails.username} <small>Subject: {ticketDetails && ticketDetails.subject}</small></h3>
                                <div className="card-options">
                                    <a href="/#" className="p-1 chat_list_btn"><i className="fa fa-align-right" /></a>
                                    <button onClick={handleRefreshConversations} type="button" className="btn btn-icon"><i className="fa fa-refresh" /></button>
                                    <button onClick={handleCloseDialog} type="button" className="btn btn-icon"><i className="fa fa-times" /></button>
                                </div>
                            </div>
                            <div className="chat_windows">
                                <ul className="mb-0" style={{ height: 'calc(100vh - 300px)' }}>
                                    {conversations && conversations.length > 0 && conversations.map((c, i) => <ChatBubbles key={i} {...c} isLast={(conversations.length - 1) === i} />)}
                                    {/* <li className="other-message">
                                        <img className="avatar mr-3" src="../assets/images/xs/avatar2.jpg" alt="avatar" />
                                        <div className="message">
                                            <p className="bg-light-blue">Are we meeting today?</p>
                                            <span className="time">10:10 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="other-message">
                                        <img className="avatar mr-3" src="../assets/images/xs/avatar3.jpg" alt="avatar" />
                                        <div className="message">
                                            <p className="bg-light-cyan">Hi Aiden, how are you? How is the project coming along?</p>
                                            <p className="bg-light-cyan">Are we meeting today?</p>
                                            <span className="time">10:15 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="my-message">
                                        <div className="message">
                                            <p className="bg-light-gray">Project has been already finished and I have results to show you.</p>
                                            <div className="file_folder">
                                                <a href="/#;">
                                                    <div className="icon">
                                                        <i className="fa fa-file-excel-o text-success" />
                                                    </div>
                                                    <div className="file-name">
                                                        <p className="mb-0 text-muted">Report2017.xls</p>
                                                        <small>Size: 68KB</small>
                                                    </div>
                                                </a>
                                                <a href="/#;">
                                                    <div className="icon">
                                                        <i className="fa fa-file-word-o text-primary" />
                                                    </div>
                                                    <div className="file-name">
                                                        <p className="mb-0 text-muted">Report2017.doc</p>
                                                        <small>Size: 68KB</small>
                                                    </div>
                                                </a>
                                            </div>
                                            <span className="time">10:17 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="other-message">
                                        <img className="avatar mr-3" src="../assets/images/xs/avatar4.jpg" alt="avatar" />
                                        <div className="message">
                                            <div className="media_img">
                                                <img src="../assets/images/gallery/1.jpg" className="w150 img-thumbnail" alt="avatar" />
                                                <img src="../assets/images/gallery/2.jpg" className="w150 img-thumbnail" alt="avatar" />
                                            </div>
                                            <span className="time">10:15 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="other-message">
                                        <span className="avatar avatar-blue mr-3">NG</span>
                                        <div className="message">
                                            <p className="bg-light-pink">Are we meeting today I have results?</p>
                                            <p className="bg-light-pink">Project has been already finished and to show you.</p>
                                            <span className="time">10:18 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="my-message">
                                        <div className="message">
                                            <p className="bg-light-gray">Well we have good budget for the project</p>
                                            <span className="time">10:25 AM, Today</span>
                                        </div>
                                    </li>
                                    <li className="my-message">
                                        <div className="message">
                                            <div className="media_img">
                                                <img src="../assets/images/gallery/3.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                <img src="../assets/images/gallery/4.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                <img src="../assets/images/gallery/5.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                <img src="../assets/images/gallery/6.jpg" className="w100 img-thumbnail" alt="avatar" />
                                            </div>
                                            <span className="time">10:25 AM, Today</span>
                                        </div>
                                    </li> */}
                                </ul>
                                <div className="chat-message clearfix">
                                    <form onSubmit={handleSubmitReply}>
                                        <div className="input-group mb-0">
                                            <textarea
                                                type="text"
                                                className="form-control mt-0"
                                                placeholder="Enter text here..."
                                                value={inputText}
                                                onKeyUp={handleKeyPress}
                                                onChange={e => setInputText(e.target.value)}
                                            />
                                            <button type="submit" className="btn btn-primary w100" disabled={!inputText}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppChart;