//App existing types
export const CHANGE_DARK_MODE = 'CHANGE_DARK_MODE';
export const CHANGE_DARK_HEADER = 'CHANGE_DARK_HEADER';
export const FIX_NAVBAR_HEADER = 'FIX_NAVBAR_HEADER';
export const DARK_MIN_SIDEBAR = 'DARK_MIN_SIDEBAR';
export const DARK_SIDEBAR = 'DARK_SIDEBAR';
export const CHANGE_ICON_COLOR = 'CHANGE_ICON_COLOR';
export const CHANGE_GRADIENT_COLOR = 'CHANGE_GRADIENT_COLOR';
export const IS_RTL = 'IS_RTL';
export const CHANGE_FONT = 'CHANGE_FONT';
export const CHANGE_SUBMENU_ICON = 'CHANGE_SUBMENU_ICON';
export const CHANGE_DROPDOWN_MENU_ICON = 'CHANGE_DROPDOWN_MENU_ICON';
export const CHANGE_BOX_LAYOUT = 'CHANGE_BOX_LAYOUT';
export const IS_OPEN_STATISTICS = 'IS_OPEN_STATISTICS';
export const IS_OPEN_ACCOUNT = 'IS_OPEN_ACCOUNT';
export const IS_OPEN_FRIENDLIST = 'IS_OPEN_FRIENDLIST';
export const IS_CLOSE_STATISTICS = 'IS_CLOSE_STATISTICS';
export const IS_CLOSE_ACCOUNT = 'IS_CLOSE_ACCOUNT';
export const IS_CLOSE_FRIENDLIST = 'IS_CLOSE_FRIENDLIST';
export const IS_TOGGLE_LEFTMENU = 'IS_TOGGLE_LEFTMENU';
export const IS_TOGGLE_BOX = 'IS_TOGGLE_BOX';
export const IS_TOGGLE_BOX2 = 'IS_TOGGLE_BOX2';
export const IS_TOGGLE_BOX3 = 'IS_TOGGLE_BOX3';
export const IS_TOGGLE_BOX4 = 'IS_TOGGLE_BOX4';
export const IS_TOGGLE_BOX5 = 'IS_TOGGLE_BOX5';
export const IS_TOGGLE_BOX6 = 'IS_TOGGLE_BOX6';
export const IS_TOGGLE_BOX7 = 'IS_TOGGLE_BOX7';
export const IS_TOGGLE_BOX8 = 'IS_TOGGLE_BOX8';
export const IS_TOGGLE_BOX9 = 'IS_TOGGLE_BOX9';
export const IS_TOGGLE_BOX10 = 'IS_TOGGLE_BOX10';
export const IS_CLOSE_BOX = 'IS_CLOSE_BOX';
export const IS_CLOSE_BOX2 = 'IS_CLOSE_BOX2';
export const IS_CLOSE_BOX3 = 'IS_CLOSE_BOX3';
export const IS_CLOSE_BOX4 = 'IS_CLOSE_BOX4';
export const IS_CLOSE_BOX5 = 'IS_CLOSE_BOX5';
export const IS_CLOSE_BOX6 = 'IS_CLOSE_BOX6';
export const IS_CLOSE_BOX7 = 'IS_CLOSE_BOX7';
export const IS_CLOSE_BOX8 = 'IS_CLOSE_BOX8';
export const IS_CLOSE_BOX9 = 'IS_CLOSE_BOX9';
export const IS_CLOSE_BOX10 = 'IS_CLOSE_BOX10';

//api dispatch types
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';

//admin dispatch types
export const INIT_URL = 'INIT_URL';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_SET_UNAUTHORIZED = 'USER_SET_UNAUTHORIZED';

//site actions
export const SITE_GET_DETAILS = 'SITE_GET_DETAILS';
export const SITE_SET_USERS_SEARCH = 'SITE_SET_USERS_SEARCH';
export const SITE_SET_USERS_SEARCH_TERM = 'SITE_SET_USERS_SEARCH_TERM';
export const SITE_SET_USERS_FILTER_TERM = 'SITE_SET_USERS_FILTER_TERM';
export const SITE_SET_PLACEMENT_SEARCH = 'SITE_SET_PLACEMENT_SEARCH';
export const SITE_SET_PLACEMENT_SEARCH_TERM = 'SITE_SET_PLACEMENT_SEARCH_TERM';
export const SITE_SET_LEADER_SEARCH = 'SITE_SET_LEADER_SEARCH';
export const SITE_SET_LEADER_SEARCH_TERM = 'SITE_SET_LEADER_SEARCH_TERM';
export const SITE_SET_ALL_LEADERS = 'SITE_SET_ALL_LEADERS';
export const SITE_SET_USERS_FINANCES_SEARCH = 'SITE_SET_USERS_FINANCES_SEARCH';
export const SITE_SET_USERS_FINANCES_SEARCH_TERM = 'SITE_SET_USERS_FINANCES_SEARCH_TERM';

//admin actions
export const ADMIN_SET_USERS_SEARCH = 'ADMIN_SET_USERS_SEARCH';
export const ADMIN_SET_USERS_SEARCH_TERM = 'ADMIN_SET_USERS_SEARCH_TERM';

//withdrawal actions
export const WITHDRAWAL_SET_DATA = 'WITHDRAWAL_SET_DATA';
export const WITHDRAWAL_SET_FILTER_TERMS = 'WITHDRAWAL_SET_FILTER_TERMS';
export const WITHDRAWAL_SET_SUMMARY = 'WITHDRAWAL_SET_SUMMARY';

//support actions
export const SUPPORT_SET_TICKETS = 'SUPPORT_SET_TICKETS';
export const SUPPORT_SET_TICKETS_TERMS = 'SUPPORT_SET_TICKETS_TERMS';
export const SUPPORT_SET_CHAT_CONVERSATIONS = 'SUPPORT_SET_CHAT_CONVERSATIONS';
export const SUPPORT_SET_CHAT_SUPPORTID = 'SUPPORT_SET_CHAT_SUPPORTID';
export const SUPPORT_SET_CHAT_TICKET_DETAILS = 'SUPPORT_SET_CHAT_TICKET_DETAILS';

//deposit actions
export const DEPOSIT_SET_DATA = 'DEPOSIT_SET_DATA';
export const DEPOSIT_SET_SUMMARY = 'DEPOSIT_SET_SUMMARY';
export const DEPOSIT_SET_SEARCH = 'DEPOSIT_SET_SEARCH';

//wallet actions
export const WALLET_SET_DATA = 'WALLET_SET_DATA';
export const WALLET_SET_SEARCH_TERM = 'WALLET_SET_SEARCH_TERM';
export const WALLET_SET_ADJUST_DATA = 'WALLET_SET_ADJUST_DATA';
export const WALLET_SET_ADJUST_SEARCH_TERM = 'WALLET_SET_ADJUST_SEARCH_TERM';

//stock actions
export const STOCK_FCN_SET_DATA = 'STOCK_FCN_SET_DATA';
export const STOCK_FCN_SET_SEARCH_TERMS = 'STOCK_FCN_SET_SEARCH_TERMS';

//kyc actions
export const KYC_SET_DATA = 'KYC_SET_DATA';
export const KYC_SET_SEARCH_TERM = 'KYC_SET_SEARCH_TERM';
export const KYC_SET_ADJUST_DATA = 'KYC_SET_ADJUST_DATA';
export const KYC_SET_ADJUST_SEARCH_TERM = 'KYC_SET_ADJUST_SEARCH_TERM';

//placement actions
export const PLACEMENT_SET_DISPLAY_ROOT = 'PLACEMENT_SET_DISPLAY_ROOT';
export const PLACEMENT_RESET_DISPLAY_ROOT = 'PLACEMENT_RESET_DISPLAY_ROOT';
export const PLACEMENT_SET_PLACEMENT = 'PLACEMENT_SET_PLACEMENT';
export const PLACEMENT_SET_REFERRALS = 'PLACEMENT_SET_REFERRALS';
export const PLACEMENT_UPDATE_NODES = 'PLACEMENT_UPDATE_NODES';
export const PLACEMENT_SET_NEW_USER = 'PLACEMENT_SET_NEW_USER';
export const PLACEMENT_SET_SEARCH_USER = 'PLACEMENT_SET_SEARCH_USER';

//package actions
export const PACKAGE_SET_SEARCH = 'PACKAGE_SET_SEARCH';
export const PACKAGE_SET_SEARCH_TERM = 'PACKAGE_SET_SEARCH_TERM';
export const PACKAGE_SET_SEARCH_USER_PIN_PACKAGE = 'PACKAGE_SET_SEARCH_USER_PIN_PACKAGE';
export const PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM = 'PACKAGE_SET_PIN_PACKAGE_SEARCH_TERM';

//campaign
export const CAMPAIGN_SET_SEARCH = 'CAMPAIGN_SET_SEARCH';
export const CAMPAIGN_SET_SEARCH_TERM = 'CAMPAIGN_SET_SEARCH_TERM';
export const CAMPAIGN_SET_INVEST_SEARCH = 'CAMPAIGN_SET_INVEST_SEARCH';
export const CAMPAIGN_SET_INVEST_SEARCH_TERM = 'CAMPAIGN_SET_INVEST_SEARCH_TERM';

// payout
export const PAYOUT_SET_SEARCH = 'PAYOUT_SET_SEARCH';
export const PAYOUT_SET_SEARCH_TERM = 'PAYOUT_SET_SEARCH_TERM';

// announcements
export const ANNOUNCEMENT_SET_DATA = 'ANNOUNCEMENT_SET_DATA';
export const ANNOUNCEMENT_SET_SEARCH_TERM = 'ANNOUNCEMENT_SET_SEARCH_TERM';
export const DOWNLOADS_SET_DATA = 'DOWNLOADS_SET_DATA';
export const DOWNLOADS_SET_SEARCH_TERM = 'DOWNLOADS_SET_SEARCH_TERM';

// system settings 
export const SYSTEM_SETTING_SET_DATA = 'SYSTEM_SETTING_SET_DATA';
export const SYSTEM_SETTING_SET_SEARCH_TERM = 'SYSTEM_SETTING_SET_SEARCH_TERM';
export const SYSTEM_SETTING_TOKEN_SET_DATA = 'SYSTEM_SETTING_TOKEN_SET_DATA';
