import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { createCampaign, deleteCampaign, editCampaign, exportCampaignInvests, searchCampaignInvests } from '../../../actions/campaignAction';
import { formatCustomDate, formatDate, formatDateTime } from '../../../util/date';
import StatusBadge from '../../Badges/StatusBadge';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getAllLeaders } from '../../../actions/siteAction';
import { formatCurrency } from '../../../util/money';

const ItemRow = ({
	id,
	campaignID,
	title,
	status,
	campaignStatus,
	username,
	amount,
	profit,
	leaderUsername,
	leaderID,
	createdAt,
}) => {
	const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

	const handleViewClickOpen = () => {
		setIsViewDialogOpen(true);
	};

	const handleViewClose = () => {
		setIsViewDialogOpen(false);
	};

	return (
		<tr>
			<td className="width45">
				{id}
			</td>
			<td>{username}</td>
			<td>{amount ? formatCurrency(amount) : '-'}</td>
			<td>{profit ? formatCurrency(profit) : '-'}</td>
			<td>{status ? <StatusBadge status={status} /> : "-"}</td>
			<td>
				<h6 className="mb-0">{title}<br />
					<small>Campaign ID: {campaignID}</small>
				</h6>
			</td>
			<td>{campaignStatus ? <StatusBadge status={campaignStatus} /> : "-"}</td>
			<td>
				{leaderUsername ?
					<>{leaderUsername}<br />
						<small>ID: {leaderID}</small></>
					: '-'
				}
			</td>
			<td>{createdAt ? formatDateTime(createdAt) : '-'}</td>
			<td>
				<button
					type="button"
					className="btn btn-icon"
					title="view"
					onClick={handleViewClickOpen}
				>
					<i className="fa fa-eye" />
				</button>
				<Dialog open={isViewDialogOpen} onClose={handleViewClose}>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">View {title}</h5>
							<button type="button" className="close" onClick={handleViewClose}>
							</button>
						</div>
						<div className="modal-body" style={{ minWidth: '350px' }}>
							<p>
								<span className="text-bold">Ticket ID: </span>{id}<br />
								<span className="text-bold">Username: </span>{username}<br />
								<span className="text-bold">Amount: </span>{amount ? formatCurrency(amount) : '-'}<br />
								<span className="text-bold">Profit: </span>{profit ? formatCurrency(profit) : '-'}<br />
								<span className="text-bold">Campaign ID: </span>{campaignID}<br />
								<span className="text-bold">Campaign Title: </span>{title}<br />
								<span className="text-bold">Leader: </span>{leaderUsername}<br />
								<span className="text-bold">Join Date: </span>{createdAt ? formatDate(createdAt) : '-'}<br />
								<span className="text-bold">Status: </span>{status ? <StatusBadge status={status} /> : '-'}<br />
							</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" onClick={handleViewClose}>Close</button>
						</div>
					</div>
				</Dialog>

			</td>
		</tr >
	)
}

const CampaignInvests = (props) => {
	const [search, setSearch] = useState('');

	const [searchLeader, setSearchLeader] = useState('');
	const [searchStatus, setSearchStatus] = useState('');
	const [searchInvestStatus, setSearchInvestStatus] = useState('');

	const [params, setParams] = useState({ page: 1, limit: 10 })

	const dispatch = useDispatch();
	const reports = useSelector(({ campaign }) => campaign.campaignInvestments.report)
	const meta = useSelector(({ campaign }) => campaign.campaignInvestments.meta)
	const allLeaders = useSelector(({ site }) => site.allLeaders)

	const { authUser } = useSelector(({ user }) => user);

	useEffect(() => {
		dispatch(getAllLeaders())
	}, [])

	useEffect(() => {
		dispatch(searchCampaignInvests({ search, leader: searchLeader, status: searchStatus, investStatus: searchInvestStatus, ...params }))
	}, [params])

	const searchUsers = (e) => {
		e.preventDefault();
		dispatch(searchCampaignInvests({ search, leader: searchLeader, status: searchStatus, investStatus: searchInvestStatus, ...params }))
	}

	const handlePageChange = (page) => setParams({ ...params, page })
	const handlePageSizeChange = (pageSize) =>
		setParams({ ...params, limit: pageSize })

	const handleExportData = () => {
		dispatch(exportCampaignInvests())
	}
	return (
		<div>

			<div className="card">
				<div className="card-body">
					<form className="row" onSubmit={searchUsers}>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<label>Search Campaign Name</label>
							<div className="input-group">
								<input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search here..." />
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Campaign Status</label>
								<select
									className="form-control custom-select"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								>
									<option value="">All</option>
									<option value="ACTIVE">Active</option>
									<option value="RUNNING">Running</option>
									<option value="COMPLETED">Completed</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Ticket Status</label>
								<select
									className="form-control custom-select"
									value={searchInvestStatus}
									onChange={(e) => setSearchInvestStatus(e.target.value)}
								>
									<option value="">All</option>
									<option value="ACTIVE">Active</option>
									<option value="RUNNING">Running</option>
									<option value="COMPLETED">Completed</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-6">
							<div className="form-group">
								<label>Leader</label>
								<div className="input-group">
									<select
										disabled={authUser?.role == 2 && authUser.limitLeader}
										className="form-control custom-select"
										value={searchLeader}
										onChange={(e) => setSearchLeader(e.target.value)}>
										<option value="">All Leaders</option>
										{allLeaders && allLeaders.length > 0 ?
											allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
											: <></>
										}
									</select>
								</div>
							</div>
						</div>
						<div className='col-12'>
							<button type="submit" className="btn btn-primary">
								<i className="fe fe-search mr-2" />
								Search
							</button>
							<button type="button" className="btn btn-outline-secondary ml-4" onClick={handleExportData}>
								Export Excel
							</button>
						</div>

					</form>
				</div>
			</div>
			<div className="mt-3">
				<div className="card">
					<div className="card-header">
						<h3 className="card-title">Campaign Tickets</h3>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
								<thead>
									<tr>
										<th>Ticket ID</th>
										<th>Username</th>
										<th>Amount</th>
										<th>Profit</th>
										<th>Investment Status</th>
										<th>Campaign</th>
										<th>Campaign Status</th>
										<th>Leader</th>
										<th>Created</th>
										<th className="w100">Action</th>
									</tr>
								</thead>
								<tbody>
									{reports && Array.isArray(reports) && reports.length > 0 ? reports.map(d => <ItemRow key={d.id} {...d} authRole={authUser.role || 0} />) : <tr><td colSpan={9}><p className="text-center">No data found</p></td></tr>}
								</tbody>
							</table>
							<TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
						</div>
					</div>
				</div>
			</div>
		</div>

	);

}

export default CampaignInvests;