import React, { useState, useEffect } from "react"
import { Tree, TreeNode } from "react-organizational-chart"
// import { useSpring, animated } from "@react-spring/web"
import { useDispatch, useSelector } from "react-redux"
import { utcToZonedTime, format } from "date-fns-tz"

import {
  PlacementData,
  placementSetUser,
  setSignUpNewUser,
} from "../../../actions/placementAction"
import { getBreadCrumbs } from "../../../util/placement"
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { formatCurrency } from "../../../util/money"


const LEFT = "LEFT"
const RIGHT = "RIGHT"

function SimpleBreadcrumbs({
  handleClick,
  list,
  initial,
  handleMoveUp,
  className,
}) {
  return (
    <Breadcrumbs
      className={className}
      aria-label="breadcrumb"
      sx={{ marginTop: 4 }}
    >
      <Button
        variant="contained"
        color="primary"
        type="button"
        onClick={() => handleClick(initial.uid)}
      >
        Back To Top
      </Button>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        onClick={(e) => handleMoveUp(e)}
      >
        Move Up
      </Button>
      {/* {list && list.map((u, i) =>
        <div className="placement-breadcrumb-link" key={i} onClick={() => handleClick(u.uid)}>
          {u.name}
        </div>
      )} */}
    </Breadcrumbs>
  )
}

const DialogPlaceUser = ({
  isDialogOpen,
  setIsDialogOpen,
  parentID,
  parentName,
  position,
}) => {
  const [userID, setUserID] = useState("")
  //   const [error, setError] = useState('')

  const dispatch = useDispatch()
  const referrals = useSelector((state) => state.placement.referrals)


  const onSubmit = () => {
    dispatch(
      placementSetUser({
        parentID,
        targetUserID: userID,
        position,
      })
    )
    setIsDialogOpen(false)
  }

  return (
    <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <DialogTitle>
        <Typography width={300} variant="h5">
          Place User
        </Typography>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                label="Username"
                fullWidth
                select
                value={userID}
                SelectProps={{ userID, onChange: (e) => setUserID(e.target.value) }}
              >
                {referrals &&
                  referrals.length > 0 &&
                  referrals.map((user, i) => (
                    <MenuItem key={i} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
              </TextField>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const TooltipTitle = (
  props
) => {
  const {
    name,
    uid,
    joined,
    packageName,
    packageValue,
    leftSales,
    rightSales,
    accumulatedValue,
    dailyLeft,
    dailyRight,
    cfLeft,
    cfRight,
    leftCount,
    rightCount,
  } = props
  const formattedDate = joined
    ? format(utcToZonedTime(joined, "+08:00"), "d MMM y")
    : "-"

  return (
    <div style={{ color: "#ffffff", textAlign: "center" }}>
      <p className="mb-1">Total Sales: ${formatCurrency(leftSales + rightSales)}</p>
      <p className="mb-1">
        <span>Left Sales: ${formatCurrency(leftSales)}</span>
      </p>
      <p className='mb-1'>
        <span>Left Remaining: ${formatCurrency(cfLeft)}</span>
      </p>
      <p className="mb-1">
        <span>Right Sales: ${formatCurrency(rightSales)}</span>
      </p>

      <p className='mb-1'>
        <span>Right Remaining: ${formatCurrency(cfRight)}</span>
      </p>
      <p className="mb-1">
        <span>Left Members: {leftCount}</span>
      </p>
      <p className="mb-1">
        <span>Right Members: {rightCount}</span>
      </p>
    </div>
  )
}

const PlacementBox = (
  props
) => {
  const { setRootDisplay, placementRoot, ...otherProps } = props
  // const animateStyle = useSpring({
  //   opacity: 1,
  //   from: { opacity: 0 },
  // })
  return (
    <Tooltip title={<TooltipTitle {...otherProps} />} arrow>
      <div>
        <div className="flex justify-center items-center" style={{ display: 'flex', justifyContent: "center", }}>
          <div style={{ border: "2px solid", borderRadius: '8px', height: "112px", width: "112px" }}
            className="border-2 rounded-md h-28 w-28 p-2"
            onClick={() => setRootDisplay(otherProps)}
          >
            <Typography variant="h5">{otherProps.name}</Typography>
            <br />
            <Typography>
              {otherProps.packageValue
                ? formatCurrency(Number(otherProps.packageValue))
                : "-"}
            </Typography>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

const PlaceHereBox = (props) => {
  const {
    index,
    parentID,
    parentName,
    setParentID,
    setParentName,
    setPosition,
    setIsDialogOpen,
    dispatchNewPlaceAction,
  } = props

  const placeAction = () => {
    setParentID(parentID)
    setParentName(parentName)
    setPosition(index == 0 ? LEFT : RIGHT)
    dispatchNewPlaceAction({
      parentID,
      parentName,
      position: index == 0 ? LEFT : RIGHT,
    })
    setIsDialogOpen(true)
  }
  return (
    <div>
      <div className="flex justify-center items-center" style={{ display: 'flex', justifyContent: "center", }}>
        <div
          className="border-2 rounded-md h-28 w-28 p-2 flex justify-center items-center cursor-pointer" style={{ border: "2px solid", borderRadius: '8px', height: "112px", width: "112px" }}
        // onClick={placeAction}
        >
          <Typography variant="h5">EMPTY</Typography>
        </div>
      </div>
    </div>
  )
}

const CareerChart = (props) => {
  const {
    root,
    rootNode,
    nodes,
    setRootDisplay,
    dispatchSearchByUsername,
    handleMoveUp,
  } = props

  const [parentID, setParentID] = useState("")
  const [parentName, setParentName] = useState("")
  const [position, setPosition] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [list, setList] = useState([])
  const [searchInput, setSearchInput] = useState("")

  const dispatch = useDispatch()
  const placementRoot = useSelector(
    ({ placement }) => placement.root
  )

  const dispatchNewPlaceAction = ({ parentID, parentName, position }) => {
    dispatch(setSignUpNewUser({ parentID, parentName, position }))
  }

  const handleClick = (uid) => {
    const node = nodes[uid]
    if (node) {
      setRootDisplay(node)
    }
  }
  const handleSearch = (event) => {
    dispatchSearchByUsername(searchInput, setRootDisplay)
    event.preventDefault()
  }

  useEffect(() => {
    if (rootNode && nodes) {
      setList(getBreadCrumbs({ nodes, targetNodeID: rootNode.uid }))
    }
  }, [rootNode, nodes])

  const renderNodes = () => {
    return (
      (rootNode &&
        Array.isArray(rootNode.children) &&
        rootNode.children.length > 0 && (
          <>
            {rootNode.children.map((child, i) => (
              <TreeNode
                key={i}
                label={
                  nodes[child] ? (
                    <PlacementBox
                      {...nodes[child]}
                      setRootDisplay={setRootDisplay}
                      leftSales={
                        nodes[child].totalLeft ? nodes[child].totalLeft : 0
                      }
                      rightSales={
                        nodes[child].totalRight ? nodes[child].totalRight : 0
                      }
                      key={`${nodes[child] ? nodes[child].name : "-"}_${i}`}
                    />
                  ) : (
                    <PlaceHereBox
                      index={i}
                      parentID={rootNode.uid}
                      parentName={rootNode.name}
                      setParentID={setParentID}
                      setParentName={setParentName}
                      setPosition={setPosition}
                      setIsDialogOpen={setIsDialogOpen}
                      dispatchNewPlaceAction={dispatchNewPlaceAction}
                    />
                  )
                }
              >
                {(nodes[child] &&
                  nodes[child].children &&
                  nodes[child].children.length > 0 &&
                  nodes[child].children.map((innerNode, j) =>
                    innerNode && nodes[innerNode] ? (
                      <TreeNode
                        key={j}
                        label={
                          <PlacementBox
                            {...nodes[innerNode]}
                            leftSales={
                              nodes[innerNode].totalLeft
                                ? nodes[innerNode].totalLeft
                                : 0
                            }
                            rightSales={
                              nodes[innerNode].totalRight
                                ? nodes[innerNode].totalRight
                                : 0
                            }
                            setRootDisplay={setRootDisplay}
                            key={`${nodes[child].name}_${j}`}
                          />
                        }
                      >
                        {(nodes[innerNode] &&
                          nodes[innerNode].children &&
                          nodes[innerNode].children.length > 0 &&
                          nodes[innerNode].children.length > 0 &&
                          nodes[innerNode].children.map(
                            (innerInnerNode, k) =>
                              innerInnerNode && nodes[innerInnerNode] ? (
                                <TreeNode
                                  key={k}
                                  label={
                                    <PlacementBox
                                      {...nodes[innerInnerNode]}
                                      leftSales={
                                        nodes[innerInnerNode].totalLeft
                                          ? nodes[innerInnerNode].totalLeft
                                          : 0
                                      }
                                      rightSales={
                                        nodes[innerInnerNode].totalRight
                                          ? nodes[innerInnerNode].totalRight
                                          : 0
                                      }
                                      setRootDisplay={setRootDisplay}
                                      key={`${nodes[innerInnerNode].name}_${j}_${k}`}
                                    />
                                  }
                                />
                              ) : (
                                <TreeNode
                                  key={k}
                                  label={
                                    <PlaceHereBox
                                      index={k}
                                      parentID={nodes[innerNode].uid}
                                      parentName={nodes[innerNode].name}
                                      setParentID={setParentID}
                                      setParentName={setParentName}
                                      setPosition={setPosition}
                                      setIsDialogOpen={setIsDialogOpen}
                                      dispatchNewPlaceAction={
                                        dispatchNewPlaceAction
                                      }
                                    />
                                  }
                                />
                              )
                          )) ||
                          null}
                      </TreeNode>
                    ) : (
                      <TreeNode
                        key={j}
                        label={
                          <PlaceHereBox
                            index={j}
                            parentID={nodes[child].uid}
                            parentName={nodes[child].name}
                            setParentID={setParentID}
                            setParentName={setParentName}
                            setPosition={setPosition}
                            setIsDialogOpen={setIsDialogOpen}
                            dispatchNewPlaceAction={dispatchNewPlaceAction}
                          />
                        }
                      />
                    )
                  )) ||
                  null}
              </TreeNode>
            ))}
          </>
        )) ||
      null
    )
  }

  return (
    <Card>
      <CardContent>
        {rootNode && (
          <form onSubmit={handleSearch}>
            <TextField
              type='text'
              label='Search'
              onChange={e => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Button edge='end' color='primary' aria-label='search user' onClick={handleSearch}>
                      <i className="fa fa-search" />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        )}
        {rootNode && nodes && (
          <SimpleBreadcrumbs
            className="mt-4 mb-2"
            handleClick={handleClick}
            list={list}
            initial={root}
            handleMoveUp={handleMoveUp}
          />
        )}
        <div style={{ minHeight: "200px", overflowX: "auto" }}>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            {rootNode && (
              <Tree
                label={
                  <PlacementBox
                    {...rootNode}
                    placementRoot={placementRoot}
                    setRootDisplay={setRootDisplay}
                    key={rootNode.name}
                    leftSales={
                      rootNode && rootNode.totalLeft ? rootNode.totalLeft : 0
                    }
                    rightSales={
                      rootNode && rootNode.totalRight ? rootNode.totalRight : 0
                    }
                  />
                }
                lineBorderRadius={"0"}
                lineColor="#E93636"
                lineWidth="2px"
              >
                {renderNodes()}
              </Tree>
            )}
          </div>
        </div>
        {/* <DialogPlaceUser
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          parentID={parentID}
          parentName={parentName}
          position={position}
        /> */}
      </CardContent>
    </Card>
  )
}

export default CareerChart
