import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import CountUp from 'react-countup'
import Dialog from '@material-ui/core/Dialog';

import { deleteDownloadReport, getDownloadSearch, updateDownloadReport } from '../../../actions/announcementAction';
import { getAllLeaders } from '../../../actions/siteAction';
import { formatCustomDate, formatDateTime } from '../../../util/date';
import StatusTypes from '../../Shared/StatusTypes';
import TablePaginationWithLogic from '../../TablePaginationWithLogic';
import { getImageUrl } from '../../../util/strings';

const ItemRow = ({
    id,
    title,
    description,
    path,
    admin,
    type,
    leader,
    leaderUsername,
    startDate,
    endDate,
    status,
    createdAt,
    updatedAt,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [editTitle, setEditTitle] = useState(title);
    const [editDescription, setEditDescription] = useState(description);
    const [editStatus, setEditStatus] = useState(status);
    const [editStartDate, setEditStartDate] = useState(startDate ? formatCustomDate(startDate) : null);
    const [editEndDate, setEditEndDate] = useState(endDate ? formatCustomDate(endDate) : null);
    const [editLeader, setEditLeader] = useState(leaderUsername);

    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleViewClickOpen = () => {
        setIsViewDialogOpen(true);
    };

    const handleViewClose = () => {
        setIsViewDialogOpen(false);
    };
    const handleDeleteClickOpen = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteClose = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleDeleteSubmit = (e) => {
        e.preventDefault();
        dispatch(deleteDownloadReport({
            id,
        }));
        setIsDeleteDialogOpen(false);
    }

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        dispatch(updateDownloadReport({
            id,
            title: editTitle,
            description: editDescription,
            status: editStatus,
            startDate: editStartDate,
            endDate: editEndDate,
            leader: editLeader,
        }));
        setIsDialogOpen(false);
    }

    return (
        <tr>
            <td>{id}</td>
            <td>{formatDateTime(createdAt)}</td>
            <td>{title}<br /><small>{description}</small></td>
            <td>{status ? <StatusTypes status={status} /> : '-'}</td>
            <td>{!!leader ? leaderUsername : '-'}</td>
            <td>{startDate ? formatDateTime(startDate) : '-'}</td>
            <td>{endDate ? formatDateTime(endDate) : '-'}</td>
            <td>
                <>
                    <button
                        type="button"
                        className="btn btn-icon"
                        title="Edit"
                        onClick={handleClickOpen}
                    >
                        <i className="fa fa-edit" />
                    </button>
                    <Dialog open={isDialogOpen} onClose={handleClose}>
                        <div className="modal-content">
                            <form onSubmit={handleUpdateSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Edit {title}</h5>
                                    <button type="button" className="close" onClick={handleClose}>
                                        {/* <span aria-hidden="true">&times;</span> */}
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Title</label>
                                                <input type="text" className="form-control" placeholder="title" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <input type="text" className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} required />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Leader</label>
                                                <input type="text" className="form-control" value={editLeader} onChange={(e) => setEditLeader(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Status</label>
                                                <select className="form-control custom-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
                                                    <option value="LIVE">LIVE</option>
                                                    <option value="DISABLED">DISABLED</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">Start Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={editStartDate}
                                                    onChange={(e) => setEditStartDate(e.target.value)}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label">End Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={editEndDate}
                                                    onChange={(e) => setEditEndDate(e.target.value)}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                    <button type="submit" className="btn btn-primary">Update</button>
                                </div>
                            </form>

                        </div>
                    </Dialog>
                </>
                <button
                    type="button"
                    className="btn btn-icon"
                    title="view"
                    onClick={handleViewClickOpen}
                >
                    <i className="fa fa-eye" />
                </button>
                <Dialog open={isViewDialogOpen} onClose={handleViewClose}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">View {title}</h5>
                            <button type="button" className="close" onClick={handleViewClose}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                <span className="text-bold">Title: </span>{title}<br />
                                <span className="text-bold">Description: </span>{description}<br />
                                <span className="text-bold">Status: </span><StatusTypes status={status} /><br />
                                <span className="text-bold">Leader: </span>{leader ? leaderUsername : '-'}<br />
                                <span className="text-bold">Start: </span>{startDate ? formatDateTime(startDate) : '-'}<br />
                                <span className="text-bold">End: </span>{endDate ? formatDateTime(endDate) : '-'}<br />
                                <span className="text-bold">Document: </span>{path ? <a target="_blank" href={getImageUrl(path.replace('public', ''))} >Link</a> : '-'}<br />
                                <span className="text-bold">Created By: </span>{admin ? admin : '-'}<br />
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleViewClose}>Close</button>
                        </div>
                    </div>
                </Dialog>
                <button
                    type="button"
                    className="btn btn-icon"
                    title="Delete"
                    onClick={handleDeleteClickOpen}
                >
                    <i className="text-danger fa fa-trash" />
                </button>
                <Dialog open={isDeleteDialogOpen} onClose={handleDeleteClose}>
                    <div className="modal-content">
                        <form onSubmit={handleDeleteSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title">Delete {title}</h5>
                                <button type="button" className="close" onClick={handleDeleteClose}>
                                    {/* <span aria-hidden="true">&times;</span> */}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p>Are You Sure?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleDeleteClose}>Close</button>
                                <button type="submit" className="btn btn-primary bg-danger">Delete</button>
                            </div>
                        </form>

                    </div>
                </Dialog>
            </td>
        </tr>
    )
}

const DownloadList = (props) => {
    const [search, setSearch] = useState("")
    const [searchType, setSearchType] = useState("")
    const [error, setError] = useState('');
    const [params, setParams] = useState({ page: 1, limit: 10 })
    const [searchEmail, setSearchEmail] = useState('');
    const [searchLeader, setSearchLeader] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const dispatch = useDispatch();
    const dataList = useSelector(({ announcement }) => announcement.downloads.report)
    const meta = useSelector(({ announcement }) => announcement.downloads.meta)

    const { authUser } = useSelector(({ user }) => user);
    const allLeaders = useSelector(({ site }) => site.allLeaders)

    useEffect(() => {
        dispatch(getAllLeaders())
    }, [])

    const handlePageChange = (page) => setParams({ ...params, page })
    const handlePageSizeChange = (pageSize) => setParams({ ...params, limit: pageSize })

    useEffect(() => {
        handleSearchDownloads()
    }, [params])

    const validateSearch = () => {
        // if (!search) {
        //     return ('No search');
        // } else {
        //     return ('');
        // }
    }

    const submitSearch = (e) => {
        e.preventDefault();
        const hasError = validateSearch();

        if (!hasError) {
            handleSearchDownloads()
            setError('')

        } else {
            setError(hasError)
        }
    }
    const handleResetFilters = () => {
        setSearch("")
        setSearchType("")
        setSearchLeader("")
        setParams({ page: 1, limit: 10 })
        setError("")
    }
    const handleSearchDownloads = () => {
        dispatch(getDownloadSearch({
            search,
            leaderSearch: searchLeader,
            startDate,
            endDate,
            ...params
        }))
    }
    const renderError = () => {
        return (error ? <p className="text-danger  mb-0 mt-2">{error}</p> : <p>&nbsp;</p>)
    }

    return (
        <>
            <div className="section-body mt-3">
                <div className="container-fluid">
                    <div className="tab-content mt-3">
                        <div>
                            <div>
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row" onSubmit={submitSearch}>
                                            <div className="col-5">
                                                <label>Search Downloads</label>
                                                <div className="input-group">
                                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search username..." />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <label>Leader</label>
                                                    <div className="input-group">
                                                        <select
                                                            disabled={authUser?.role == 2 && authUser.limitLeader}
                                                            className="form-control custom-select"
                                                            value={searchLeader}
                                                            onChange={(e) => setSearchLeader(e.target.value)}>
                                                            <option value="">All Leaders</option>
                                                            {allLeaders && allLeaders.length > 0 ?
                                                                allLeaders.map((l, i) => <option key={`${l}_${i}`} value={l}>{l}</option>)
                                                                : <></>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">&nbsp;</div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Created Start Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={startDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <label>Created End Date</label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={endDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">&nbsp;</div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Search
                                                </button>
                                            </div>
                                            <div className="col-md-2 col-sm-6">
                                                <button type="button" className="btn btn-secondary btn-block" onClick={handleResetFilters}>
                                                    Reset
                                                </button>
                                            </div>
                                        </form>
                                        <div className="row">
                                            <div className="col-12">{renderError()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Downloads</h3>
                                    {/* <div className="card-options">
                                            <form>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                    <span className="input-group-btn ml-2"><button className="btn btn-icon btn-sm" type="submit"><span className="fe fe-search" /></button></span>
                                                </div>
                                            </form>
                                        </div> */}
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped table-vcenter">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Created Date</th>
                                                    <th>Title</th>
                                                    <th>Status</th>
                                                    <th>Leader</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th className="w150">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataList && dataList.length > 0 ? dataList.map((w, i) => <ItemRow key={w.id} {...w} authRole={authUser.role} />)
                                                    :
                                                    <tr><td colSpan={7}><p className="text-center">No user wallets found</p></td></tr>
                                                }
                                            </tbody>
                                        </table>
                                        <TablePaginationWithLogic count={meta.itemCount} changePage={handlePageChange} changeRows={handlePageSizeChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default DownloadList;